import { FC } from "react";
import { TileProps } from "./types";
import classes from "./tilesets.module.scss";
import clsx from "clsx";

interface Props extends TileProps {}

const TileCenter: FC<Props> = (props) => {
  const { color = "primary", className = "" } = props;
  return (
    <div
      className={clsx({
        [classes.tile]: true,
        [classes.tileCenter]: true,
        [classes[color]]: true,
        [className]: true
      })}
    ></div>
  );
};

export default TileCenter;
