import merge from "lodash/merge";
import shared from "./shared";

import en from "./en";
import fr from "./fr";

export default merge(shared, {
  en,
  fr
});
