import { colors } from "modules/listing-calendar/constants/day-color";
import { FC, Fragment, memo } from "react";
import { FlagComponentProps } from "./types";
import { ProviderLogo, TileCenter, TileStart } from "modules/listing-calendar";
import { RANGE_FORMAT } from "modules/listing-calendar/constants/range-format";
import { useSelector } from "react-redux";
import classes from "./flag-components.module.scss";
import clsx from "clsx";
import dayjs from "dayjs";
import selectIsDayOverlapping from "modules/listing-calendar/store/selectors/select-is-day-overlapping";
import selectIsDayOverlappingBack from "modules/listing-calendar/store/selectors/select-is-day-overlapping-back";
import selectPeriodBetweenRange from "modules/listing-calendar/store/selectors/select-period-between-range";
import selectPeriodByDate from "modules/listing-calendar/store/selectors/select-period-by-date";

interface Props extends FlagComponentProps {}

export const ReservedStartDay: FC<Props> = (props) => {
  const { calendar, day, listingId } = props;

  const previousDay = dayjs(day).subtract(1, "day").format(RANGE_FORMAT);

  const period = useSelector(selectPeriodByDate(listingId, day));

  const isOverlapping = useSelector(selectIsDayOverlapping(listingId, day));

  const bgPeriod = useSelector(
    selectPeriodBetweenRange(listingId, previousDay)
  );

  const isBgOverlayed = useSelector(selectIsDayOverlappingBack(listingId, day));

  const { type, guest, payout } = period || {};

  const bgPeriodType = bgPeriod?.type;

  const color = type ? colors[type] : "primary";

  const colorC = bgPeriodType ? colors[bgPeriodType] : "primary";

  const hostPayoutAmount = Number(payout?.host_payout ?? 0).toFixed(2);

  return (
    <Fragment>
      <div
        className={clsx({
          [classes.reservedBlockedStart]: true,
          [classes.elevated]: isBgOverlayed && isOverlapping
        })}
      >
        <TileStart color={isOverlapping ? "danger" : color}>
          <div className={classes.labelContainer}>
            {type !== "external-booking" && (
              <>
                {!!guest?.avatar && (
                  <img src={guest.avatar} alt={guest.fullname} />
                )}
                <span className={classes.guestName}>{guest?.fullname}</span>

                <span>
                  &nbsp; - {payout?.symbol}
                  {hostPayoutAmount} {payout?.currency}
                </span>
              </>
            )}
            {type === "external-booking" && (
              <>
                <ProviderLogo provider={period?.provider_name ?? "wechalet"} />
                <div className={classes.reservation__guest}>
                  {calendar?.booked_by ?? "N/A"}
                </div>
              </>
            )}
          </div>
        </TileStart>
      </div>
      {isOverlapping && isBgOverlayed && (
        <TileCenter className={classes.overlappedBack} color={colorC} />
      )}
    </Fragment>
  );
};

export default memo(ReservedStartDay);
