import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";

export default (
  state: ContainerState,
  action: PayloadAction<{
    date: string;
    yIndex: number;
  }>
): ContainerState => {
  const payload = action.payload;
  switch (action.type) {
    case DAY_HIGHLIGHTED:
      return {
        ...state,
        previewDayTwo: payload.date,
        yIndexTwoPreview: payload.yIndex
      };
    default:
      return {
        ...state
      };
  }
};

export const DAY_HIGHLIGHTED = "DAY_HIGHLIGHTED";
