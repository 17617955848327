import { ContainerState } from "./types";

/**
 * Explore initial state
 *
 */
const initialState: ContainerState = {
  mapMode: "windowed",
  searchWithMap: true
};

export default initialState;
