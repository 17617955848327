import clsx from "clsx";

import style from "./Skeleton.module.scss";

const Skeleton = ({ className }) => (
  <span
    role="img"
    className={clsx(style.element, className)}
  />
);

export default Skeleton;
