import { colors } from "modules/listing-calendar/constants/day-color";
import { FC, Fragment, memo } from "react";
import { FlagComponentProps } from "./types";
import { ProviderLogo, TileCenter, TileCross } from "modules/listing-calendar";
import { RANGE_FORMAT } from "modules/listing-calendar/constants/range-format";
import { useSelector } from "react-redux";
import classes from "./flag-components.module.scss";
import clsx from "clsx";
import dayjs from "dayjs";
import selectIsDayOverlapping from "modules/listing-calendar/store/selectors/select-is-day-overlapping";
import selectIsDayOverlappingBack from "modules/listing-calendar/store/selectors/select-is-day-overlapping-back";
import selectPeriodByDate from "modules/listing-calendar/store/selectors/select-period-by-date";
import selectPeriodByEndDate from "modules/listing-calendar/store/selectors/select-period-by-end-date";

export const ReservedStartEndDay: FC<FlagComponentProps> = (props) => {
  const { calendar, day, listingId } = props;

  const period = useSelector(selectPeriodByDate(listingId, day));

  const type = period?.type;

  const previousDay = dayjs(day).subtract(1, "day").format(RANGE_FORMAT);

  const prevPeriod = useSelector(selectPeriodByEndDate(listingId, previousDay));

  const previousType = prevPeriod?.type;

  const color = type ? colors[type] : "primary";

  const previousColor = previousType ? colors[previousType] : "primary";

  const guest = period?.guest;

  const payout = period?.payout;

  const hostPayoutAmount = Number(payout?.host_payout ?? 0).toFixed(2);

  const overlappedStart = useSelector(
    selectIsDayOverlapping(listingId, previousDay)
  );
  const periodOverlapped = useSelector(selectIsDayOverlapping(listingId, day));

  const isBgOverlayed = useSelector(selectIsDayOverlappingBack(listingId, day));

  const hasBgOverlay = overlappedStart && isBgOverlayed;
  return (
    <Fragment>
      <div
        className={clsx({
          [classes.reservedBlockedStart]: true,
          [classes.elevated]: hasBgOverlay
        })}
      >
        <TileCross
          previousColor={overlappedStart ? "danger" : previousColor}
          color={periodOverlapped ? "danger" : color}
        >
          <div className={classes.labelContainer}>
            {type !== "external-booking" && (
              <>
                {!!guest?.avatar && (
                  <img src={guest.avatar} alt={guest.fullname} />
                )}
                <span className={classes.guestName}>{guest?.fullname}</span>

                <span>
                  &nbsp; - {payout?.symbol}
                  {hostPayoutAmount} {payout?.currency}
                </span>
              </>
            )}
            {type === "external-booking" && (
              <>
                <ProviderLogo provider={period?.provider_name ?? "wechalet"} />
                <div className={classes.reservation__guest}>
                  {calendar?.booked_by ?? "N/A"}
                </div>
              </>
            )}
          </div>
        </TileCross>
      </div>
      {hasBgOverlay && (
        <TileCenter className={classes.overlappedBack} color={previousColor} />
      )}
    </Fragment>
  );
};

export default memo(ReservedStartEndDay);
