import { useMemo, forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import ValidationMessage from "../ValidationMessage";

import style from "./Checkbox.module.scss";

const Checkbox = forwardRef(({
  id: idProp,
  name,
  value,
  label,
  button,
  small,
  invalid,
  renderIcon,
  children,
  onChange,
  ...attrs
}, ref) => {
  const id = useMemo(() => idProp || `Checkbox_${Math.random().toString(36).substring(7)}`, [idProp]);

  const checked = Array.isArray(value)
    ? value.includes(name)
    : value;

  const onInputChange = (event) => {
    if (Array.isArray(value)) {
      if (!name) return;

      const newValue = [...value];
      const index = newValue.indexOf(name);

      if (!event.target.checked && index >= 0) {
        newValue.splice(index, 1);
      } else if (event.target.checked && index < 0) {
        newValue.push(name);
      }

      onChange(newValue);
    } else {
      onChange(event.target.checked);
    }
  };

  return (
    <div className={clsx(style.wrapper, {
      [style.button]: button,
      "has-icon": renderIcon,
      "is-invalid": invalid,
      "is-small": small
    })}>
      <div className={style.field}>
        <input
          ref={ref}
          type="checkbox"
          id={id}
          className={style.input}
          name={name}
          checked={checked}
          aria-describedby={`${id}_description`}
          onChange={onInputChange}
          {...attrs}
        />
        <label
          htmlFor={id}
          className={style.label}
        >
          {typeof renderIcon === "function" && (
            <div className={style.icon}>
              {renderIcon(checked)}
            </div>
          )}
          <span className={style.text}>
            {label || children}
          </span>
        </label>
      </div>
      <div id={`${id}_description`}>
        {typeof invalid === "string" && (
          <ValidationMessage>
            {invalid}
          </ValidationMessage>
        )}
      </div>
    </div>
  );
});

Checkbox.propTypes = {
  /** Input id */
  id: PropTypes.string,
  /** Checkbox name. Will be used in array value. */
  name: PropTypes.string,
  /** Value. Checked boolean or checked names array. */
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  /** Label text */
  label: PropTypes.string,
  /** Small label */
  small: PropTypes.bool,
  /** Button style */
  button: PropTypes.bool,
  /**
   * Validation state.
   * `true` displays field invalid.
   * A `string` displays field invalid with a message.
   */
  invalid: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  /** Replace checkbox with icon. `checked` is passed as arg. */
  renderIcon: PropTypes.func,
  /** Change handler. Gets the new value passed. */
  onChange: PropTypes.func
};

Checkbox.defaultProps = {
  value: false,
  onChange: () => { }
};

export default Checkbox;
