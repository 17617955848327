import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getLocale } from "modules/settings/selectors";

import { RouterRoute } from "../types";
import { getLocalizedPath } from "../utils";

const useLocalizedPath = (route: RouterRoute) => {
  const locale = useSelector(getLocale);
  const path = useMemo(() => getLocalizedPath(route, locale), [route, locale]);

  return path;
};

export default useLocalizedPath;
