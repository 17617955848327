import { useMemo } from "react";
import LocalizedStrings from "react-localization";

import { useSelector } from "react-redux";
import { getLocale } from "modules/settings/selectors";

import defaultTranslations from "translations";

export type Messages<T extends string> = {
  [K in "en" | "fr"]: Record<T, string>;
};

export default (
  translations: any = defaultTranslations,
  currentLocale?: string
) => {
  const locale = useSelector(getLocale);

  return useMemo(() => {
    const lang = new LocalizedStrings(translations);
    lang.setLanguage(currentLocale || locale || "en");
    return lang;
  }, [currentLocale, locale, translations]);
};

export function useTranslation<T extends string>(
  messages: Messages<T>
): Record<T, string> {
  const locale = useSelector(getLocale);

  return useMemo(() => {
    const lang = new LocalizedStrings(messages);
    lang.setLanguage(locale || "en");
    return lang;
  }, [locale, messages]);
}
