import { lazy, Suspense, useEffect, useState } from "react";

const loadable = (
  importFunc: () => Promise<
    { default: React.ComponentType<any> }
  >,
  {
    fallback: Fallback = null,
    timeout = 0
  }: {
    fallback?: React.ComponentType<any> | null,
    timeout?: number
  } = {}
) => {
  const LazyComponent = lazy(importFunc);

  return (props: object) => {
    const [timedOut, setTimedOut] = useState(false);
    useEffect(() => {
      if (timeout > 0) {
        setTimeout(() => {
          setTimedOut(true);
        }, timeout);
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
      <Suspense fallback={Fallback && <Fallback timedOut={timedOut} />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};

export default loadable;
