import { FC, useMemo } from "react";
import { getLocale } from "modules/settings/selectors";
import { getLocalizedPath } from "./utils";
import { Link as RouterLink, LinkProps, generatePath } from "react-router-dom";
import { LocationDescriptorObject } from "history";
import { RouterRoute } from "./types";
import { useSelector } from "react-redux";
import useDeepEqualMemo from "utils/useDeepEqualMemo";

interface Props extends Omit<LinkProps, "to"> {
  route: RouterRoute;
  params?: { [key: string]: any };
  search?: LocationDescriptorObject["search"];
  state?: LocationDescriptorObject["state"];
  hash?: LocationDescriptorObject["hash"];
}

/**
 * Link with auto localization support.
 * Takes route object and generates the path based on locale.
 * Requires individual location props (`params`, `search`, `state`, `hash`),
 * instead of using a `to` object.
 */
const Link: FC<Props> = ({
  route,
  params: paramsProp,
  search,
  state: stateProp,
  hash,
  children,
  ...attrs
}) => {
  const params = useDeepEqualMemo(paramsProp);

  const state = useDeepEqualMemo(stateProp);

  const locale = useSelector(getLocale);

  const pathname = useMemo(() => {
    const localizedPath = getLocalizedPath(route, locale);
    return generatePath(localizedPath, params).replace(/%2F/g, "/");
  }, [route, params, locale]);

  if (!route) {
    return (
      <a // eslint-disable-line jsx-a11y/anchor-is-valid
        href="#"
        hrefLang={locale}
        {...attrs}
      >
        {children}
      </a>
    );
  }

  return (
    <RouterLink
      to={{ pathname, search, state, hash }}
      hrefLang={locale}
      {...attrs}
    >
      {children}
    </RouterLink>
  );
};

export default Link;
