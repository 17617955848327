interface Config {
  apiUrl: string;
  paymentFlowTestId: string;
}

const defaultConfig: Config = {
  apiUrl: process.env.REACT_APP_API_URL,
  paymentFlowTestId: "8979d1b9-5d20-47f5-bbeb-813056fbfe1e"
};

export const getConfig = (): Config => {
  const storedConfig = localStorage.getItem("appConfig");
  if (storedConfig) {
    return JSON.parse(storedConfig);
  }
  return defaultConfig;
};

export const setConfig = (newConfig: Partial<Config>) => {
  const currentConfig = getConfig();
  const updatedConfig = { ...currentConfig, ...newConfig };
  localStorage.setItem("appConfig", JSON.stringify(updatedConfig));
};

export const resetConfig = () => {
  localStorage.removeItem("appConfig");
};
