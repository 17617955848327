export default {
  en: {
    timeoutMessage: "Taking too long?",
    reloadButton: "Reload the page"
  },
  fr: {
    timeoutMessage: "Ça prend trop longtemps?",
    reloadButton: "Rafraîchir la page"
  }
};
