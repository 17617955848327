import { createSelector } from "reselect";
import selectDomain from ".";

/**
 * Returns the accepted cookies
 *
 */
export default createSelector([selectDomain], (state) => {
  return state.acceptedCookies;
});
