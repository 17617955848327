import { useMediaQuery } from "react-responsive";
import breakpoints from "../styles/breakpoints.module.scss";

/**
 * useBreakpoints hook
 *
 * @returns {Object<string, boolean>} The breakpoints object.
 * Contains all breakpoints defined in the styles as booleans.
 * A breakpoint will be `true` if it is active using a `min-width` media query.
 */
const useBreakpoints = () => {
  const mediaQueries = {
    getCurrent: (values = {}) => {
      const breakpointsKeys = Object.keys(values);
      // Start from largest breakpoint going down until one of them returns true
      for (let i = breakpointsKeys.length - 1; i >= 0; i--) {
        const key = breakpointsKeys[i];
        if (mediaQueries[key]) return values[key];
      }
    }
  };

  for (const breakpointKey in breakpoints) {
    // We can use a hook here because the iteration won't ever change during runtime
    // because breakpoints is a constant
    mediaQueries[breakpointKey] = useMediaQuery({ // eslint-disable-line react-hooks/rules-of-hooks
      minWidth: parseInt(breakpoints[breakpointKey], 10)
    });
  }

  return mediaQueries;
};

export default useBreakpoints;
