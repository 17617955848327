import { FC, memo } from "react";
import classes from "./place-loaders.module.scss";
import Skeleton from "components/Skeleton";

interface Props {}

export const ListingColumnPlaceLoader: FC<Props> = memo(() => {
  return (
    <div className={classes.day}>
      <Skeleton className="" />
    </div>
  );
});

export default memo(ListingColumnPlaceLoader);
