import { merge } from "lodash";
import {
  CALENDAR_REMOVE,
  CALENDAR_ADD_MONTHS,
  CALENDAR_REMOVE_MONTHS,
  CalendarActionTypes
} from "./types";

export const calendarMonthsReducer = (
  state = {},
  action: CalendarActionTypes
) => {
  const { type, payload } = action;
  if (type === CALENDAR_ADD_MONTHS) {
    return merge({}, state, payload.data);
  }

  if (type === CALENDAR_REMOVE_MONTHS) {
    const keys = (Object.keys(state) as string[]).filter(
      (key: string) =>
        !(Object.keys(payload.data as object) as string[]).includes(key)
    );
    return keys.reduce((acc: object, current: string) => {
      acc[current] = state[current];
      return acc;
    }, {});
  }

  return state;
};

export const calendarsReducer = (state = {}, action: CalendarActionTypes) => {
  const { type, payload } = action;

  // handles removing a calendar from calendars state
  if (type === CALENDAR_REMOVE) {
    const keys = Object.keys(state).filter((key) => key !== payload.calendarId);
    return keys.reduce((curry: object, current: string): object => {
      curry[current] = state[current];
      return curry;
    }, {});
  }

  // handles adding new calendar
  if (type === CALENDAR_ADD_MONTHS || type === CALENDAR_REMOVE_MONTHS) {
    // if calendar don't exist we create it
    const calendar = state[payload.calendarId] || {};
    // merge calendar state with global state
    return {
      ...state,
      [payload.calendarId]: calendarMonthsReducer(calendar, action)
    };
  }

  return state;
};
