import { INITIAL_X_OFFSET } from "modules/listing-calendar/constants";
import dayjs from "dayjs";
import realDate from "./real-date";

/**
 * Returns the initial date from which the listing calendar starts
 *
 */
export default () => {
  const day = dayjs().subtract(INITIAL_X_OFFSET, "day").startOf("date");

  const todayNextMonth = dayjs().add(29, "day").startOf("date");

  const day1 = realDate(day.toDate());

  const day2 = realDate(todayNextMonth.toDate());

  return [day1, day2];
};
