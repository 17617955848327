import PropTypes from "prop-types";

const ChevronIcon = ({ size = 20, color = "#4f5253" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="3"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 9l6 6 6-6" />
  </svg>
);

var Toggle = ({ label, classnames, onClick, isVisible }) => {
  return (
    <button className={classnames} type="button" onClick={onClick}>
      <span className="Text">{label}</span>
      <span className={`ChevronIcon ${isVisible ? "rotate" : ""}`}>
        <ChevronIcon />
      </span>
    </button>
  );
};

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
  classnames: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired
};

export default Toggle;
