import { CSSProperties, FC, memo, useMemo } from "react";
import classes from "./place-loaders.module.scss";
import clsx from "clsx";
import ListingColumnPlaceLoader from "./listing-column-place-loader";

interface Props {
  style?: CSSProperties;
  className?: string;
}

export const ListingRowPlaceLoader: FC<Props> = memo((props) => {
  const { style, className = "" } = props;

  const columns = useMemo(() => Array.from({ length: 30 }), []);
  return (
    <div className={clsx(classes.container, className)} style={style}>
      {columns.map((c, index) => (
        <ListingColumnPlaceLoader key={`listing-column--${index}`} />
      ))}
    </div>
  );
});

export default memo(ListingRowPlaceLoader);
