import { createSelector } from "reselect";
import selectAuthenticatedUser from "./select-authenticated-user";

/**
 * Returns whether or not the user is authenticated
 *
 */
export default createSelector([selectAuthenticatedUser], (user) => {
  return !!user?.id;
});
