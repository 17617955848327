import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState, CookieMap } from "../types";

export default (state: ContainerState, action: Action): ContainerState => {
  switch (action.type) {
    case CUSTOM_COOKIES_ACCEPTED:
      return {
        ...state,
        cookiesAcceptedAt: action.payload.acceptedAt,
        acceptedCookies: action.payload.cookies
      };
    default:
      return state;
  }
};

type Action = PayloadAction<{ acceptedAt: string; cookies: CookieMap }>;

export const CUSTOM_COOKIES_ACCEPTED = "CUSTOM_COOKIES_ACCEPTED";
