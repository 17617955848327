import { PulseLoader } from "react-spinners";

const Loader = ({ color = "white", size = "50" }) => {
  return (
    <PulseLoader
      // css={override}
      sizeUnit={"px"}
      size={size}
      color={color}
      loading={true}
    />
  );
};

export default Loader;
