import PropTypes from "prop-types";
import clsx from "clsx";

import style from "./ValidationMessage.module.scss";

const ValidationMessage = ({ children, className, ...attrs }) => children ? (
  <p
    className={clsx(className, style.message)}
    {...attrs}
  >
    {children}
  </p>
) : null;

ValidationMessage.propTypes = {
  /** Message. Nothing is rendered if falsy. */
  children: PropTypes.string
};

export default ValidationMessage;
