import { forwardRef, useRef, useState, useMemo, useCallback, useEffect } from "react";
import clsx from "clsx";
import Proptypes from "prop-types";

import ChevronIcon from "../ChevronIcon";

import style from "./DropdownMenu.module.scss";

const keyCodes = {
  27: "Escape"
};

const Dropdown = forwardRef(
  (
    {
      id,
      label,
      theme,
      children,
      icon,
      onClickAway,
      isOpen: isOpenProp,
      staticMenu = false,
      onClick,
      ...attrs
    },
    ref
  ) => {
    const wrapperRef = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const innerIsOpen = isOpenProp ?? isOpen;

    const innerId = useMemo(
      () => id || `Dropdown_${Math.random().toString(36).substring(7)}`,
      [id]
    );

    const innerOnClick = () => {
      setIsOpen(!isOpen);
      onClick();
    };

    const onClickOutside = useCallback(() => {
      if (isOpen) {
        setIsOpen(false);
        onClickAway();
      }
    }, [isOpen, onClickAway]);

    const onDocumentClick = useCallback(
      (event) => {
        if (!wrapperRef.current.contains(event.target)) {
          onClickOutside(event);
        }
      },
      [onClickOutside]
    );

    const onEsc = () => {
      if (isOpen) setIsOpen(false);
    };

    const onKeyDown = (event) => {
      let code = event.code;

      if (!code && event.keyCode != null) {
        code = keyCodes[event.keyCode];
      }

      switch (code) {
        case "Escape":
          return onEsc(event);
        default:
          break;
      }
    };

    useEffect(() => {
      document.addEventListener("click", onDocumentClick, true);

      return () => {
        document.removeEventListener("click", onDocumentClick, true);
      };
    }, [onDocumentClick]);

    return (
      <div
        ref={wrapperRef}
        className={clsx(style.wrapper, style[theme], {
          [style.active]: innerIsOpen,
          [style.staticMenu]: staticMenu
        })}
        onKeyDown={onKeyDown}
        {...attrs}
      >
        {/* Toggle */}
        <button
          ref={ref}
          type="button"
          className={style.button}
          aria-haspopup="true"
          aria-controls={`${innerId}_menu`}
          aria-expanded={innerIsOpen}
          onClick={innerOnClick}
        >
          {/* Icon */}
          {typeof icon === "function" && (
            <span className={style.icon}>{icon()}</span>
          )}

          {/* Label */}
          <span className={style.buttonText}>{label}</span>

          {/* Arrow */}
          <span className={style.arrow}>
            <ChevronIcon />
          </span>
        </button>

        {/* Menu */}
        <div id={`${innerId}_menu`} role="menu" className={style.menu}>
          <ul className={style.menuList}>
            {(Array.isArray(children) ? children : [children]).map(
              (child, index) => (
                <li key={index} className={style.menuItem}>
                  {child}
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    );
  }
);

const noop = () => {};

Dropdown.defaultProps = {
  isOpen: null,
  onClick: noop,
  onClickAway: noop,
  theme: "default"
};

Dropdown.propTypes = {
  /** Id used by aria attributes */
  id: Proptypes.string,
  /** On click outside event */
  onClickAway: Proptypes.func,
  /** Toggle label */
  label: Proptypes.string,
  /** Theme */
  theme: Proptypes.oneOf(["default", "labelOnly", "alignedLeftLabel"]),
  /** Toggle icon */
  icon: Proptypes.func,
  /** Force the menu open */
  isOpen: Proptypes.bool,
  /** Make menu appear under the toggle without absolute positioning. */
  staticMenu: Proptypes.bool,
  /** Click event handler */
  onClick: Proptypes.func
};

export default Dropdown;
