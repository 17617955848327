import { Eye } from "react-feather";
import { FC, useState, Fragment, CSSProperties, memo } from "react";
import { ObjectId } from "modules/shared/types/object-id";
import { useSelector } from "react-redux";
import classes from "./listing-name.module.scss";
import Link from "modules/router/Link";
import routes from "routes";
import selectListingById from "modules/listing-calendar/store/selectors/select-listing-by-id";
import useBreakpoints from "utils/useBreakpoints";

interface Props {
  listingId: ObjectId;
  style?: CSSProperties;
}

export const ListingName: FC<Props> = (props) => {
  const { listingId, style } = props;

  const listing = useSelector(selectListingById(listingId));

  const breakpoints = useBreakpoints();

  const [showEye, setShowEye] = useState(false);

  return (
    <Fragment>
      <div className={classes.container} style={style}>
        <Link
          route={routes.editListingCalendar}
          params={{ listingId: listing.id }}
          hash="isFromMulticalendar"
          className={classes.listingLink}
          target="_blank"
        >
          {
            <>
              <span className={classes.thumbnailContainer}>
                {breakpoints.md && (
                  <img
                    className={classes.listingCover}
                    src={listing.main_picture}
                    alt={listing.name}
                    onMouseEnter={() => setShowEye(true)}
                    onMouseLeave={() => setShowEye(false)}
                  />
                )}
                {showEye && (
                  <Eye
                    role="img"
                    className={classes.eyeIcon}
                    color="white"
                    size={20}
                  />
                )}
              </span>
              <p
                // added this inline to avoid stylelint removing/breaking it from scss file
                style={{ WebkitBoxOrient: "vertical" }}
                className={classes.listingTitle}
              >
                {listing.name}
              </p>
            </>
          }
        </Link>
      </div>
    </Fragment>
  );
};

export default memo(ListingName);
