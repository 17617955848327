export const SET_LOCALE = "SET_LOCALE";
export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE";

export interface LanguageAction {
  type: typeof SET_LOCALE;
  payload: {
    locale: string;
  };
}

export interface LocaleAction {
  type : typeof SET_COUNTRY_CODE,
  payload: {
    countryCode : string
  }
}
export enum LanguagesEnum {
  fr = "fr",
  en = "en"
}
export interface SettingsState {
  locale: LanguagesEnum;
  countryCode : string | null
}
