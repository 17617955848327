import { FC, Fragment } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import selectDateRange from "modules/listing-calendar/store/selectors/select-date-range";
import useBreakpoints from "utils/useBreakpoints";

interface Props {
  onClick: () => void;
}

export const DateRangeLabel: FC<Props> = (props) => {
  const { onClick } = props;

  const breakpoints = useBreakpoints();

  const [from, to] = useSelector(selectDateRange);

  const fromDay = dayjs(from);

  const toDay = dayjs(to);

  const isDateRangeFullMonths =
    fromDay.startOf("month").isSame(from, "d") &&
    toDay.endOf("month").isSame(to, "d");

  const isDateRangeOnSameMonth =
    fromDay?.toDate().getMonth() === toDay?.toDate().getMonth() &&
    isDateRangeFullMonths;

  const monthFormat = breakpoints.md ? "MMMM" : "MMM";

  const dateFormat =
    isDateRangeOnSameMonth || isDateRangeFullMonths
      ? `${monthFormat} YYYY`
      : `DD ${monthFormat} YYYY`;

  return (
    <Fragment>
      <p onClick={onClick}>
        {`${
          dayjs(from).format(dateFormat) || dayjs(new Date()).format(dateFormat)
        }`}

        {breakpoints.sm && !isDateRangeOnSameMonth ? " - " : <br />}

        {isDateRangeOnSameMonth
          ? null
          : `${dayjs(to).format(dateFormat)}` ||
            dayjs(new Date()).format(dateFormat)}
      </p>
    </Fragment>
  );
};

export default DateRangeLabel;
