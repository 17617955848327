import dayjs from "dayjs";

/**
 * Converts a date object to iso string date but does not
 * add/subtract any hours/days or minutes
 *
 */
export default (dateLike?: Date) => {
  if (!dateLike) {
    return undefined;
  }

  const originalDate = dayjs(dateLike);

  return originalDate.toISOString();
};
