import LocalizedStrings from "react-localization";
const lang = new LocalizedStrings({
  en: {
    phoneNumberPlaceHolder: "Phone Number"
  },
  fr: {
    phoneNumberPlaceHolder: "Numéro de téléphone"
  },
  es: {
    phoneNumberPlaceHolder: "Número de teléfono"
  },
  it: {
    phoneNumberPlaceHolder: "Numero di telefono"
  }
});
export default lang;
