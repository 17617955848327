import { FC, Fragment, useMemo, useState } from "react";
import { getLocale } from "modules/settings/selectors";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Button from "@design-library/Button";
import Checkbox from "@design-library/Checkbox";
import classes from "./status-select.module.scss";
import clearStatuses from "modules/listing-calendar/store/actions/clear-statuses";
import DropdownMenu from "@design-library/DropdownMenu";
import messages from "./messages";
import selectListingStatuses from "modules/listing-calendar/store/selectors/select-listing-statuses";
import STATUS from "modules/listing-calendar/constants/statuses";
import toggleStatus from "modules/listing-calendar/store/actions/toggle-status";
import useBreakpoints from "utils/useBreakpoints";
import useLang from "@design-library/utils/useLang";

interface Props {}

export const StatusSelect: FC<Props> = (props) => {
  const breakpoints = useBreakpoints();

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  const statuses = useMemo(() => {
    return Object.values(STATUS).map((status) => ({
      value: status.toUpperCase(),
      label: lang[status]
    }));
  }, [lang]);

  const dispatch = useDispatch();

  const initiallySelectedStatuses = useSelector(selectListingStatuses);

  const [selectedStatuses, setSelectedStatuses] = useState({
    ...initiallySelectedStatuses
  });

  const onChangeStatus = (value: string) => {
    const statusValue = value as unknown as keyof typeof STATUS;
    const isToggled = selectedStatuses[statusValue];
    setSelectedStatuses({
      ...selectedStatuses,
      [statusValue]: !isToggled
    });
  };

  const onClearStatuses = () => {
    dispatch(clearStatuses());
  };

  const onClickAway = async () => {
    if (!isEqual(selectedStatuses, initiallySelectedStatuses)) {
      dispatch(toggleStatus(selectedStatuses));
    }
  };

  return (
    <Fragment>
      <div className={classes.container}>
        {/* @ts-expect-error */}
        <DropdownMenu
          onClickAway={onClickAway}
          label={lang.status}
          staticMenu={!breakpoints.xl}
          theme="alignedLeftLabel"
        >
          {statuses.map((status) => (
            <div key={status.value} className={classes.checkbox}>
              {/* @ts-expect-error  */}
              <Checkbox
                name={status.label}
                value={selectedStatuses[status.value]}
                onChange={() => onChangeStatus(status.value)}
              >
                <span data-cy="calendars.status-dropdown-published">
                  {status.label}
                </span>
              </Checkbox>
            </div>
          ))}
          <Button theme="link" onClick={onClearStatuses}>
            {lang.clear}
          </Button>
        </DropdownMenu>
      </div>
    </Fragment>
  );
};

export default StatusSelect;
