export default {
  en: {
    inquiry: "Inquiry",
    pending: "Pending",
    accepted: "Accepted",
    completed: "Completed",
    cancelled: "Cancelled",
    expired: "Expired",
    declined: "Declined",
    auto_declined: "Auto declined",
    requestBook: "Request Book",
    accept: "Accept",
    cancel: "Cancel",
    decline: "Decline",
    reportProblem: "Report a problem",
    inviteToBook: "Invite to book",
    book: "book",
    book_error: "This booking window is not available anymore"
  },
  fr: {
    inquiry: "Requête",
    pending: "En attente",
    accepted: "Accepté",
    completed: "Complété",
    cancelled: "Annulé",
    expired: "Expiré",
    declined: "Décliné",
    auto_declined: "Auto décliné",
    requestBook: "Demande de réservation",
    accept: "Accepter",
    cancel: "Annuler",
    decline: "Décliner",
    reportProblem: "Signaler un problème",
    inviteToBook: "Inviter à réserver",
    book: "Réserver",
    book_error: "Cette fenêtre de réservation n'est plus disponible"
  }
};
