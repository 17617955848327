import { requestStart, requestSuccess, requestFailure } from "./actions";
import Query from "utils/Query";

export const getActionFactory = (endpoint: string) => (
  params = {},
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .get(params)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const getByIdActionFactory = (endpointUrl: string) => (
  id: string,
  params: object = {},
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = endpointUrl.replace(":id", id);
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .get(params)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const postActionFactory = (endpoint: string) => (
  payload = {},
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .post(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const postByIdActionFactory = (endpointUrl: string, onActionSuccess?: (res: any, func: any) => undefined) => (
  id: string,
  payload: object = {},
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = endpointUrl.replace(":id", id);
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .post(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      if (onActionSuccess) onActionSuccess(json, { dispatch });
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const postByParentIdActionFactory = (endpointUrl: string) => (
  parentId: string,
  childId: string,
  payload: object = {},
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  let endpoint = endpointUrl.replace(":parentId", parentId);
  endpoint = endpoint.replace(":childId", childId);
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .post(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const updateActionFactory = (endpoint: string) => (
  payload: object = {},
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .update(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const updateByIdActionFactory = (endpointUrl: string) => (
  id: string,
  payload: object = {},
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = endpointUrl.replace(":id", id);
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .update(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const updateByParentIdActionFactory = (endpointUrl: string) => (
  parentId: string,
  childId: string,
  payload: object = {},
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  let endpoint = endpointUrl.replace(":parentId", parentId);
  endpoint = endpoint.replace(":childId", childId);
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .update(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const deleteByIdActionFactory = (endpointUrl: string) => (
  id: string,
  params: object = {},
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = endpointUrl.replace(":id", id);
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .delete(params)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const deleteAllRulesActionFactory = (endpointUrl: string) => (
  listingId:string,
  params: object = {},
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = endpointUrl.replace(":id", listingId);
  return Query(endpoint)
    .delete(params)
    .then(() => {
      onSuccess();
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const deleteByParentIdActionFactory = (endpointUrl: string) => (
  parentId: string,
  childId: string,
  params: object = {},
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  console.log({ parentId, childId });
  let endpoint = endpointUrl.replace(":parentId", parentId);
  endpoint = endpoint.replace(":childId", childId);
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .delete(params)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const uploadActionFactory = (endpoint: string) => (
  formData: FormData,
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .upload(formData)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const uploadByIdActionFactory = (endpointUrl: string) => (
  id: string,
  formData: FormData,
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = endpointUrl.replace(":id", id);
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .upload(formData)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};
