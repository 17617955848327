export default {
  en: {
    apply: "Apply",
    cancel: "Clear dates",
    when: "When",
    checkin: "Checkin",
    checkout: "Checkout",
    firstDayOfWeek: 0,
    dates: "Dates",
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    daysOfWeekShort: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    daysOfWeekLong: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    errorMessages: {
      ERROR_MINIMUM_NIGHTS: "You must book a minimum of {value} nights",
      ERROR_MAXIMUM_NIGHTS: "You can book a maximum of {value} of nights"
    }
  },
  fr: {
    cancel: "Effacer",
    apply: "Appliquer",
    when: "Quand",
    dates: "Dates",
    checkin: "Arrivée",
    checkout: "Départ",
    firstDayOfWeek: 0,
    months: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre"
    ],
    daysOfWeekShort: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    daysOfWeekLong: [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi"
    ],
    errorMessages: {
      ERROR_MINIMUM_NIGHTS: "Vous devez louer un minimum de {value} nuits",
      ERROR_MAXIMUM_NIGHTS: "Vous pouvez louer un maximum de {value} nuits"
    }
  }
};
