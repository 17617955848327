import { useEffect, Children, ReactNode } from "react";

import PropTypes from "prop-types";
// @ts-ignore
import parsePrice from "parse-price";
import { useDispatch } from "react-redux";

import { changeCurrency } from "modules/currency/actions";

import { SYMBOLS, DEFAULT_CURRENCY, RatesInterface } from "./types";

export const convertCurrencyFormatted = (
  value: string | number,
  from: string = "CAD",
  to: string = "CAD",
  rates: RatesInterface = {}
): string => {
  // check if user currency is valid
  if (rates[from] === undefined || from === to) {
    const price = parsePrice(value);
    return `${SYMBOLS[from]}${Math.ceil(price)} ${from}`;
  }
  const rate = rates[from];
  const price = parsePrice(value);
  const converted = Math.ceil((price / rate));
  return `${SYMBOLS[to]}${converted} ${to}`;
};

export const formatCurrency = (
  value: number | string,
  currency: string
): string => `${SYMBOLS[currency]}${value} ${currency}`;

export const formatCurrencyWithoutSuffix = (
  value: number,
  currency: string
): string => `${SYMBOLS[currency]}${parsePrice(value).toFixed(0)}`;

export const convertCurrency = (
  value: string,
  from: string = "CAD",
  to: string = "CAD",
  rates: RatesInterface = {}
): string => {
  // check if user currency is valid
  if (rates[from] === undefined || from === to) {
    return parsePrice(value);
  }
  const rate = rates[from];
  const price = parsePrice(value);
  return (price / rate).toFixed(2);
};

type Props = {
  children?: ReactNode;
};

/**
 * react component
 * @param {Object} props passed to this component
 */
const Currency = (props: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      changeCurrency(localStorage.getItem("currency") || DEFAULT_CURRENCY)
    );
    // eslint-disable-next-line
  }, []);
  return Children.only(props.children);
};

Currency.propTypes = {
  children: PropTypes.node.isRequired
};

export default Currency;
