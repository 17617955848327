import React, { FC } from "react";
import classes from "./tilesets.module.scss";
import { TileProps } from "./types";
import clsx from "clsx";

interface Props extends TileProps {}

const TileStart: FC<Props> = (props) => {
  const { color = "primary", children, className = "" } = props;

  return (
    <div
      className={clsx({
        [classes.tile]: true,
        [classes.tileStart]: true,
        [classes[color]]: true,
        [className]: true
      })}
    >
      {children}
    </div>
  );
};

export default TileStart;
