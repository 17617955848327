import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState, SegmentMap } from "../types";
import { produce } from "immer";
import getRangeId from "modules/listing-calendar/utils/get-range-id";

export default (
  state: ContainerState,
  action: PayloadAction<SegmentMap>
): ContainerState => {
  const payload = action.payload;
  switch (action.type) {
    case SEGMENT_CHANGED: {
      const pages = Object.keys(state.pages);

      return produce(state, (draft) => {
        draft.segment.x1 = payload.x1;
        draft.segment.x2 = payload.x2;
        draft.rangeStatus = produce(state.rangeStatus, (draft) => {
          pages.forEach((p) => {
            const page = Number(p);
            const rangeId = getRangeId(page, payload.x1, payload.x2);
            draft[rangeId] = "loading";
          });
        });
      });
    }
    default:
      return state;
  }
};

export const SEGMENT_CHANGED = "SEGMENT_CHANGED";
