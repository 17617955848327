import { createSelector } from "reselect";
import { CurrencyState } from "./types";

interface RootState {
  currency: CurrencyState;
}

export const getCurrency = createSelector(
  (state: RootState) => state.currency,
  (currency: CurrencyState) => currency
);
