import { ContainerState } from "../types";
import initialState from "../initial-state";

const reducers: ((
  state: typeof initialState,
  action: unknown
) => typeof initialState)[] = [
  /**
   * all explore reducers should be put here
   *
   */
  require("./map-display-mode-changed").default,
  require("./map-search-mode-changed").default
];

/**
 * Explore root reducer
 *
 */
export default (state = initialState, action: any) => {
  let newState: ContainerState;
  switch (action.type) {
    // Global reducers go here

    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
};
