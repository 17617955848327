export const CALENDAR_REMOVE = "CALENDAR_REMOVE";
export const CALENDAR_ADD_MONTHS = "CALENDAR_ADD_MONTHS";
export const CALENDAR_REMOVE_MONTHS = "CALENDAR_REMOVE_MONTHS";

interface Payload {
  calendarId: string;
  monthId?: string;
  data?: object;
}

interface CalendarAction {
  type:
    | typeof CALENDAR_ADD_MONTHS
    | typeof CALENDAR_REMOVE_MONTHS
    | typeof CALENDAR_REMOVE;
  payload: Payload;
}

export type CalendarActionTypes = CalendarAction;
