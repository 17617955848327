import { FC, memo } from "react";
import classes from "./place-loaders.module.scss";
import clsx from "clsx";
import Skeleton from "components/Skeleton";

interface Props {}

export const ListingNamePlaceLoader: FC<Props> = memo(() => {
  return (
    <div className={clsx(classes.container)}>
      <div className={clsx(classes.heading, classes.title)}>
        <Skeleton className={classes.img} />
        <Skeleton className={classes.listingTitle} />
      </div>
    </div>
  );
});

export default memo(ListingNamePlaceLoader);
