// Modals constants and types

export const CREATE_MODAL = "CREATE_MODAL";
export const DELETE_MODAL = "DELETE_MODAL";
export const TOGGLE_MODAL_VISIBILITY = "TOGGLE_MODAL_VISIBILITY";
export const SET_MODAL_VISIBILITY = "SET_MODAL_VISIBILITY";
export const SET_MODAL_TITLE = "SET_MODAL_TITLE";
export const SET_MODAL_DATA = "SET_MODAL_DATA";
export const UNSET_MODAL_DATA = "UNSET_MODAL_DATA";

export interface ModalAction {
  type:
    | typeof CREATE_MODAL
    | typeof DELETE_MODAL
    | typeof TOGGLE_MODAL_VISIBILITY
    | typeof SET_MODAL_VISIBILITY
    | typeof SET_MODAL_TITLE
    | typeof SET_MODAL_DATA
    | typeof UNSET_MODAL_DATA;
  payload: {
    id: string;
    visible?: boolean;
    title?: string;
    data?: any;
  };
}
export interface ModalState {
  visible: boolean;
  title?: string;
}

export interface ModalsState {
  [key: string]: object;
}

export type ModalActionTypes = ModalAction;
