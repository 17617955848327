import { ContainerState } from "./types";

/**
 * Layout initial state
 *
 */
const initialState: ContainerState = {
  acceptedCookies: {},
  modals: {}
};

export default initialState;
