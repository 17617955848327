import { createSelector } from "reselect";
import { selectDomain } from ".";
import { ObjectId } from "modules/shared/types/object-id";
import dayjs from "dayjs";
import { Period } from "modules/listing-calendar/types/period";

/**
 * Returns whether or not does the day given have
 * an overlapping period
 *
 */
export default (listingId?: ObjectId, day?: string) =>
  createSelector([selectDomain], (state) => {
    if (!day || !listingId) {
      return false;
    }

    const overlappedPeriods = Object.values(
      state.overlappedPeriods[listingId] ?? {}
    );

    const dayJsDay = dayjs(day);

    return overlappedPeriods.some((overlappedPeriod) =>
      isOverlapping(dayJsDay, overlappedPeriod)
    );
  });

const isOverlapping = (
  startDate: dayjs.Dayjs,
  overlappingPeriod: Period
): boolean => {
  const startsAt = dayjs(overlappingPeriod.start_date);
  const endsAt = dayjs(overlappingPeriod.end_date);

  return startDate.isBetween(startsAt, endsAt, "date", "[]");
};
