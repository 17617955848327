export default {
  validation: {
    birthday: {
      required: "The birthday field is required.",
      day: {
        required: "The day is required."
      },
      month: {
        required: "The month is required."
      },
      year: {
        required: "The year is required."
      },
      invalidDate: "The birthday field is not a valid date.",
      gte18: "You need to be at least 18 years old."
    }
  },
  search: {
    cta: "Where do you want to go?"
  },
  actions: {
    addProperty: "Add a property",
    addPropertySmall: "Add"
  },
  mobileNavigation: {
    explore: "Explore",
    favorites: "Favorites",
    trips: "Trips",
    inbox: "Inbox",
    listings: "Management",
    calendars: "MultiCal"
  },
  navigation: {
    beta: "Beta",
    home: "Home",
    explore: "Explore",
    favorites: "Favorites",
    trips: "Trips",
    inbox: "Inbox",
    listings: "Management",
    calendars: "MultiCal (Beta)",
    menu: "Menu",
    login: "Login",
    logout: "Logout",
    signup: "Sign up",
    profile: "Profile",
    payoutSettings: "Payouts",
    security: "Password",
    transactions: "Transactions",
    integrations: "Integrations",
    help: "Help articles",
    blog: "Blog",
    facebook: "Facebook community",
    hostingGuide: "Hosting guide",
    ourStory: "Our story",
    partners: "Partners",
    contact: "Contact",
    becomeHost: "Become a host",
    hostMode: "Host mode",
    guestMode: "Travel Mode",
    alert: "alert",
    alerts: "alerts"
  },
  routes: {
    contactUs: {
      title: "Contact"
    },
    becomeHost: {
      title: "Become a host"
    },
    ourPartners: {
      title: "Partners"
    },
    search: {
      title: "Discover Unique Homes in Nature"
    },
    users: {
      title: "{0} listings"
    },
    photography: {
      title: "Photography"
    },
    transactionsTravelling: {
      title: "Travelling Transactions"
    },
    transactionsHosting: {
      title: "Hosting Transactions"
    },
    transactions: {
      title: "Transactions"
    },
    profile: {
      title: "Profile settings",
      header: "Settings",
      tab: "Profile"
    },
    payoutSettings: {
      title: "Payout Preferences"
    },
    changePassword: {
      title: "Password"
    },
    hosting: {
      title: "Listings",
      headerTitle: "Management"
    },
    integrations: {
      title: "Integrations"
    },
    integrationsPricelabs: {
      title: "PriceLabs"
    },
    hostingGuide: {
      title: "Hosting guide"
    },
    listingForm: {
      title: "Property",
      addTitle: "Add property",
      editTitle: "Edit property",
      space: {
        title: "Space"
      },
      location: {
        title: "Location"
      },
      amenities: {
        title: "Amenities"
      },
      photos: {
        title: "Photos"
      },
      description: {
        title: "Description"
      },
      booking_settings: {
        title: "Booking"
      },
      pricing: {
        title: "Pricing"
      },
      calendar: {
        title: "Calendar"
      },
      guest_material: {
        title: "Material"
      },
      status: {
        title: "Status & taxes"
      }
    },
    inbox: {
      title: "Inbox"
    },
    trips: {
      title: "Trips",
      upcoming: "Upcoming",
      past: "Past",
      cancelled: "Cancelled"
    },
    invoices: {
      title: "Transaction history"
    }
  },
  externalLinks: {
    help: "https://help.wechalet.com/en/",
    blog: "https://blog.wechalet.com/blog/",
    facebook: "https://www.facebook.com/groups/wechaletfr",
    ourStory: "https://blog.wechalet.com/wechalet-story-dany-papineau-founder/"
  },
  footer: {
    copyright: "© 2022 WeChalet.com",
    terms: "Terms and conditions"
  },
  general: {
    preview: "Preview",
    hosting: "Hosting",
    travelling: "Travelling",
    back: "Retour"
  }
};
