import { User } from "./user";

// Authentication Reducer Constants
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const LOGIN_MODAL = "LOGIN_MODAL";
export const REGISTER_MODAL = "REGISTER_MODAL";
export const RESET_PASSWORD_MODAL = "RESET_PASSWORD_MODAL";
export const USER_FAVORITES_UPDATE = "USER_FAVORITES_UPDATE";
export const USER_FAVORITES_INCREMENT = "USER_FAVORITES_INCREMENT";
export const USER_FAVORITES_DECREMENT = "USER_FAVORITES_DECREMENT";
export const USER_TRIPS_UPDATE = "USER_TRIPS_UPDATE";

export interface AuthState {
  isAuthenticating: boolean;
  user?: User;
  error: string | null;
  favoritesCount: number;
  tripsCount: number;
}

export interface AuthenticationAction {
  type:
    | typeof USER_LOGIN_REQUEST
    | typeof USER_LOGIN_FAILURE
    | typeof USER_LOGOUT_REQUEST
    | typeof USER_FETCH_SUCCESS
    | typeof USER_UPDATE_SUCCESS
    | typeof USER_LOGOUT_SUCCESS
    | typeof USER_FAVORITES_UPDATE
    | typeof USER_FAVORITES_INCREMENT
    | typeof USER_FAVORITES_DECREMENT
    | typeof USER_TRIPS_UPDATE;
  payload?: object | string | number | null;
}
