import CookieBanner from "modules/layout/components/cookie-banner";
import initializeConsentCookie from "modules/layout/store/actions/initialize-consent-cookie";
import selectIsCookieBannerVisible from "modules/layout/store/selectors/select-is-cookie-banner-visible";
import { FC, ReactNode, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  children: ReactNode;
}

const CookieConsentProvider: FC<Props> = (props) => {
  const { children } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeConsentCookie());
  }, [dispatch]);

  const showCookieBanner = useSelector(selectIsCookieBannerVisible);

  return (
    <Fragment>
      {showCookieBanner && <CookieBanner />}
      {children}
    </Fragment>
  );
};

export default CookieConsentProvider;
