import React, { FC, Fragment } from "react";
import classes from "./tilesets.module.scss";
import { TileProps } from "./types";
import clsx from "clsx";

type C = TileProps["color"];

interface Props extends TileProps {
  previousColor?: C;
}

const TileCross: FC<Props> = (props) => {
  const {
    color = "primary",
    previousColor = "primary",
    children,
    className = ""
  } = props;

  return (
    <Fragment>
      <div
        className={clsx({
          [classes.tile]: true,
          [classes.tileFiller]: true,
          [classes[previousColor]]: true,
          [className]: true
        })}
      ></div>
      <div
        className={clsx({
          [classes.tile]: true,
          [classes.tileCross]: true,

          [classes[color]]: true,
          [className]: true
        })}
      >
        {children}
      </div>
    </Fragment>
  );
};

export default TileCross;
