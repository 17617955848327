import PropTypes from "prop-types";
import "./index.scss";
import Loader from "./Loader";
import clsx from "clsx";

/**
 * returns list of classes to be applied to a button.
 * @param {String} theme theme of button.
 *
 * @returns {String} a classnames string.
 */
const getLoaderColor = (theme, type) => {
  if (type === "default" && theme === "default") {
    return "#4f5253";
  }
  if (type === "default" && theme !== "default") {
    return "white";
  }
  if (theme === "default") {
    return "#4f5253";
  }
  if (theme === "info") {
    return "#4f5253";
  }
  if (theme === "primary") {
    return "#4f9589";
  }
  if (theme === "secondary") {
    return "#fe6701";
  }
};

/**
 * Button
 * @deprecated Use new Button instead
 */
var Button = ({
  label,
  theme,
  type,
  onClick,
  isLoading,
  children,
  className,
  ...attrs
}) => {
  return (
    <button
      className={clsx("WC_Button", className, {
        info: theme === "info",
        primary: theme === "primary",
        secondary: theme === "secondary",
        outline: type === "outline"
      })}
      onClick={onClick}
      {...attrs}
    >
      <span className="Icon"></span>
      {isLoading ? (
        <Loader size={10} color={getLoaderColor(theme, type)} />
      ) : (
        <span className="Text">{label}</span>
      )}
    </button>
  );
};

Button.propTypes = {
  /** String to be showed as button label */
  label: PropTypes.string.isRequired,
  /** String theme of the button default,primary,secondary */
  theme: PropTypes.oneOf(["default", "info", "primary", "secondary"]),
  /** String type of the button default,outline */
  type: PropTypes.oneOf(["default", "outline"]),
  /** Boolean indicating whether button shows loading indicator or not */
  isLoading: PropTypes.bool,
  /** Function to be triggered when user clicks on the button */
  onClick: PropTypes.func
};

const noop = () => {};

Button.defaultProps = {
  theme: "default",
  type: "default",
  isLoading: false,
  onClick: noop
};

export default Button;
