export default {
  validation: {
    birthday: {
      required: "Le champ date de naissance est obligatoire.",
      day: {
        required: "Le jour est obligatoire."
      },
      month: {
        required: "Le mois est obligatoire."
      },
      year: {
        required: "L'année est obligatoire."
      },
      invalidDate: "Le champ date de naissance n'est pas une date valide.",
      gte18: "Vous devez avoir au moins 18 ans."
    }
  },
  search: {
    cta: "Où voulez-vous aller?"
  },
  actions: {
    addProperty: "Ajouter une propriété",
    addPropertySmall: "Ajouter"
  },
  mobileNavigation: {
    explore: "Explorer",
    favorites: "Favoris",
    trips: "Voyages",
    inbox: "Messages",
    listings: "Gestion",
    calendars: "MultiCal"
  },
  navigation: {
    home: "Accueil",
    beta: "Béta",
    explore: "Explorer",
    favorites: "Favoris",
    trips: "Voyages",
    inbox: "Messages",
    listings: "Gestion",
    calendars: "MultiCal (Béta)",
    menu: "Menu",
    login: "Connexion",
    logout: "Se déconnecter",
    signup: "S'inscrire",
    profile: "Profil",
    payoutSettings: "Paiments",
    security: "Mot de passe",
    transactions: "Transactions",
    integrations: "Intégrations",
    help: "Articles d'aide",
    blog: "Blogue",
    facebook: "Communauté Facebook",
    hostingGuide: "Guide d'hôte",
    ourStory: "Notre histoire",
    partners: "Partenaires",
    contact: "Contact",
    becomeHost: "Devenez un hôte",
    hostMode: "Mode hôte",
    guestMode: "Mode Voyage",
    alert: "alerte",
    alerts: "alertes"
  },
  routes: {
    contactUs: {
      title: "Contact"
    },
    becomeHost: {
      title: "Devenez hôte"
    },
    ourPartners: {
      title: "Partenaires"
    },
    search: {
      title: "Découvrez des maisons uniques en nature"
    },
    users: {
      title: "Les annonces de {0}"
    },
    photography: {
      title: "Photographie"
    },
    transactionsTravelling: {
      title: "Transactions de voyage"
    },
    transactions: {
      title: "Transactions"
    },
    transactionsHosting: {
      title: "Historique des transactions"
    },
    profile: {
      title: "Paramètres du profil",
      header: "Paramètres",
      tab: "Profil"
    },
    payoutSettings: {
      title: "Préférences de paiement"
    },
    changePassword: {
      title: "Mot de passe"
    },
    hosting: {
      title: "Annonces",
      headerTitle: "Gestion"
    },
    integrations: {
      title: "Intégrations"
    },
    hostingGuide: {
      title: "Guide d'hôte"
    },
    listingForm: {
      title: "Propriété",
      addTitle: "Ajouter une propriété",
      editTitle: "Éditer la propriété",
      space: {
        title: "Espace"
      },
      location: {
        title: "Localisation"
      },
      amenities: {
        title: "Équipements"
      },
      photos: {
        title: "Photos"
      },
      description: {
        title: "Description"
      },
      booking_settings: {
        title: "Réservation"
      },
      pricing: {
        title: "Prix"
      },
      calendar: {
        title: "Calendrier"
      },
      guest_material: {
        title: "Matériel"
      },
      status: {
        title: "Statut & taxes"
      }
    },
    inbox: {
      title: "Messages"
    },
    trips: {
      title: "Voyages",
      upcoming: "À venir",
      past: "Précédents",
      cancelled: "Annulés"
    },
    invoices: {
      title: "Historique des transactions"
    }
  },
  externalLinks: {
    help: "https://help.wechalet.com/fr/",
    blog: "https://blog.wechalet.com/blog/",
    facebook: "https://www.facebook.com/groups/wechaletfr",
    ourStory: "https://blog.wechalet.com/fr/lhistoire-wechalet-dany-papineau/"
  },
  footer: {
    copyright: "© 2022 WeChalet.com",
    terms: "Termes et conditions"
  },
  general: {
    preview: "Aperçu",
    hosting: "Hébergement",
    travelling: "Voyages",
    back: "Back"
  }
};
