import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";

export default (
  state: ContainerState,
  action: PayloadAction<StatusPayload>
): ContainerState => {
  switch (action.type) {
    case STATUS_TOGGLED: {
      const statuses = action.payload;
      return {
        ...state,
        statuses: {
          ...state.statuses,
          ...statuses
        }
      };
    }
    default:
      return state;
  }
};

export type StatusPayload = ContainerState["statuses"];

export const STATUS_TOGGLED = "STATUS_TOGGLED";
