import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";

export default (
  state: ContainerState,
  action: PayloadAction<{ acceptedAt: string }>
): ContainerState => {
  switch (action.type) {
    case ALL_COOKIES_ACCEPTED:
      return {
        ...state,
        cookiesAcceptedAt: action.payload.acceptedAt,
        acceptedCookies: {
          marketing: true
        }
      };
    default:
      return state;
  }
};

export const ALL_COOKIES_ACCEPTED = "ALL_COOKIES_ACCEPTED";
