import Loading from "components/wechalet-loader";
import loadable from "utils/loadable";

import locales from "./locales";
import { RouterRoute } from "./types";

export const getLocalizedPaths = (route: RouterRoute) => {
  const localizedPaths: { [locale: string]: string } = {};

  locales.forEach((locale) => {
    localizedPaths[locale] = `/${locale}${route?.locales?.[locale] || route?.path}`;
  });

  return localizedPaths;
};

export const viewLoadable = (
  importFunc: () => Promise<
    { default: React.ComponentType<any> }
  >
) => {
  return loadable(importFunc, { fallback: Loading, timeout: 15000 });
};

export const getLocalizedPath = (route: RouterRoute, locale: string) => {
  const paths = getLocalizedPaths(route);

  return paths?.[locale] || "";
};

export const getRouteIsActive = (currentRoute: RouterRoute, targetRoute: RouterRoute, { exact = false, ignoreSubPaths = false } = {}) => {
  if (currentRoute?.path == null || targetRoute?.path == null) return false;
  return (
    exact
      ? currentRoute === targetRoute
      : (
        ignoreSubPaths
          ? currentRoute === targetRoute
          : targetRoute.path === currentRoute.path.slice(0, targetRoute.path.length)
      ) || targetRoute.activePaths?.includes(currentRoute.path)
  );
};

/**
 * Removes all query params from a url
 *
 */
export const removeAllQueryParams = (url: string): string => {
  return url.replace(/(\?.*)/, "");
};
