import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";

export default (
  state: ContainerState,
  action: PayloadAction<StatusPayload>
): ContainerState => {
  switch (action.type) {
    case STATUS_CLEARED: {
      return {
        ...state,
        statuses: {}
      };
    }
    default:
      return state;
  }
};

export type StatusPayload = void;

export const STATUS_CLEARED = "STATUS_CLEARED";
