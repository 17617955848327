import { useState, useMemo, useEffect } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker/DayPicker";
import clsx from "clsx";
import LocalizedStrings from "react-localization";
import { convertCurrency, formatCurrencyWithoutSuffix } from "modules/currency";
import { useSelector } from "react-redux";
import { getCurrency } from "modules/currency/selectors";

import strings from "./strings";
import Navbar from "./Navbar";

import "./index.scss";
import styles from "./DatePicker.module.scss";

const ERROR_MAXIMUM_NIGHTS = "ERROR_MAXIMUM_NIGHTS";
const ERROR_MINIMUM_NIGHTS = "ERROR_MINIMUM_NIGHTS";

const DatePicker = ({
  locale,
  numberOfMonths,
  initialEndDate,
  initialStartDate,
  availabilityCalendar,
  isLoading,
  onCancel,
  onApply,
  onError,
  onChange,
  onMonthChange,
  livePrice,
  isPreviousDatesDisabled,
  isFullWidth,
  forceWeekend,
  currency
}) => {
  const [state, setState] = useState({
    start: initialStartDate,
    end: initialEndDate,
    enteredTo: initialEndDate
  });

  const [disabledDays, setDisabledDays] = useState(() => {
    if (!isPreviousDatesDisabled) return [];
    return [{ before: new Date() }];
  }
  );

  const { base, rates } = useSelector(getCurrency);

  const [selectedDays, setSelectedDays] = useState([
    state.start,
    {
      from: state.start,
      to: state.enteredTo
    }
  ]);

  // modifier for min days
  const [minimumDays, setMinimumDays] = useState([]);
  const [maximumDays, setMaximumDays] = useState([]);
  const [firstAvailableDay, setFirstAvailableDay] = useState([]);

  const lang = useMemo(() => {
    const localizedStrings = new LocalizedStrings(strings);
    localizedStrings.setLanguage(locale);
    return localizedStrings;
  }, [locale]);

  useEffect(() => {
    onChange(state);
    setSelectedDays([
      state.start,
      {
        from: state.start,
        to: state.enteredTo
      }
    ]);
  }, [state]); // eslint-disable-line

  /**
   * when component fetches calendar from backend
   * we build an array of disabled dates.
   */
  useEffect(() => {
    if (Object.values(availabilityCalendar).length) {
      const [date] = selectedDays;
      if (date) {
        setDisabledDays(getDisabledDatesForCheckout(date));
      } else {
        setDisabledDays(getDisabledDatesForCheckin());
      }
    }
  }, [availabilityCalendar]); // eslint-disable-line

  /**
   * checks if a user is selecting first day or not
   * @param {Date} start start date
   * @param {Date} end end date
   * @param {Date} date current date
   * @returns {Boolean}
   */
  const isSelectingFirstDay = (start, end, date) => {
    const isBeforeFirstDay = start && dayjs(date).isBefore(dayjs(start), "day");
    const isRangeSelected = start && end;
    return !start || isBeforeFirstDay || isRangeSelected;
  };

  /**
   * Event handler for day cell click event
   * @param {Date} date current date being rendered by calendar
   * @param {Object} modifiers modifier being matched with the current date
   */
  const handleDayClick = (date, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    if (modifiers.minimum) {
      onError(
        lang.formatString(lang?.errorMessages[ERROR_MINIMUM_NIGHTS], {
          value: getMinStay(state.start)
        })
      );
      return;
    }

    if (modifiers.maximum) {
      onError(
        lang.formatString(lang?.errorMessages[ERROR_MAXIMUM_NIGHTS], {
          value: getMaxStay(state.start)
        })
      );
      return;
    }

    const { start, end } = state;
    let newState = {};
    // case where we are selecting the first day
    if (isSelectingFirstDay(start, end, date)) {
      newState = {
        ...state,
        start: date,
        end: null,
        enteredTo: null
      };
      setState(newState);
      notifyOutsideWorld(newState);
      setDisabledDays(getDisabledDatesForCheckout(date));
      setMinimumDays(getMinimumNightsFromDate(date));
      setMaximumDays(getMaximumNightsFromDate(date));
      setFirstAvailableDay(getFirstAvailableFromDate(date));
    } else {
      newState = {
        ...state,
        end: date,
        enteredTo: date
      };
      setState(newState);
      notifyOutsideWorld(newState);
      resetMinMaxStay();
    }
  };

  /**
   * Event handler for mouse hovering on day cells
   * @param {Date} date
   */
  const handleDayMouseEnter = (date) => {
    if (!isSelectingFirstDay(state.start, state.end, date)) {
      setState({
        ...state,
        enteredTo: date
      });
    }
  };

  /**
   * Event handler for mouse hovering on day cells
   * @param {Date} date
   */
  const handleCancelButtonClick = (day, modifiers) => {
    const nullState = {
      start: null,
      end: null,
      enteredTo: null
    };
    setDisabledDays(getDisabledDatesForCheckin());
    resetMinMaxStay();
    setState(nullState);
    onCancel(nullState);
  };

  /**
   * Notify the outside world that state of this component changed.
   * @param {Object} state calendar state
   */
  const notifyOutsideWorld = ({ start, end }) => {
    // we call onApply callback to update outer components
    const isRangeSelected = start && end;
    if (isRangeSelected && dayjs(start).isBefore(dayjs(end), "day")) {
      onApply({
        start: dayjs(start).format("YYYY-MM-DD"),
        end: dayjs(end).format("YYYY-MM-DD")
      });
    }
  };

  /**
   * Gets All disabled dates
   */
  const getDisabledDatesForCheckin = () => {
    const disabledDatesForCheckin = Object.values(availabilityCalendar)
      .map((days) =>
        days
          .filter(
            (e) => e.is_available_for_checkin === false && e.date !== null
          )
          .map((e) => {
            const currDate = new Date(e.date);
            return new Date(
              currDate.getTime() + currDate.getTimezoneOffset() * 60000
            );
          })
      )
      .reduce((prev, current) => {
        return [...prev, ...current];
      }, []);
    return [
      {
        before: new Date()
      },
      ...disabledDatesForCheckin
    ];
  };

  /**
   * Update disabled dates based on user selected date.
   * @param {Date} date currently selected date by the user
   */
  const getDisabledDatesForCheckout = (date) => {
    // get all disabled days after this date.
    const disabledDatesForCheckout = Object.values(availabilityCalendar)
      .map((days) =>
        days
          .filter(
            (e) => e.is_available_for_checkout === false && e.date !== null
          )
          .filter((e) => dayjs(e.date).isAfter(dayjs(date), "day"))
          .map((e) => {
            const currDate = new Date(e.date);
            return new Date(
              currDate.getTime() + currDate.getTimezoneOffset() * 60000
            );
          })
      )
      .reduce((prev, current) => {
        return [...prev, ...current];
      }, []);

    // check if disabled dates are present
    if (disabledDatesForCheckout.length) {
      // get first disabled date in the upcoming period
      const dateToStartDisabling = dayjs(disabledDatesForCheckout.shift());

      return [
        {
          before: date // block all dates before selected date
        },
        dateToStartDisabling.toDate(), // block dateToStartDisabling
        {
          after: dateToStartDisabling.toDate() // block all dates after date to start disabling
        }
      ];
    }
    return [
      {
        before: date // block all dates before selected date
      }
    ];
  };

  /**
   * Update minimum nights modifier based on selected date.
   * @param {Date} date currently selected start date by the user
   */
  const getMinimumNightsFromDate = (date) => {
    const minNights = getMinStay(date);
    const before = dayjs(date).add(minNights, "days");
    return [
      {
        before: before.toDate(),
        after: date
      }
    ];
  };

  /**
   * Update maximum nights modifier based on selected date.
   * @param {Date} date currently selected start date by the user
   */
  const getMaximumNightsFromDate = (date) => {
    const maxNights = getMaxStay(date);
    const after = dayjs(date).add(maxNights, "days");
    return {
      after: after.toDate()
    };
  };

  /**
   * Update maximum nights modifier based on selected date.
   * @param {Date} date currently selected start date by the user
   */
  const getFirstAvailableFromDate = (date) => {
    const minNights = getMinStay(date);
    const firstAvailable = dayjs(date).add(minNights, "days");
    return [firstAvailable.toDate()];
  };

  /**
   * Get minimum nights based on selected date.
   * @param {Date} date currently selected start date by the user
   *
   * @returns {Integer}
   */
  const getMinStay = (date) => {
    const day = getCurrentDateFromCalendar(date);
    const minNights =
      day !== undefined && day.min_nights > 0 ? day.min_nights : 1;
    if (minNights === 1 && forceWeekend && dayjs(date).day() === 5) {
      return 2;
    }
    return minNights;
  };

  /**
   * Get maximum nights based on selected date.
   * @param {Date} date currently selected start date by the user
   *
   * @returns {Integer}
   */
  const getMaxStay = (date) => {
    const day = getCurrentDateFromCalendar(date);
    const maxNights =
      day !== undefined && day.max_nights > 2 ? day.max_nights : 9999;
    return maxNights;
  };

  const isDayBetween = (dayToCheck, startDay, endDay) => {
    if (dayToCheck === null || startDay === null || endDay === null) {
      return false;
    }
    return dayjs(dayToCheck).isBetween(startDay, endDay, "day", "[]");
  };

  /**
   * Reset minimum nights modifier based on selected date.
   */
  const resetMinMaxStay = () => {
    setMinimumDays([]);
    setMaximumDays([]);
    setFirstAvailableDay([]);
  };

  const getCurrentDateFromCalendar = (date) => {
    // convert selected date to moment object
    const selectedDate = dayjs(date);
    const startOfMonth = selectedDate.startOf("month").format("YYYY-MM-DD");
    // get current month from listing calendar
    const selectedMonth = availabilityCalendar[startOfMonth];
    return selectedMonth?.find((e) => dayjs(e.date).isSame(dayjs(date), "day"));
  };

  /**
   * list of modifiers to apply to the day picker dates.
   */
  const modifiers = {
    start: state.start,
    end: state.enteredTo,
    minimum: minimumDays,
    maximum: maximumDays,
    firstAvailable: firstAvailableDay
  };

  const renderDay = (day) => {
    const formatedDay = dayjs(day).format("YYYY-MM-DD");
    const item = availabilityCalendar[dayjs(day).format("YYYY-MM-01")]?.find((e) => e.date === formatedDay);
    let price = item && convertCurrency(item?.price, currency, base, rates);
    const isBetween = isDayBetween(day, selectedDays[1].from, selectedDays[1].to);
    if (isBetween) {
      price = item && convertCurrency(livePrice?.price ?? item?.price, currency, base, rates);
    }
    return (
      <div className={clsx(styles.dayStyle, isBetween && styles.selectedDayPrice)}>
        <span >{day.getDate()}</span>
        {price && <span >{formatCurrencyWithoutSuffix(price, base)}</span>}
      </div>
    );
  };

  return (
    <DayPicker
      className={clsx(
        "WechaletDayPicker",
        isLoading && "WechaletDayPicker--loading",
        {
          [styles.fullWidth]: isFullWidth,
          [styles.containedWidth]: !isFullWidth,
          [styles["lower-z-index"]]: isFullWidth
        }
      )}
      locale={locale}
      months={lang.months}
      renderDay={renderDay}
      weekdaysLong={lang.daysOfWeekLong}
      weekdaysShort={lang.daysOfWeekShort}
      firstDayOfWeek={lang.firstDayOfWeek}
      numberOfMonths={numberOfMonths}
      todayButton={lang.cancel}
      navbarElement={<Navbar />}
      month={state.end ? state.start : new Date()}
      fromMonth={new Date()}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayMouseEnter}
      onTodayButtonClick={handleCancelButtonClick}
      disabledDays={disabledDays}
      selectedDays={selectedDays}
      modifiers={modifiers}
      onMonthChange={onMonthChange}
    ></DayPicker>
  );
};

DatePicker.propTypes = {
  /** Date indicating the default start date the calendar should render */
  initialStartDate: PropTypes.object,
  /** Date indicating the default end date the calendar should render */
  initialEndDate: PropTypes.object,
  /** Object containing the rules of availability */
  availabilityCalendar: PropTypes.object,
  /** String indicating the default locale the calendar should render in */
  locale: PropTypes.oneOf(["en", "fr", "es", "it"]),
  /** Number indicating the number of months visible in date calendar */
  numberOfMonths: PropTypes.number.isRequired,
  /** Boolean indicating whether calendar is in loading state */
  isLoading: PropTypes.bool,
  /** Function to be triggered when user clicks on apply button */
  onApply: PropTypes.func.isRequired,
  /** Function to be triggered when user clicks on cancel button */
  onCancel: PropTypes.func.isRequired,
  /** Function to be triggered when user change state */
  onChange: PropTypes.func.isRequired,
  /** Function to be triggered when a validation error fires */
  onError: PropTypes.func,
  /** Function to be triggered when click on next month */
  onMonthChange: PropTypes.func,
  /** Boolean indicating whether previous dates should be disabled */
  isPreviousDatesDisabled: PropTypes.bool,
  /**  Boolean indicating whether the date picker should extend its width 100% of its container width */
  isFullwidth: PropTypes.bool,
  /** Boolean indicating whether the date picker should force weekends */
  forceWeekend: PropTypes.bool,
  /** The listing currency */
  currency: PropTypes.string
};

const noop = () => { };

DatePicker.defaultProps = {
  initialStartDate: null,
  initialEndDate: null,
  locale: "en",
  numberOfMonths: 2,
  isLoading: false,
  currency: "CAD",
  onCancel: noop,
  onError: noop,
  onChange: noop,
  onMonthChange: noop,
  availabilityCalendar: {},
  isPreviousDatesDisabled: true,
  isFullWidth: false,
  forceWeekend: false
};

export default DatePicker;
