import {
  CONSENT_COOKIE_LIFE_TIME,
  CONSENT_COOKIE_NAME
} from "../constants/consent-cookie";

/**
 * Persists the consent cookie
 *
 */
export default (payload: object) => {
  // TODO: handle this in saga

  const expiresAfter = CONSENT_COOKIE_LIFE_TIME;

  const value = JSON.stringify(payload);

  const consentCookie = `${CONSENT_COOKIE_NAME}=${value}; max-age=${expiresAfter}; path=/;`;

  document.cookie = consentCookie;
};
