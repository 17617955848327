import { ALL_COOKIES_ACCEPTED } from "../reducers/all-cookies-accepted";
import { CookieMap } from "../types";
import { now } from "lodash";
import persistConsentCookie from "modules/layout/utils/persist-consent-cookie";

/**
 * Accept all cookies
 *
 */
export default () => {
  const cookies: CookieMap = {
    marketing: true
  };

  const payload = {
    cookies,
    acceptedAt: now()
  };

  persistConsentCookie(payload);

  return {
    type: ALL_COOKIES_ACCEPTED,
    payload
  };
};
