import { useState, Fragment } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import LocalizedStrings from "react-localization";

import "./index.scss";
import strings from "./strings";
import GuestBox from "../GuestBox";
import DatePicker from "../DatePicker";
import { CalendarIcon, UserIcon, ArrowNextIcon } from "./icons";
import ExtraServices from "./extraServices";

const lang = new LocalizedStrings(strings);

const PricingBox = ({
  locale,
  initialState,
  rules,
  extraServices,
  availabilityCalendar,
  isAvailabilityCalendarLoading,
  isReadonly,
  isAutoApply,
  isApplyOnClickAway,
  onChange,
  onError,
  onMonthChange,
  header,
  footer,
  livePrice,
  children,
  forceWeekend,
  currency = "CAD"
}) => {
  /**
   * @states
   */
  const [state, setState] = useState({
    checkin: initialState.checkin || null,
    checkout: initialState.checkout || null,
    adults: parseInt(initialState.adults) || 0,
    children: parseInt(initialState.children) || 0,
    infants: parseInt(initialState.infants) || 0,
    pets: JSON.parse(initialState.pets) || false,
    extraServices: initialState.extraServices || []
  });

  /**
   * On dropdown apply button click
   * @param {Object} payload
   */
  const onGuestBoxApply = (payload) => {
    const newState = {
      ...state,
      ...payload
    };
    setState(newState);
    onChange(newState);
  };

  /**
   * On dropdown cancel button click
   * @param {Object} payload
   */
  const onGuestBoxCancel = (payload) => {
    const newState = {
      ...state,
      adults: 0,
      children: 0,
      infants: 0,
      pets: false
    };
    setState(newState);
    onChange(newState);
  };

  /**
   * On guestbox cancel button click
   * @param {Object} error
   */
  const onGuestBoxError = (error) => {
    onError(error);
  };

  /**
   * On date picker apply event.
   * @param {Object} date
   */
  const onDatepickerApply = (date) => {
    const newState = {
      ...state,
      checkin: date.start,
      checkout: date.end
    };
    setState(newState);
    onChange(newState);
  };

  /**
   * On date picker cancel event.
   * @param {Object} date
   */
  const onDatepickerCancel = (date) => {
    const newState = {
      ...state,
      checkin: null,
      checkout: null
    };
    setState(newState);
    onChange(newState);
  };

  /**
   * On date picker apply event.
   * @param {Object} error
   */
  const onDatepickerError = (error) => {
    onError(error);
  };

  /**
   * On checkbox change event handler
   * @param {Event} e event
   */
  const onCheckboxChange = (value) => {
    const newState = {
      ...state,
      extraServices: value
    };
    setState(newState);
    onChange(newState);
  };

  lang.setLanguage(locale);
  return (
    <div className="PricingBox">
      {/* PricingBox Header Section */}
      {header}
      {/* PricingBox Body Section */}
      <section className="PricingBox__Body">
        <section className="PricingBox__Params">
          <div className="PricingBox__DatePicker">
            {isReadonly ? (
              <label className="PricingBox__DatePicker--label frozen">
                <span className="icon">
                  <CalendarIcon color="#4f9589" />
                </span>
                <span>{dayjs(state.checkin).format("ddd, D MMM")} </span>
                <span className="icon">
                  <ArrowNextIcon color="#4f9589" />
                </span>
                <span>{dayjs(state.checkout).format("ddd, D MMM")}</span>
              </label>
            ) : (
              <DatePicker
                locale={locale}
                theme="primary"
                initialStartDate={
                  state.checkin ? dayjs(state.checkin).toDate() : undefined
                }
                initialEndDate={
                  state.checkout ? dayjs(state.checkout).toDate() : undefined
                }
                availabilityCalendar={availabilityCalendar}
                isLoading={isAvailabilityCalendarLoading}
                onApply={onDatepickerApply}
                onCancel={onDatepickerCancel}
                onError={onDatepickerError}
                onMonthChange={onMonthChange}
                autoApply={isAutoApply}
                livePrice={livePrice}
                forceWeekend={forceWeekend}
                isFullWidth
                disableFlip
                currency={currency}
                data={{
                  cy: "pricingBox.datePicker"
                }}
              />
            )}
          </div>
          <div className="PricingBox__GuestPicker">
            {isReadonly ? (
              <label className="PricingBox__GuestPicker--label frozen">
                <span className="icon">
                  <UserIcon color="#4f9589" />
                </span>
                <span>
                  {state.adults + state.children}{" "}
                  {state.adults + state.children + state.infants > 1
                    ? lang.guests
                    : lang.guest}
                </span>
              </label>
            ) : (
              <GuestBox
                locale={locale}
                maxGuests={rules.maxGuests}
                isChildrenAllowed={rules.isChildrenAllowed}
                isInfantsAllowed={rules.isInfantsAllowed}
                isPetsAllowed={rules.isPetsAllowed}
                defaultAdultsState={state.adults}
                defaultChildrenState={state.children}
                defaultInfantsState={state.infants}
                defaultPetsState={state.pets}
                onCancel={onGuestBoxCancel}
                onApply={onGuestBoxApply}
                onError={onGuestBoxError}
                theme="primary"
                autoApply={isAutoApply}
                applyOnClickAway={isApplyOnClickAway}
                data-cy="pricingBox.guestBox"
              />
            )}
          </div>

          {extraServices.length > 0 && (
            <Fragment>
              <div className="PricingBox__ExtraServices">
                <ExtraServices
                  extraServices={extraServices}
                  selectedIds={state.extraServices}
                  onCheckboxChange={onCheckboxChange}
                  lang={lang}
                  frozen={isReadonly}
                />
              </div>
              <hr />
            </Fragment>
          )}

        </section>
        {children}
      </section>
      {/* Pricing box footer */}
      {footer}
    </div>
  );
};

PricingBox.propTypes = {
  /** String indicating the local language that pricing box should render in */
  locale: PropTypes.oneOf(["en", "fr"]).isRequired,
  /** Object initial state of pricing box component */
  initialState: PropTypes.shape({
    checkin: PropTypes.string,
    checkout: PropTypes.string,
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
    pets: PropTypes.bool,
    extraServices: PropTypes.array
  }),
  /** Object rules passed to guest box component */
  rules: PropTypes.shape({
    isChildrenAllowed: PropTypes.bool.isRequired,
    isInfantsAllowed: PropTypes.bool.isRequired,
    isPetsAllowed: PropTypes.bool.isRequired,
    maxGuests: PropTypes.number.isRequired
  }),
  /** Array<Object> list of extra services */
  extraServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        "single",
        "per_night",
        "per_guest",
        "per_night_guest"
      ]).isRequired,
      price: PropTypes.string.isRequired
    })
  ),
  /** The listing currency */
  currency: PropTypes.string,
  /** Array of dates rules to be passed to date picker */
  availabilityCalendar: PropTypes.object,
  /** Boolean indicating whether availability calendar is being loading */
  isAvailabilityCalendarLoading: PropTypes.bool,
  /** Boolean indicating whether the pricing box should render in readonly mode */
  isReadonly: PropTypes.bool,
  /** Boolean indicating whether the pricing should auto apply */
  isAutoApply: PropTypes.bool,
  /** Function to be triggered when internal state changes */
  onChange: PropTypes.func.isRequired,
  /** Function to be triggered when datepicker/guests picker has errors */
  onError: PropTypes.func.isRequired,
  /** Function to be triggered when user clicks on datepicker next/previous month */
  onMonthChange: PropTypes.func,
  /** React.Element a header element */
  header: PropTypes.node,
  /** React.Element a footer element */
  footer: PropTypes.node,
  /** Object for live price */
  livePrice: PropTypes.object,
  /** Boolean indicating whether to force weekend */
  forceWeekend: PropTypes.bool,
  /** React.Element component passed children's */
  children: PropTypes.node
};

PricingBox.defaultProps = {
  locale: "en",
  initialState: {
    checkin: null,
    checkout: null,
    adults: 0,
    children: 0,
    infants: 0,
    pets: false,
    extraServices: []
  },
  currency: "CAD",
  rules: {
    isChildrenAllowed: true,
    isInfantsAllowed: true,
    isPetsAllowed: true,
    maxGuests: 100
  },
  extraServices: [],
  availabilityCalendar: {},
  isReadonly: false,
  isAutoApply: false,
  livePrice: {},
  forceWeekend: false,
  isAvailabilityCalendarLoading: false,
  onMonthChange: () => { }
};

export default PricingBox;
