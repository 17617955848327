export default {
  en: {
    star: "star",
    review: "reviews"
  },
  fr: {
    star: "étoiles",
    review: "critiques"
  }
};
