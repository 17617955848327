import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";

export default (
  state: ContainerState,
  action: PayloadAction<string[]>
): ContainerState => {
  const payload = action.payload;
  switch (action.type) {
    case DATE_RANGE_CHANGED:
      return {
        ...state,
        from: payload[0],
        to: payload[1],
        segment: {
          x1: payload[0],
          x2: payload[1]
        }
      };
    default:
      return state;
  }
};

export const DATE_RANGE_CHANGED = "DATE_RANGE_CHANGED";
