import { useState, useEffect } from "react";
import dayjs from "dayjs";

const format = (n: number): string =>
  n < 10 && n >= 0 ? `0${n}` : n < 0 ? "00" : `${n}`;

interface TimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

const calculateTimeLeft = (start: string | Date): TimeLeft => {
  const end = (dayjs as any)(start).utc().add(1, "day");
  const days = format(end.diff(dayjs().utc(), "day"));
  const hours = format(end.diff(dayjs().utc(), "hour") % 24);
  const minutes = format(end.diff(dayjs().utc(), "minute") % 60);
  const seconds = format(end.diff(dayjs().utc(), "second") % 60);

  return {
    days,
    hours,
    minutes,
    seconds
  };
};

/**
 * a react hook to calculate remaining time from a given date
 * @param {Date|String} start date to start counter from
 */
export function useTimer(start: string | Date) {
  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(start));
  useEffect(() => {
    const timeLeft = calculateTimeLeft(start);
    setTimeLeft(timeLeft);

    const interval = setInterval(() => {
      const timeLeft = calculateTimeLeft(start);
      setTimeLeft(timeLeft);
      if (
        timeLeft.days === "00" &&
        timeLeft.hours === "00" &&
        timeLeft.minutes === "00" &&
        timeLeft.seconds === "00"
      ) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [start]);
  return timeLeft;
}
