import {
  SET_MODAL_TITLE,
  SET_MODAL_VISIBILITY,
  TOGGLE_MODAL_VISIBILITY,
  ModalsState,
  CREATE_MODAL,
  DELETE_MODAL,
  SET_MODAL_DATA,
  UNSET_MODAL_DATA,
  ModalActionTypes,
  ModalState
} from "./types.dt";

const defaultModalState = {
  visible: false,
  title: ""
};

const modalReducer = (
  state: ModalState | undefined = defaultModalState,
  action: ModalActionTypes
) => {
  const { type, payload } = action || {};
  switch (type) {
    case TOGGLE_MODAL_VISIBILITY:
      return {
        ...state,
        visible: !state.visible
      };
    case SET_MODAL_VISIBILITY:
      return {
        ...state,
        visible: payload.visible
      };
    case SET_MODAL_TITLE:
      return { ...state, title: payload.title };
    case SET_MODAL_DATA:
      return { ...state, data: payload.data };
    case UNSET_MODAL_DATA:
      return { ...state, data: undefined };
    default:
      return state;
  }
};

const modalsReducer = (
  state: ModalsState | undefined = {},
  action: ModalActionTypes | undefined
) => {
  if (action?.type === undefined) {
    return state;
  }

  const { type, payload } = action;

  // creates a modal
  if (type === CREATE_MODAL) {
    return {
      ...state,
      modals: {
        ...state.modals,
        [payload?.id]: defaultModalState
      }
    };
  }

  // deletes a modal
  if (type === DELETE_MODAL) {
    return { ...state, [payload?.id]: undefined };
  }

  if (
    type === TOGGLE_MODAL_VISIBILITY ||
    type === SET_MODAL_VISIBILITY ||
    type === SET_MODAL_TITLE ||
    type === SET_MODAL_DATA ||
    type === UNSET_MODAL_DATA
  ) {
    const modalState = state[payload?.id];
    // get modal and pass it to modalReducer
    return {
      ...state,
      modals: {
        ...state.modals,
        [payload?.id]: modalReducer(modalState as ModalState, action)
      }
    };
  }

  return state;
};

export default modalsReducer;
