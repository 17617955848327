/**
 * Using one of the following flags, we can make draw any shape
 * in the calendar
 *
 * We can add any combination here and use the proper component
 * to draw in in calendar
 *
 */
export const DAY_TILE = {
  END: "END",
  MIDDLE: "MIDDLE",
  CROSS: "CROSS",
  START: "START",
  NO_TILE: "NO_TILE"
};
