import { FC, Fragment, useMemo } from "react";
import { lowerCase } from "lodash";
import classes from "./provider-logo.module.scss";
import logosByExtension from "./provider-logo-assets";

interface Props {
  provider: string;
}

export const ProviderLogo: FC<Props> = (props) => {
  const { provider: rawProvider } = props;

  const provider = lowerCase(rawProvider);

  const filePath = useMemo(() => {
    for (const [extension, logos] of Object.entries(logosByExtension)) {
      if (logos.includes(provider)) {
        return `/images/providers/${provider}.${extension}`;
      }
    }
    return null;
  }, [provider]);

  if (provider === "wechalet") {
    return (
      <img
        alt="wechalet-logo"
        className={classes.reservation__wechalet}
        src="/images/brand/logo_wechalet_v1.jpg"
      />
    );
  }

  return (
    <Fragment>
      {filePath?.length ? (
        <img
          alt={`${provider}-logo`}
          className={classes.reservationLogo}
          src={filePath}
        />
      ) : (
        <div className={classes.reservationAvatar}>{provider?.charAt(0)}</div>
      )}
    </Fragment>
  );
};

export default ProviderLogo;
