import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";
import { Integration } from "../../types/integration-type";
import { produce } from "immer";
import { IntegrationStatus } from "modules/integrations/types/status";

export default (
  state: ContainerState,
  action: PayloadAction<{ provider: Integration; status: IntegrationStatus }>
): ContainerState => {
  const { type, payload } = action || {};
  switch (type) {
    case INTEGRATION_STATUS_CHANGED:
      return produce(state, (draft) => {
        draft.status[payload.provider] = payload.status;
      });
    default:
      return state;
  }
};

export const INTEGRATION_STATUS_CHANGED = "INTEGRATION_STATUS_CHANGED";
