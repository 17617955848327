import { FC } from "react";
import { getLocale } from "modules/settings/selectors";
import { useSelector } from "react-redux";
import classes from "./grid-label.module.scss";
import messages from "./messages";
import useLang from "@design-library/utils/useLang";

interface Props {}

export const GridLabel: FC<Props> = () => {
  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  return <div className={classes.container}>{lang.properties}</div>;
};

export default GridLabel;
