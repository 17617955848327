import { FC, Fragment, useMemo, useState } from "react";
import { getLocale } from "modules/settings/selectors";
import { Info } from "react-feather";
import { useSelector } from "react-redux";
import Button from "@design-library/Button";
import ClickAwayListener from "react-click-away-listener";
import clsx from "clsx";
import messages from "./messages";
import styles from "./legend.module.scss";
import useBreakpoints from "utils/useBreakpoints";
import useLang from "@design-library/utils/useLang";

interface Props {}

export const Legend: FC<Props> = (props) => {
  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  const [showLegendPopup, setShowLegendPopup] = useState(false);

  const bars = useMemo(() => {
    return [...Array.from({ length: 6 })];
  }, []);

  const handleTogglePopup = () => {
    setShowLegendPopup(!showLegendPopup);
  };

  const breakpoint = useBreakpoints();

  return (
    <Fragment>
      <ClickAwayListener
        className={styles.legendPopoverContainer}
        onClickAway={() => setShowLegendPopup(false)}
      >
        <Button
          icon={() => <Info size={21} />}
          size="small"
          theme="link"
          className={styles.infoButton}
          onClick={handleTogglePopup}
          onMouseEnter={handleTogglePopup}
        >
          {lang.legend}
        </Button>
      </ClickAwayListener>

      <div className={styles.relative}>
        <div
          className={clsx(styles.menu, {
            [styles.rightMost]: breakpoint.xs,
            [styles.leftMost]: breakpoint.md,
            [styles.active]: showLegendPopup
          })}
        >
          <div>
            <div className={styles.calendarKey}>
              <div className={styles.calendarKeyRow}>
                <div
                  className={clsx([styles.explanation, styles.reserved])}
                ></div>
                <p className={styles.calendarKeyText}>{lang.booked}</p>
              </div>
              <div className={styles.calendarKeyRow}>
                <div
                  className={clsx([styles.explanation, styles.overlapped])}
                ></div>
                <p className={styles.calendarKeyText}>{lang.overlapped}</p>
              </div>
              <div
                className={clsx([
                  styles.calendarKeyRow,
                  styles.calendarKeyLastRow
                ])}
              >
                <div className={clsx([styles.explanation, styles.blocked])}>
                  {bars.map((_k, index) => (
                    <div
                      key={`blocked-item--${index}`}
                      className={styles.blockedItem}
                    ></div>
                  ))}
                </div>
                <p className={styles.calendarKeyText}>{lang.blocked}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Legend;
