import { forwardRef, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ValidationMessage from "../ValidationMessage";
import style from "./TextField.module.scss";
import CloseIcon from "@design-library/CloseIcon/CloseIcon";

/**
 * Text field component
 */
const TextField = forwardRef((
  {
    type,
    id: idProp,
    label,
    placeholder,
    value,
    theme,
    invalid,
    hint,
    icon,
    afterInput,
    noCopy,
    noPaste,
    disabled,
    containerAttrs = {},
    onChange,
    onClear,
    inputClass,
    ...attrs
  },
  ref
) => {
  const innerRef = useRef(null);

  const id = useMemo(
    () => idProp || `TextField_${Math.random().toString(36).substring(7)}`,
    [idProp]
  );

  const setRef = (el) => {
    if (ref) ref.current = el;
    innerRef.current = el;
  };

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const handleCopy = (event) => {
    if (noCopy) event.preventDefault();
  };

  const handlePaste = (event) => {
    if (noPaste) event.preventDefault();
  };

  const handleClear = () => {
    onChange("");
    onClear();

    // Focus input
    if (document.activeElement !== innerRef.current) {
      innerRef.current.focus();
    }
  };

  const handleClearMouseDown = (event) => {
    event.preventDefault(); // Prevents focus on button
  };

  return (
    <div
      className={clsx(style.wrapper, style[theme], {
        "is-invalid": invalid,
        [style.disabled]: disabled
      })}
    >
      {!!label && (
        <label className={style.label} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={style.inputWrapper}>
        {/* Container */}
        <div className={style.box} {...containerAttrs}>
          {typeof icon === "function" && (
            <span className={style.icon}>{icon()}</span>
          )}
          {type === "search" && !!value && (
            <button
              type="button"
              className={style.clear}
              tabIndex="-1"
              onClick={handleClear}
              onMouseDown={handleClearMouseDown}
            >
              <CloseIcon />
            </button>
          )}
          <input
            ref={setRef}
            id={id}
            className={clsx(style.input, inputClass)}
            type={type}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            aria-describedby={`${id}_description`}
            onChange={handleChange}
            onCopy={handleCopy}
            onPaste={handlePaste}
            {...attrs}
          />
        </div>
        {afterInput()}
      </div>
      <div id={`${id}_description`} className={style.description}>
        {typeof invalid === "string" ? (
          <ValidationMessage>{invalid}</ValidationMessage>
        ) : (
          hint && <p className={style.hint}>{hint}</p>
        )}
      </div>
    </div>
  );
});

TextField.propTypes = {
  /** Input type */
  type: PropTypes.oneOf([
    "text",
    "number",
    "email",
    "password",
    "search"
  ]),
  /** Label text */
  label: PropTypes.string,
  /** Placeholder text */
  placeholder: PropTypes.string,
  /** Field value */
  value: PropTypes.string,
  /** Theme */
  theme: PropTypes.oneOf([
    "default",
    "compact",
    "banner",
    "fixedBanner",
    "filled",
    "rounded"
  ]),
  /**
   * Validation state.
   * `true` displays field invalid.
   * A `string` displays field invalid with a message.
   */
  invalid: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  /** Hint text under input */
  hint: PropTypes.string,
  /** Add an icon with a render function */
  icon: PropTypes.func,
  /** Container attributes */
  containerAttrs: PropTypes.object,
  /** Add elements after the input with a render function */
  afterInput: PropTypes.func,
  /** Prevent copy */
  noCopy: PropTypes.bool,
  /** Prevent paste */
  noPaste: PropTypes.bool,
  /** Change event */
  onChange: PropTypes.func,
  /** Event called when search input is cleared */
  onClear: PropTypes.func
};

const noop = () => {};

TextField.defaultProps = {
  type: "text",
  label: "",
  placeholder: "",
  value: "",
  theme: "default",
  invalid: null,
  hint: "",
  afterInput: noop,
  noCopy: false,
  noPaste: false,
  onChange: noop,
  onClear: noop
};

export default TextField;
