import { now } from "lodash";
import { CookieMap } from "../types";
import { CUSTOM_COOKIES_ACCEPTED } from "../reducers/custom-cookies-accepted";
import persistConsentCookie from "modules/layout/utils/persist-consent-cookie";

/**
 * Accepts only necessary cookies
 *
 */
export default (cookies: CookieMap) => {
  const payload = {
    cookies,
    acceptedAt: now()
  };

  persistConsentCookie(payload);

  return {
    type: CUSTOM_COOKIES_ACCEPTED,
    payload
  };
};
