import { createSelector } from "reselect";
import { selectDomain } from ".";
import { ObjectId } from "modules/shared/types/object-id";

/**
 * Returns the period of a given date
 *
 */
export default (listingId: ObjectId, date?: string) =>
  createSelector([selectDomain], (state) => {
    if (!date || !listingId) {
      return;
    }

    const listingPeriods = state.periods[listingId] ?? {};

    const record = listingPeriods[date];

    return record;
  });
