import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek";
import isBetween from "dayjs/plugin/isBetween";
import localeData from "dayjs/plugin/localeData";
import updateLocale from "dayjs/plugin/updateLocale";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import "dayjs/locale/en";
import "dayjs/locale/fr";

dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.extend(isSameOrAfter);

const defaultLanguage =
  localStorage.getItem("locale") || window.navigator.language.slice(0, 2);
dayjs.locale(defaultLanguage);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "Few secs",
    m: "1 min",
    mm: "%d min",
    h: "1 h",
    hh: "%d h",
    d: "1 d",
    dd: "%d d",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years"
  }
});

dayjs.updateLocale("fr", {
  relativeTime: {
    future: "Dans %s",
    past: "Il y a %s",
    s: "quelques secs",
    m: "1 min",
    mm: "%d min",
    h: "1 h",
    hh: "%d h",
    d: "1 j",
    dd: "%d j",
    M: "1 mois",
    MM: "%d mois",
    y: "1 an",
    yy: "%d ans"
  }
});
