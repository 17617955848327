import { SET_LOCALE, SettingsState, SET_COUNTRY_CODE, LocaleAction, LanguageAction, LanguagesEnum } from "./types";

const defaultLanguage : LanguagesEnum =
LanguagesEnum[localStorage.getItem("locale") as LanguagesEnum] || LanguagesEnum[window.navigator.language.slice(0, 2)];

const cachedCountryCodeOrNull = localStorage.getItem("countryCode")
  ? localStorage.getItem("countryCode") as string
  : null;

const initialState : SettingsState = {
  locale: defaultLanguage,
  countryCode: cachedCountryCodeOrNull
};
export const settings = (state : SettingsState = initialState, action: LocaleAction | LanguageAction) => {
  switch (action?.type) {
    case SET_LOCALE:
      return { ...state, locale: action?.payload?.locale };
    case SET_COUNTRY_CODE:
      return { ...state, countryCode: action?.payload?.countryCode };
    default:
      return state;
  }
};

export default settings;
