import { FC, memo } from "react";
import { FlagComponentProps } from "./types";
import { PriceTag } from "modules/listing-calendar";
import classes from "./flag-components.module.scss";

export const FreeDay: FC<FlagComponentProps> = (props) => {
  const { calendar } = props;
  return (
    <div className={classes.freeDayContainer}>
      <PriceTag calendar={calendar} />

      {!!calendar?.note?.length && (
        <div className={classes.noteContainer}>
          <img
            className={classes.noteImageIcon}
            alt={"note"}
            src="/assets/note-icon.png"
          />
        </div>
      )}
    </div>
  );
};

export default memo(FreeDay);
