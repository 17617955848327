export default {
  en: {
    search_placeholder: "Search",
    properties: "Properties",
    datePickerError: "Unable to select date range",
    booked: "Booked",
    overlapped: "Overlapped",
    blocked: "Blocked",
    minimumStay: "Min Stay",
    prices: "Prices",
    draft: "Draft",
    legend: "Legend",
    paused: "Paused",
    published: "Published",
    status: "Status",
    notification: "Notification",
    errorMessages: {
      ERROR_SELECT_PERIOD: "Please select a period",
      ERROR_SELECT_WEEKLY_PRICE:
        "Weekly price must be at least $10 and at max $999999.",
      ERROR_SELECT_WEEKEND_PRICE:
        "Weekend price must be at least $10 and at max $999999.",
      ERROR_SELECT_AVAILABILITY: "You must select availability for your period",
      ERROR_SELECT_PROPERTIES: "You must select at least one property"
    }
  },
  fr: {
    legend: "Légende",
    search_placeholder: "Recherche",
    properties: "Propriétés",
    datePickerError: "Impossible de sélectionner la plage de dates",
    booked: "Réservé",
    overlapped: "Superposé",
    blocked: "Bloqué",
    minimumStay: "Durée",
    prices: "Prix",
    draft: "Ébauche",
    paused: "Pause",
    published: "Publié",
    status: "Statut",
    notification: "Notification",
    errorMessages: {
      ERROR_SELECT_PERIOD: "Veuillez sélectionner une période",
      ERROR_SELECT_WEEKLY_PRICE:
        "Weekly price doit être d'au moins $10 et au maximum $999999.",
      ERROR_SELECT_WEEKEND_PRICE:
        "Weekend price doit être d'au moins $10 et au maximum $999999.",
      ERROR_SELECT_AVAILABILITY:
        "Vous devez sélectionner la disponibilité pour votre période",
      ERROR_SELECT_PROPERTIES: "Vous devez sélectionner au moins une propriété"
    }
  }
};
