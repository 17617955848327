import { useMemo } from "react";
import PropTypes from "prop-types";
import useLang from "../utils/useLang";
import { Star } from "react-feather";
import style from "./Rating.module.scss";
import strings from "./strings";

const Rating = ({ rating: ratingProp, count, locale, hideCount }) => {
  const rating = useMemo(() => {
    if (ratingProp == null) {
      return 0;
    }

    const number = typeof ratingProp === "string" ? parseFloat(ratingProp) : ratingProp;

    if (isNaN(number)) {
      return 0;
    }

    return number;
  }, [ratingProp]);
  const kFormatter = num => num > 999 ? `${(num / 1000).toFixed(1)}k` : `${num}`;
  const lang = useLang(strings, locale);
  return (
    <div className={style.wrapper}
    >
      <Star role="img" aria-label={`${rating} ${lang.star}`} className={style.star} />
      <p className={style.rating}>
        {(rating).toFixed(1)}
      </p>
      {!hideCount &&
        <p
          className={style.count}
          title={`${kFormatter(count)} ${lang.review}`}
        >
          ({kFormatter(count)})
        </p>
      }
    </div>
  );
};

Rating.propTypes = {
  /** Number of votes */
  count: PropTypes.number,
  /** Average rating */
  rating: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /** Hide count number */
  hideCount: PropTypes.bool,
  /** Message locale */
  locale: PropTypes.string
};

Rating.defaultProps = {
  rating: 0,
  count: 0,
  hideCount: false,
  locale: "en"
};

export default Rating;
