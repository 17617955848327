import { Fragment, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import getServiceFeePercentageValue from "utils/getServiceFeePercentageValue";

import useLang from "../utils/useLang";
import ChevronIcon from "../ChevronIcon";
import ExtraServiceTooltip from "./tooltip";

import strings from "./strings";
import "./index.css";
import styles from "./Invoice.module.scss";

/**
 * Render a booking invoice with all invoice details
 */
const Invoice = ({ locale, invoiceType, invoice, priceFormatter }) => {
  const lang = useLang(strings, locale);
  const [taxesAreVisible, setTaxesAreVisible] = useState(false);
  const [isServiceFeesVisible, setIsServiceFeesVisible] = useState(false);
  const [couponType, setCouponType] = useState(undefined);
  const shouldShowGuestTaxes = invoiceType === "travelling";

  const shouldShowHostTaxes =
    invoiceType === "hosting" &&
    invoice.nightsCount <= 31 &&
    (invoice?.taxes[0]?.property_tax?.tax_code !== undefined ||
      invoice?.taxes[2]?.property_tax?.tax_code !== undefined);

  var gst =
    invoiceType === "travelling"
      ? invoice.canadianFederalTax
      : invoice?.hostTaxes?.canadian_federal_tax || 0;

  var qst =
    invoiceType === "travelling"
      ? invoice.quebecProvincialTax
      : invoice?.hostTaxes?.quebec_provincial_tax || 0;

  var hst =
    invoiceType === "travelling"
      ? invoice.harmonizedSalesTax
      : invoice?.hostTaxes?.harmonized_sales_tax || 0;

  const accommodationTax = invoice.accommodationTax;

  const { taxes, taxesTotal } = useMemo(() => {
    const taxes = [
      {
        name: lang.accommodationTax,
        price: accommodationTax
      },
      {
        name: "GST",
        price: gst
      },
      {
        name: "QST",
        price: qst
      },
      {
        name: "HST",
        price: hst
      }
    ].filter((tax) => Number(tax.price));

    console.log(taxes);
    return {
      taxes,
      taxesTotal: taxes
        .reduce((previousTotal, tax) => previousTotal + Number(tax.price), 0)
        .toFixed(2)
    };
  }, [gst, qst, hst, accommodationTax, lang.accommodationTax]);

  const serviceFeeGst =
    invoiceType === "hosting"
      ? invoice.serviceFeeCanadianFederalTax
      : undefined;

  const serviceFeeQst =
    invoiceType === "hosting"
      ? invoice.serviceFeeQuebecProvincialTax
      : undefined;

  const serviceFeeHst =
    invoiceType === "hosting"
      ? invoice.serviceFeeHarmonizedSalesTax
      : undefined;

  const { serviceFeeTaxes, serviceFeeTaxesTotal } = useMemo(() => {
    const serviceFeeTaxes = [
      {
        name: lang.hostWechaletFee,
        price: Number(-invoice.wechaletFee)
      },
      {
        name: `${lang.taxNames.GST} (${String(
          lang.wechaletFee
        ).toLocaleLowerCase()})`,
        price: Number(-serviceFeeGst)
      },
      {
        name: `${lang.taxNames.QST} (${String(
          lang.wechaletFee
        ).toLocaleLowerCase()})`,
        price: Number(-serviceFeeQst)
      },
      {
        name: `${lang.taxNames.HST} (${String(
          lang.wechaletFee
        ).toLocaleLowerCase()})`,
        price: Number(-serviceFeeHst)
      }
    ].filter((serviceFee) => serviceFee.price);
    return {
      serviceFeeTaxes,
      serviceFeeTaxesTotal: serviceFeeTaxes
        .reduce((previousTotal, tax) => previousTotal + Number(tax.price), 0)
        .toFixed(2)
    };
  }, [
    serviceFeeGst,
    serviceFeeQst,
    serviceFeeHst,
    lang.taxNames.QST,
    lang.wechaletFee,
    lang.taxNames.GST,
    lang.taxNames.HST,
    invoice.wechaletFee,
    lang.hostWechaletFee
  ]);

  useEffect(() => {
    if (invoice?.couponDiscount > 0) {
      if (invoice.hasGiftCardCoupon) {
        setCouponType("gift");
      } else {
        setCouponType("rebate");
      }
    }
  }, [invoice]);

  return (
    <section className="WechaletInvoice">
      <div className={styles.row}>
        <span>
          {priceFormatter(invoice.nightCost)} x {invoice.nightsCount}{" "}
          {invoice.nightsCount > 1 ? lang.nights : lang.night}
        </span>
        <span>{priceFormatter(invoice.nightsCost)}</span>
      </div>

      {invoice.discount > 0 && (
        <div className={styles.row}>
          <span className="text-highlighted">
            {parseInt(invoice.nightsCount) > 28
              ? `${invoice.discountPercentage} ${lang.monthlyDiscount}`
              : `${invoice.discountPercentage} ${lang.weeklyDiscount}`}
          </span>
          <span className="text-highlighted">
            -{priceFormatter(invoice.discount)}
          </span>
        </div>
      )}

      {invoice.cleaningFee > 0 && (
        <div className={styles.row}>
          <div>
            <span>{lang.cleaningFee}</span>
            <a
              href={lang.cleaningFeeLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <QuestionIcon />
            </a>
          </div>
          <span>{priceFormatter(invoice.cleaningFee)}</span>
        </div>
      )}

      {invoice.extraServices.map((service) => {
        let label;
        switch (service.type) {
          case "per_night":
            label = `${service.nights_count} x ${priceFormatter(
              service.price
            )} (${lang.per_night})`;
            break;
          case "per_guest":
            label = `${service.guests_count} x ${priceFormatter(
              service.price
            )} (${lang.per_guest})`;
            break;
          case "per_night_guest":
            label = `${service.guests_count} x ${service.nights_count
              } x ${priceFormatter(service.price)} (${lang.per_guest_per_night})`;
            break;
          case "single":
            label = `1 x ${priceFormatter(service.price)} (${lang.single_fee})`;
            break;
          default:
            label = "";
        }

        if (service.price === 0) {
          return null;
        }

        return (
          <div key={service.name} className={styles.row}>
            <span>
              {service.name}
              <ExtraServiceTooltip label={label} />
            </span>
            <span>{priceFormatter(service.total)}</span>
          </div>
        );
      })}

      {invoiceType === "travelling" && invoice.wechaletFee !== 0 && (
        <div className={styles.row}>
          <div>
            <span>{lang.wechaletFee}</span>
            <a
              href={
                invoiceType === "travelling"
                  ? lang.serviceFeeGuestLink
                  : lang.serviceFeeHostLink
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <QuestionIcon />
            </a>
          </div>
          <span>{priceFormatter(invoice.wechaletFee)}</span>
        </div>
      )}

      {invoiceType === "hosting" && serviceFeeTaxes?.length > 0 && (
        <>
          <div
            role="button"
            className={styles.row}
            style={{ cursor: "pointer" }}
            onClick={() => setIsServiceFeesVisible(!isServiceFeesVisible)}
          >
            <div>
              <span>{lang.hostWechaletFee} (3%)</span>
              <ChevronIcon
                style={{
                  marginLeft: "5px",
                  transform: isServiceFeesVisible ? "rotate(180deg)" : ""
                }}
              />
            </div>
            <span>-{priceFormatter(Math.abs(serviceFeeTaxesTotal))}</span>
          </div>

          {isServiceFeesVisible &&
            serviceFeeTaxes.map((tax) =>
              tax.name === lang.hostWechaletFee ? (
                <div className={styles.row}>
                  <div>
                    {lang.hostWechaletFee} (
                    {getServiceFeePercentageValue(
                      invoice.wechaletFee,
                      invoice.nightsCost
                    )}
                    %)
                    <a
                      href={
                        invoiceType === "travelling"
                          ? lang.serviceFeeGuestLink
                          : lang.serviceFeeHostLink
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <QuestionIcon />
                    </a>
                  </div>
                  <span>
                    -<span>{priceFormatter(invoice.wechaletFee)}</span>
                  </span>
                </div>
              ) : (
                <div className={styles.row}>
                  <div>
                    <span>{lang.taxNames[tax.name] || tax.name}</span>
                  </div>
                  <span>-{priceFormatter(Math.abs(tax.price))}</span>
                </div>
              )
            )}
        </>
      )}

      {invoice.couponDiscount > 0 && couponType === "rebate" && (
        <div className={styles.row}>
          <span className="text-highlighted">{lang.rebateDiscount}</span>
          <span className="text-highlighted">
            -{priceFormatter(invoice.couponDiscount)}
          </span>
        </div>
      )}

      {invoiceType === "travelling" && invoice.waiverDeposit !== 0 && (
        <Fragment>
          {invoice.waiverDeposit > 0 && (
            <div className={clsx(["DepositMobileRow", styles.row])}>
              <div>
                <span>{lang.damageProtection}</span>
                <a
                  href={lang.waiverDepositLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <QuestionIcon />
                </a>
              </div>
              <span>{priceFormatter(invoice.waiverDeposit)}</span>
            </div>
          )}
        </Fragment>
      )}

      {taxes.length > 0 && (shouldShowHostTaxes || shouldShowGuestTaxes) && (
        <>
          <div
            role="button"
            className={styles.row}
            style={{ cursor: "pointer" }}
            onClick={() => setTaxesAreVisible(!taxesAreVisible)}
          >
            <div>
              <span>Taxes</span>
              <ChevronIcon
                style={{
                  marginLeft: "5px",
                  transform: taxesAreVisible ? "rotate(180deg)" : ""
                }}
              />
            </div>
            <span>{priceFormatter(taxesTotal)}</span>
          </div>

          {taxesAreVisible &&
            taxes.map((tax) => (
              <div className={styles.row}>
                <div>
                  <span>{lang.taxNames[tax.name] || tax.name}</span>
                </div>
                <span>{priceFormatter(tax.price)}</span>
              </div>
            ))}
        </>
      )}

      {invoice.couponDiscount > 0 && couponType === "gift" && (
        <div className={styles.row}>
          <span className="text-highlighted">{lang.giftDiscount}</span>
          <span className="text-highlighted">
            -{priceFormatter(invoice.couponDiscount)}
          </span>
        </div>
      )}
      <hr className="separator" />

      <div className={clsx(["PriceMobileRow", styles.row, styles.noMarginTop])}>
        <span className="text-bold">
          {invoiceType === "travelling" ? lang.total : lang.hostPayout}
        </span>
        <span className="text-bold">{priceFormatter(invoice.total)}</span>
      </div>

      {invoiceType === "travelling" && invoice.securityDeposit !== 0 && (
        <Fragment>
          {invoice.securityDeposit > 0 && (
            <>
              <div className={clsx(["DepositMobileRow", styles.row])}>
                <div>
                  <span>{lang.refundableDeposit}</span>
                  <a
                    href={lang.securityDepositLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <QuestionIcon />
                  </a>
                </div>
                <span>{priceFormatter(invoice.securityDeposit)}</span>
              </div>
              <div className={clsx(["DepositMobileRow", "text-bold", styles.row])}>
                <div>
                  <span>{lang.totalWithDeposit}</span>
                </div>
                <span>{priceFormatter(invoice.totalWithDeposit)}</span>
              </div>
            </>
          )}
        </Fragment>
      )}
    </section>
  );
};

const emptyInvoice = {
  nightCost: 0,
  nightsCost: 0,
  nightsCount: 1,
  discount: 0,
  discountPercentage: "0%",
  cleaningFee: 0,
  wechaletFee: 0,
  accommodationTax: 0,
  total: 0,
  securityDeposit: 0,
  totalWithDeposit: 0,
  extraServices: []
};

Invoice.defaultProps = {
  locale: "en",
  invoiceType: "travelling",
  invoice: emptyInvoice,
  priceFormatter: (value) => `$${value} CAD`
};

Invoice.propTypes = {
  /**
   * String in "en|fr" as a locale for this component.
   */
  locale: PropTypes.oneOf(["en", "fr"]),
  /**
   * String in "travelling|hosting" as a locale for this component.
   */
  invoiceType: PropTypes.oneOf(["travelling", "hosting"]),
  /**
   * Object invoice of a booking.
   */
  invoice: PropTypes.shape({
    /**
     * Number cost of one night of a booking.
     */
    nightCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    /**
     * Number total cost of all nights of a booking.
     */
    nightsCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    /**
     * Number count nights.
     */
    nightsCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    /**
     * Number discount value.
     */
    discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * String discount percentage.
     */
    discountPercentage: PropTypes.string,
    /**
     * Number cleaning fee value.
     */
    cleaningFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Number wechalet service fee value.
     */
    wechaletFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    /**
     * Number total amount of this invoice without security deposit.
     */
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    securityDeposit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    waiverDeposit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalWithDeposit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    extraServices: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired
      })
    )
  }).isRequired,
  /**
   * Function a function to format prices.
   */
  priceFormatter: PropTypes.func.isRequired
};

export default Invoice;

const QuestionIcon = ({ size = 15, color = "#4f9589" }) => (
  <svg
    className="WechaletInvoice_Svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="10" />
    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
    <line x1="12" y1="17" x2="12.01" y2="17" />
  </svg>
);
