import useBreakpoints from "utils/useBreakpoints";

import DropdownMenu from "../DropdownMenu";
import Checkbox from "../Checkbox";
import ServiceIcon from "../ServiceIcon";

import { CheckIcon } from "./icons";

const ExtraServices = ({
  extraServices,
  selectedIds,
  frozen,
  onCheckboxChange,
  lang
}) => {
  const breakpoints = useBreakpoints();
  const toggleLabel = !selectedIds.length
    ? lang.extraServices
    : selectedIds.length > 1
      ? `${selectedIds.length} ${lang.extraServices}`
      : `${selectedIds.length} ${lang.extraService}`;

  return frozen ? (
    extraServices.map(extraService => (
      selectedIds?.includes(extraService.id) && (
        <div
          key={extraService.id}
          className="PricingRow"
          title={`${extraService.name} : ${lang[extraService.type]} - ${
            extraService.price
          }`}
        >
          <label className="PricingBox__ExtraService--label frozen">
            <span className="icon">
              <CheckIcon size={16} color="#4f9589" />
            </span>
            <span>{extraService.name}</span>
          </label>
          <span>
            {lang[extraService.type]} - {extraService.price}
          </span>
        </div>
      )
    ))
  ) : (
    <DropdownMenu
      label={toggleLabel}
      icon={() => <ServiceIcon />}
      staticMenu={!breakpoints.xl}
    >
      {extraServices.map(extraService => (
        <Checkbox
          key={extraService.id}
          name={extraService.id}
          value={selectedIds}
          onChange={onCheckboxChange}
        >
          <span style={{ fontWeight: "500" }}>
            {extraService.name}
          </span>
          <span style={{ display: "block" }}>
            {lang[extraService.type]} - {extraService.price}
          </span>
        </Checkbox>
      ))}
    </DropdownMenu>
  );
};

export default ExtraServices;
