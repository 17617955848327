export default {
  en: {
    draft: "Draft",
    paused: "Paused",
    published: "Published",
    status: "Status",
    clear: "Clear"
  },
  fr: {
    draft: "Ébauche",
    paused: "Pause",
    published: "Publié",
    status: "Statut"
  }
};
