export default {
  en: {
    off: "No",
    on: "Yes"
  },
  fr: {
    off: "Non",
    on: "Oui"
  }
};
