import { createSelector } from "reselect";
import { selectDomain } from ".";
import { ObjectId } from "modules/shared/types/object-id";

/**
 * Returns all periods of a given listing
 *
 */
export default (listingId?: ObjectId) =>
  createSelector([selectDomain], (state) => {
    if (!listingId) {
      return [];
    }

    const listingPeriods = state.periods[listingId] ?? {};
    const periods = Object.values(listingPeriods);

    return periods;
  });
