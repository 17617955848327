import PropTypes from "prop-types";

const ChevronIcon = ({ size = 20, color = "#4f5253" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="3"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 9l6 6 6-6" />
  </svg>
);

export const UserIcon = ({ size = 20, color = "#4f5253" }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    x="0px"
    y="0px"
    width={size}
    height={size}
    fill={color}
  >
    <g>
      <g>
        <path
          d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148 C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z"
        />
      </g>
    </g>
  </svg>
);

var Toggle = ({ label, classnames, onClick, isVisible }) => {
  return (
    <button className={classnames} type="button" onClick={onClick}>
      <span className="Icon UserIcon">
        <UserIcon color="#4f9589" />
      </span>
      <span className="Text">{label}</span>
      <span className={`ChevronIcon ${isVisible ? "rotate" : ""}`}>
        <ChevronIcon />
      </span>
    </button>
  );
};

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
  classnames: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired
};

export default Toggle;
