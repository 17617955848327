import { DISPLAY_TYPE_CHANGED } from "../reducers/display-type-changed";
import { ContainerState } from "../types";

/**
 * Changes the display type
 *
 */
export default (type: ContainerState["display"]) => {
  return {
    type: DISPLAY_TYPE_CHANGED,
    payload: type
  };
};
