import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";

export default (
  state: ContainerState,
  action: PayloadAction<string>
): ContainerState => {
  const payload = action.payload;
  switch (action.type) {
    case SEARCH_KEYWORD_CHANGED:
      return {
        ...state,
        keyword: payload
      };
    default:
      return state;
  }
};

export const SEARCH_KEYWORD_CHANGED = "SEARCH_KEYWORD_CHANGED";
