import TriangleTooltip from "@design-library/TriangleTooltip";

const ExtraServiceTooltip = ({ label }) => {
  return (
    <span className="WechaletInvoice_Tooltip">
      <TriangleTooltip label={label}>
        <span>
          <svg
            width={15}
            height={15}
            viewBox="0 0 24 24"
            fill="none"
            stroke="#4f9589"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
            <line x1="12" y1="17" x2="12.01" y2="17" />
          </svg>
        </span>
      </TriangleTooltip>
    </span>
  );
};

export default ExtraServiceTooltip;
