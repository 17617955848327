import { useRef, useState, useEffect } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import ClickAwayListener from "react-click-away-listener";
import DayPicker from "react-day-picker/DayPicker";

import lang from "./lang";
import Toggle from "./Toggle";
import Navbar from "./Navbar";

import "./index.scss";

const RangePicker = ({
  initialStartDate,
  initialEndDate,
  displayFormat,
  locale,
  numberOfMonths,
  isScrollScreen,
  defaultVisibility,
  onCancel,
  onApply,
  ...rest
}) => {
  const dayPickerRef = useRef(null);
  const dropdownRef = useRef(null);

  const [state, setState] = useState({
    start: initialStartDate,
    end: initialEndDate,
    enteredTo: initialEndDate
  });
  const [isVisible, setIsVisible] = useState(defaultVisibility);
  const [buttonLabel, setButtonLabel] = useState(lang.when);

  /**
   * @effects
   */
  useEffect(() => {
    lang.setLanguage(locale);
  }, [locale]); // eslint-disable-line

  useEffect(() => {
    if (isVisible) {
      const dropdown = document.querySelector(".WechaletRangePicker");
      const dropdownBounds = dropdown?.getBoundingClientRect();
      if (dropdownBounds?.left < 0) {
        dropdown.style.left = "0px";
        dropdown.style.right = "auto";
      }

      if (dropdownBounds?.right < 0) {
        dropdown.style.right = "0px";
        dropdown.style.left = "auto";
      }
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && isScrollScreen) {
      document.body.classList.add("wc-opened-menu");
    } else {
      document.body.classList.remove("wc-opened-menu");
    }
  }, [isVisible, isScrollScreen]);

  /**
   * set button label based on state and visibility.
   */
  useEffect(() => {
    if (state.start && state.end) {
      setButtonLabel(
        `${dayjs(state.start).format(displayFormat)} - ${dayjs(
          state.end
        ).format(displayFormat)}`
      );
    } else if (state.start && !state.end) {
      setButtonLabel(
        `${dayjs(state.start).format(displayFormat)} - ${lang.checkout}`
      );
    } else {
      setButtonLabel(
        `${isVisible ? lang.checkin + " - " + lang.checkout : lang.when}`
      );
    }
  }, [state, isVisible]); // eslint-disable-line

  /**
   * set the calender to invisible when the user click outside of the box
   * @param {Event} event dom object where the mouse is clicked
   */
  const handleClickAway = (event) => {
    if (isVisible) {
      setIsVisible(false);
    }
  };

  /**
   * checks if a user is selecting first day or not
   * @param {Date} start start date
   * @param {Date} end end date
   * @param {Date} date current date
   * @returns {Boolean}
   */
  const isSelectingFirstDay = (start, end, date) => {
    const isBeforeFirstDay = start && dayjs(date).isBefore(dayjs(start), "day");
    const isRangeSelected = start && end;
    return !start || isBeforeFirstDay || isRangeSelected;
  };

  /**
   * Event handler for day cell click event
   * @param {Date} date current date being rendered by calendar
   * @param {Object} modifiers modifier being matched with the current date
   */
  const handleDayClick = (date, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    const { start, end } = state;
    let newState = {};
    // case where we are selecting the first day
    if (isSelectingFirstDay(start, end, date)) {
      newState = {
        ...state,
        start: date,
        end: null,
        enteredTo: null
      };
      setState(newState);
      notifyOutsideWorld(newState);
    } else {
      newState = {
        ...state,
        end: date,
        enteredTo: date
      };
      setState(newState);
      notifyOutsideWorld(newState);
      setIsVisible(false);
    }
  };

  /**
   * Event handler for mouse hovering on day cells
   * @param {Date} date
   */
  const handleDayMouseEnter = (date) => {
    if (!isSelectingFirstDay(state.start, state.end, date)) {
      setState({
        ...state,
        enteredTo: date
      });
    }
  };

  /**
   * Event handler for mouse hovering on day cells
   * @param {Date} date
   */
  const handleCancelButtonClick = (day, modifiers) => {
    const nullState = {
      start: null,
      end: null,
      enteredTo: null
    };
    setIsVisible(false);
    setState(nullState);
    onCancel(nullState);
    setButtonLabel(`${lang.when}`);
  };

  /**
   * Event handler to open calendar
   * @param {Date} date
   */
  const toggleCalendar = (e) => {
    e.preventDefault();
    // close datepicker
    if (isVisible) {
      setIsVisible(false);
      notifyOutsideWorld(state);
      return;
    }
    // open datepicker
    setIsVisible(true);
  };

  /**
   * Notify the outside world that state of this component changed.
   * @param {Object} state calendar state
   */
  const notifyOutsideWorld = ({ start, end }) => {
    // we call onApply callback to update outer components
    const isRangeSelected = start && end;
    if (isRangeSelected && dayjs(start).isBefore(dayjs(end), "day")) {
      onApply({
        start: dayjs(start).format("YYYY-MM-DD"),
        end: dayjs(end).format("YYYY-MM-DD")
      });
    }
  };

  /**
   * list of modifiers to apply to the day picker dates.
   */
  const modifiers = {
    start: state.start,
    end: state.enteredTo
  };

  /**
   * list of selected days.
   */
  const selectedDays = [
    state.start,
    {
      from: state.start,
      to: state.enteredTo
    }
  ];

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        ref={dayPickerRef}
        className="WechaletRangePicker_Container ThemePrimary"
      >
        <Toggle
          label={buttonLabel}
          onClick={toggleCalendar}
          isVisible={isVisible}
          classnames={clsx(
            "WechaletRangePicker_ToggleButton",
            state.start && state.end && "isActive",
            isVisible && isScrollScreen && "isHover"
          )}
        />
        {isVisible === true && (
          <div className="datepicker-dropdown-container" ref={dropdownRef}>
            <DayPicker
              ref={dropdownRef}
              className="WechaletRangePicker"
              locale={locale}
              months={lang.months}
              weekdaysLong={lang.daysOfWeekLong}
              weekdaysShort={lang.daysOfWeekShort}
              firstDayOfWeek={lang.firstDayOfWeek}
              numberOfMonths={numberOfMonths}
              todayButton={lang.cancel}
              navbarElement={<Navbar />}
              fromMonth={new Date()}
              onDayClick={handleDayClick}
              onDayMouseEnter={handleDayMouseEnter}
              onTodayButtonClick={handleCancelButtonClick}
              selectedDays={selectedDays}
              modifiers={modifiers}
              {...rest}
            ></DayPicker>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

RangePicker.propTypes = {
  /** Date indicating the default start date the calendar should render */
  initialStartDate: PropTypes.object,
  /** Date indicating the default end date the calendar should render */
  initialEndDate: PropTypes.object,
  /** String indicating the default locale the calendar should render in */
  locale: PropTypes.oneOf(["en", "fr"]),
  /** String indicating the date format to be used by day-picker */
  displayFormat: PropTypes.string,
  /** Number indicating the number of months visible in date calendar */
  numberOfMonths: PropTypes.number.isRequired,
  /** Boolean indicating whether dropdown should be visible by default */
  defaultVisibility: PropTypes.bool,
  /** Boolean indicating whether it's scroll screen */
  isScrollScreen: PropTypes.bool,
  /** Function to be triggered when user clicks on apply button */
  onApply: PropTypes.func.isRequired,
  /** Function to be triggered when user clicks on cancel button */
  onCancel: PropTypes.func.isRequired
};

const noop = () => {};

RangePicker.defaultProps = {
  initialStartDate: null,
  initialEndDate: null,
  locale: "en",
  displayFormat: "ddd, D MMM",
  numberOfMonths: 2,
  isScrollScreen: false,
  defaultVisibility: false,
  onCancel: noop
};

export default RangePicker;
