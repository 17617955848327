import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";
import normalizeArray from "modules/shared/utils/normalize-array";
import { Listing } from "modules/shared/types/listing";
import { produce } from "immer";
import { get, set } from "lodash";
import { Calendar } from "modules/listing-calendar/types/calendar";
import { ObjectId } from "modules/shared/types/object-id";

export default (
  state: ContainerState,
  action: PayloadAction<Listing[]>
): ContainerState => {
  const payload = action.payload;
  switch (action.type) {
    case LISTINGS_UPDATED: {
      const listingMap = normalizeArray(payload, "id");
      return produce(state, (draft) => {
        Object.keys(listingMap).forEach((listingId) => {
          const calendarsPath = `listingMutations.${listingId}.calendars`;

          const currentValues: Record<ObjectId, Calendar> =
            get(state, calendarsPath) ?? {};

          const updatedCalendars = normalizeArray(
            listingMap[listingId].calendars,
            "date"
          );

          set(draft, calendarsPath, {
            ...currentValues,
            ...updatedCalendars
          });
        });
      });
    }
    default:
      return {
        ...state
      };
  }
};

export const LISTINGS_UPDATED = "LISTINGS_UPDATED";
