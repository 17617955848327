import getInitialDate from "../utils/get-initial-date";
import getRangeId from "../utils/get-range-id";
import { ContainerState } from "./types";

const initialRange = getInitialDate();

const initialRangeId = getRangeId(1, initialRange[0], initialRange[1]);

/**
 * Multi calendar initial state
 *
 */
const initialState: ContainerState = {
  display: "price",
  from: initialRange[0],
  to: initialRange[1],
  keyword: "",

  statuses: {
    // by default, published status is checked
    PUBLISHED: true
  },
  selectionDayOne: undefined,
  selectionDayTwo: undefined,
  previewDayTwo: undefined,
  yIndexTwo: undefined,
  yIndexOne: undefined,
  yIndexTwoPreview: undefined,
  /**
   * Listing related data
   *
   */
  requestId: "",
  listings: {},
  listingIds: {},
  periods: {},
  overlappedPeriods: {},
  calendars: {},
  meta: {},
  pages: {
    1: true
  },
  rangeStatus: {
    // Initially, the range from which to start the calendar
    // is loading
    [initialRangeId]: "loading"
  },
  segment: {
    x1: undefined,
    x2: undefined
  },
  listingMutations: {}
};

export default initialState;
