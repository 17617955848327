import { combineReducers } from "redux";

import auth from "modules/auth/reducers";
import explore from "modules/explore/store/reducers";
import layout from "modules/layout/store/reducers";
import request from "modules/request/reducers";
import currency from "modules/currency/reducers";
import settings from "modules/settings/reducers";
import { calendarsReducer as calendars } from "modules/calendar/reducers";
import navigationStoreReducer from "modules/navigation/reducers";
import listingCalendar from "modules/listing-calendar/store/reducers";
import integrations from "modules/integrations/store/reducers";

const AppReducers = combineReducers({
  /* app’s top-level reducers */
  auth,
  layout,
  request,
  settings,
  currency,
  explore,
  calendars,
  listingCalendar,
  integrations,
  navigation: navigationStoreReducer
});

const rootReducer = (state, action) => {
  if (action === undefined || action.type === undefined) {
    return state;
  }
  return AppReducers(state, action);
};

export default rootReducer;
