import Query from "utils/Query";
import {
  requestStart,
  requestFailure,
  requestSuccess
} from "modules/request/actions";

/**
 * Fetch resource from server
 * @param {Object} payload request parameters
 * @param {Function} onSuccess on success callback after request success
 * @param {Function} onError on error callback after request error
 */
export const createListing = (payload = {}, onSuccess, onError) => (
  dispatch
) => {
  const endpoint = "v1/hosting/listings";
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .post(payload)
    .then((json) => json.data)
    .then((data) => {
      dispatch(requestSuccess(endpoint, data));
      onSuccess(data);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

/**
 * Update a listing on server.
 * @param {String} endpoint request parameters
 * @param {Object} payload request payload
 * @param {Function} onSuccess on success callback after request success
 * @param {Function} onError on error callback after request error
 */
export const updateListing = (endpoint, payload, onSuccess, onError) => (
  dispatch
) => {
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .update(payload)
    .then((json) => json.data)
    .then((data) => {
      dispatch(requestSuccess(endpoint, data));
      onSuccess(data);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

/**
 * Upload expiary file to the server.
 * @param {String} endpoint request parameters
 * @param {Object} payload request payload
 * @param {Function} onSuccess on success callback after request success
 * @param {Function} onError on error callback after request error
 */
export const uploadExpiaryfile = (endpoint, payload, onSuccess, onError) => (
  dispatch
) => {
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .upload(payload)
    .then((json) => json.data)
    .then((data) => {
      dispatch(requestSuccess(endpoint, data));
      onSuccess(data);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

/**
 * delete file.
 * @param {String} endpoint request parameters
 * @param {Object} payload request payload
 * @param {Function} onSuccess on success callback after request success
 * @param {Function} onError on error callback after request error
 */
export const deleteFile = (endpoint, payload, onSuccess, onError) => (
  dispatch
) => {
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .delete(payload)
    .then((json) => json.data)
    .then((data) => {
      dispatch(requestSuccess(endpoint, data));
      onSuccess(data);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

/**
 * publish a given listing by it's id.
 * @param {String} endpoint request parameters
 * @param {Object} params request params
 * @param {Function} onSuccess on success callback after request success
 * @param {Function} onError on error callback after request error
 */
export const publishListing = (listingId, payload = {}, onSuccess, onError) => (
  dispatch
) => {
  const endpoint = `v1/hosting/listings/${listingId}/publish`;
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .update(payload)
    .then((json) => json.data)
    .then((data) => {
      dispatch(requestSuccess(endpoint, data));
      onSuccess(data);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

/**
 * pause a given listing by it's id.
 * @param {String} endpoint request parameters
 * @param {Object} params request params
 * @param {Function} onSuccess on success callback after request success
 * @param {Function} onError on error callback after request error
 */
export const pauseListing = (listingId, payload = {}, onSuccess, onError) => (
  dispatch
) => {
  const endpoint = `v1/hosting/listings/${listingId}/pause`;
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .update(payload)
    .then((json) => json.data)
    .then((data) => {
      dispatch(requestSuccess(endpoint, data));
      onSuccess(data);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

/**
 * soft deletes a given listing by it's id.
 * @param {String} endpoint request parameters
 * @param {Object} params request params
 * @param {Function} onSuccess on success callback after request success
 * @param {Function} onError on error callback after request error
 */
export const deleteListing = (listingId, payload = {}, onSuccess, onError) => (
  dispatch
) => {
  const endpoint = `v1/hosting/listings/${listingId}/delete`;
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .update(payload)
    .then((json) => json.data)
    .then((data) => {
      dispatch(requestSuccess(endpoint, data));
      onSuccess(data);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};
