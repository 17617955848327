import LocalizedStrings from "react-localization";
const lang = new LocalizedStrings({
  en: {
    clear: "Clear",
    apply: "Apply"
  },
  fr: {
    clear: "Annuler",
    apply: "Appliquer"
  },
  es: {
    clear: "Claro",
    apply: "Aplicar"
  },
  it: {
    clear: "Chiaro",
    apply: "Applicare"
  }
});
export default lang;
