import dayjs from "dayjs";
import { RANGE_FORMAT } from "../constants/range-format";

/**
 * Returns a unique id that represents a range
 *
 * this is used to identify what ranges are currently being loaded
 * note that the double "--" separator is important here
 *
 */
export default (page: number, from?: string, to?: string) => {
  const fromId = dayjs(from).format(RANGE_FORMAT);
  const toId = dayjs(to).format(RANGE_FORMAT);
  return `${fromId}--${toId}--${page}`;
};
