import { FC, useMemo } from "react";
import { getLocale } from "modules/settings/selectors";
import { uniqueId } from "lodash";
import { useSelector } from "react-redux";
import classes from "./toggle-switch.module.scss";
import clsx from "clsx";
import messages from "./messages";
import useLang from "@design-library/utils/useLang";

interface Props {
  disabled?: boolean;
  id?: string;
  loading?: boolean;
  offText?: string;
  onChange: (newValue?: boolean) => void;
  onText?: string;
  theme?: "default" | "banner";
  value?: boolean;
}

const ToggleSwitch: FC<Props> = (props) => {
  const {
    id,
    onText,
    offText,
    value,
    disabled,
    loading,
    onChange,
    theme = "default",
    ...attrs
  } = props;

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  const onClick = (newValue: boolean) => {
    if (disabled) {
      return;
    }
    onChange(newValue);
  };

  const _id = useMemo(() => id || uniqueId("toggle-switch"), [id]);

  return (
    <span className={classes[theme]}>
      <div className={classes.wrapper}>
        <label
          className={clsx(classes.slider, {
            "is-checked": value,
            "is-disabled": disabled || loading
          })}
          htmlFor={_id}
        >
          <input
            id={_id}
            name="input"
            type="checkbox"
            checked={value}
            onChange={() => {}}
            className={classes.input}
            disabled={disabled || loading}
            {...attrs}
          />
          <span className={classes.overlay}>
            <span
              onClick={() => onClick(true)}
              className={classes.on}
              aria-hidden={true}
            >
              {onText || lang.on}
            </span>
            <span
              onClick={() => onClick(false)}
              className={classes.off}
              aria-hidden={true}
            >
              {offText || lang.off}
            </span>
          </span>
        </label>
      </div>
    </span>
  );
};

export default ToggleSwitch;
