import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";

export default (
  state: ContainerState,
  action: PayloadAction<DisplayType>
): ContainerState => {
  const payload = action.payload;
  switch (action.type) {
    case DISPLAY_TYPE_CHANGED:
      return {
        ...state,
        display: payload
      };
    default:
      return state;
  }
};

export type DisplayType = ContainerState["display"];

export const DISPLAY_TYPE_CHANGED = "DISPLAY_TYPE_CHANGED";
