import { queryCache, QueryConfig, useMutation } from "react-query";
import Query from "../../utils/Query";

export const useIsStillAvailable = (
  bookingId: string,
  config?: QueryConfig<any>
) => {
  const query = useMutation(
    () =>
      Query(`v1/bookings/${bookingId}/is-still-available`)
        .put({})
        .then((json) => json.data),
    {
      ...config,
      onSuccess(data) {
        queryCache.invalidateQueries("v1/inbox");
        queryCache.invalidateQueries(["v1/bookings", bookingId]);
        config?.onSuccess?.(data);
      },
      onError(error) {
        queryCache.invalidateQueries("v1/inbox");
        queryCache.invalidateQueries(["v1/bookings", bookingId]);
        config?.onError?.(error);
      }
    }
  );
  return query;
};
