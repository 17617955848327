import {
  SET_CURRENCY,
  DEFAULT_CURRENCY,
  SET_CURRENCY_RATES,
  CurrencyState
} from "./types";

const defaultState = {
  base: localStorage.getItem("currency") || DEFAULT_CURRENCY,
  rates: {}
};

export default function (
  state: CurrencyState = defaultState,
  action: any = {}
) {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENCY:
      return {
        ...state,
        base: payload.currency
      };
    case SET_CURRENCY_RATES:
      return {
        ...state,
        rates: payload.rates
      };
    default:
      return state;
  }
}
