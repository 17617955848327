import {
  NavigationStoreAction,
  NavigationStoreState
} from "./types";

const initialState: NavigationStoreState = {
  mode: localStorage.getItem("store.navigation.mode") === "host"
    ? "host"
    : "travel",
  navIsVisible: true
};

const navigationStoreReducer = (state = initialState, action: NavigationStoreAction) => {
  switch (action.type) {
    case "SET_NAV_MODE":
      localStorage.setItem("store.navigation.mode", action.payload);
      return {
        ...state,
        mode: action.payload
      };
    case "SET_NAV_VISIBILITY":
      return {
        ...state,
        navIsVisible: action.payload
      };
    default:
      return state;
  }
};

export default navigationStoreReducer;
