import { createSelector } from "reselect";
import { ObjectId } from "modules/shared/types/object-id";
import selectListings from "./select-listings";

/**
 * Returns a listing by id, if it exists
 *
 */
export default (id: ObjectId) =>
  createSelector([selectListings], (listings) => {
    return listings[id];
  });
