import { SEARCH_KEYWORD_CHANGED } from "../reducers/search-keyword-changed";

/**
 * Changes the search keyword
 *
 */
export default (payload: string) => {
  return {
    type: SEARCH_KEYWORD_CHANGED,
    payload
  };
};
