import { AuthState } from "./types";
import { createSelector } from "reselect";
import { RootState } from "redux/store/types";
import User from "./Models/User";

/**
 * @deprecated do not grab the entire auth state object
 */
export const getAuth = createSelector(
  (state: RootState): AuthState => state.auth,
  (auth: AuthState) => auth
);

/**
 * Returns the authenticated user
 * @deprecated use selectAuthenticatedUser instead
 */
export const getAuthenticatedUser = createSelector(
  (state: RootState): AuthState => state.auth,
  (auth: AuthState) => (auth.user ? new User(auth.user) : undefined)
);
