import { STATUS_CLEARED } from "../reducers/status-cleared";

/**
 * Clears all statuses
 *
 */
export default () => {
  return {
    type: STATUS_CLEARED,
    payload: {}
  };
};
