import { DATE_RANGE_CHANGED } from "../reducers/date-range-changed";

/**
 * Changes the date range
 *
 */
export default (range: (string | undefined)[]) => {
  return {
    type: DATE_RANGE_CHANGED,
    payload: range
  };
};
