import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState, CookieMap } from "../types";

export default (
  state: ContainerState,
  action: InitializeAction
): ContainerState => {
  switch (action.type) {
    case CONSENT_COOKIES_INITIALIZED:
      return {
        ...state,
        cookiesAcceptedAt: action.payload.acceptedAt,
        acceptedCookies: action.payload.acceptedCookies
      };
    default:
      return state;
  }
};

type InitializeAction = PayloadAction<{
  acceptedAt: string;
  acceptedCookies: CookieMap;
}>;

export const CONSENT_COOKIES_INITIALIZED = "CONSENT_COOKIES_INITIALIZED";
