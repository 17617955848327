import { createSelector } from "reselect";
import { ObjectId } from "modules/shared/types/object-id";
import dayjs from "dayjs";
import { Period } from "modules/listing-calendar/types/period";
import selectPeriodsByListingId from "./select-periods-by-listing-id";

/**
 * Returns whether or not the day has a background overlap
 * A background overlap here means that the day given
 * falls between any of the overlapping periods
 *
 */
export default (listingId?: ObjectId, day?: string) =>
  createSelector([selectPeriodsByListingId(listingId)], (periods) => {
    if (!day || !listingId) {
      return false;
    }

    const dateToCheck = dayjs(day);

    return periods.some(overlapBetween(dateToCheck));
  });

const overlapBetween = (dateToCheck: dayjs.Dayjs) => (item: Period) => {
  const startDate = dayjs(item.start_date);

  const endDate = dayjs(item.end_date);

  return dateToCheck.isBetween(startDate, endDate, "date", "()");
};
