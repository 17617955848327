import {
  useRef,
  useMemo,
  useCallback,
  useLayoutEffect,
  forwardRef,
  useState
} from "react";
import PropTypes from "prop-types";
import ReactSelect, { components } from "react-select";
import ChevronIcon from "../ChevronIcon";
import clsx from "clsx";
import ValidationMessage from "../ValidationMessage";
import style from "./Select.module.scss";
import useBreakpoints from "../../utils/useBreakpoints";

/**
 * Select component.
 * Accepts all [React Select](https://react-select.com) component props.
 */

const NativeSelect = forwardRef(
  (
    {
      id,
      containerAttrs,
      nativeAttrs,
      value,
      placeholder,
      onChange,
      options,
      isClearable
    },
    ref
  ) => {
    // Values

    const handleNativeChange = (event) => {
      onChange(
        event.target.value.length
          ? options?.[Number(event.target.value)]?.value
          : undefined
      );
    };

    const selectedIndex = useMemo(
      () => options.findIndex((option) => option.value === (value?.value || value)),
      [options, value]
    );

    return (
      <div className={style.nativeWrapper} {...containerAttrs}>
        <select
          id={id}
          name="select"
          ref={ref}
          className={clsx(style.nativeSelect, {
            [style.placeholder]: selectedIndex < 0
          })}
          onChange={handleNativeChange}
          value={selectedIndex >= 0 ? selectedIndex : ""}
          aria-describedby={`${id}_description`}
          {...nativeAttrs}
        >
          {placeholder && (
            <option disabled={!isClearable} value="">
              {placeholder}
            </option>
          )}
          {options.map((opt, index) => (
            <option title={opt.label} value={index} key={index}>
              {opt.label}
            </option>
          ))}
        </select>

        <span className={style.arrow}>
          <ChevronIcon />
        </span>
      </div>
    );
  }
);

const ReactSelectWrapper = forwardRef(
  (
    {
      id,
      label,
      invalid,
      containerAttrs,
      nativeAttrs,
      value,
      theme,
      menuAlignRight,
      transparentBox,
      onChange,
      options,
      openPlaceholder,
      placeholder,
      ...attrs
    },
    ref
  ) => {
    // Refs

    const innerRef = useRef();

    const setRef = useCallback(
      (el) => {
        if (ref) ref.current = el;
        innerRef.current = el;
      },
      [ref]
    );

    // Values

    const selectedValue = useMemo(
      () =>
        Array.isArray(options)
          ? options.find((option) => option.value === value)
          : null,
      [value, options]
    );

    // Effects

    useLayoutEffect(() => {
      // Set unsupported aria attributes
      innerRef.current.select.inputRef.setAttribute(
        "aria-describedby",
        `${id}_description`
      );
    }, [id]);

    useLayoutEffect(() => {
      if (containerAttrs == null) return;

      // Set container attributes
      const container = innerRef.current.select.controlRef.parentNode;

      Object.entries(containerAttrs).forEach(([key, value]) => {
        container.setAttribute(key, value);
      });

      return () => {
        Object.entries(containerAttrs).forEach(([key, value]) => {
          container.removeAttribute(key, value);
        });
      };
    }, [containerAttrs]);

    // Events

    const onReactSelectChange = (option) => {
      onChange(option?.value);
    };

    const CustomOption = (props) => {
      return (
        <components.Option {...props}>
          <span
            data-cy={`select.${props.data.label}`}
            key={props.innerProps.key}
          >
            <div data-tooltip-id="select-tooltip" data-tooltip-content={props.data.label}>
              {props.data.label}
            </div>
          </span>
        </components.Option>
      );
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const morphedPlaceholder = useMemo(() => {
      if (!openPlaceholder) {
        return placeholder;
      }
      return isMenuOpen ? openPlaceholder : placeholder;
    }, [isMenuOpen, placeholder, openPlaceholder]);

    return (
      <ReactSelect
        ref={setRef}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        placeholder={morphedPlaceholder}
        components={{
          Option: CustomOption
        }}
        inputId={id}
        className={style.outer}
        classNamePrefix={style.prefix}
        value={selectedValue || value}
        options={options}
        onChange={onReactSelectChange}
        {...attrs}
      />
    );
  }
);

const Select = forwardRef((props, ref) => {
  const breakpoints = useBreakpoints();

  const {
    id: idProp,
    label,
    invalid,
    theme,
    menuAlignRight,
    transparentBox
  } = props;

  const id = useMemo(
    () => idProp || `Select_${Math.random().toString(36).substring(7)}`,
    [idProp]
  );

  return (
    <div
      className={clsx(style.wrapper, style[theme], {
        "is-invalid": invalid,
        [style.transparentBox]: transparentBox,
        [style.menuAlignRight]: menuAlignRight
      })}
    >
      {!!label && (
        <label className={style.label} htmlFor={id}>
          {label}
        </label>
      )}

      {breakpoints.lg ? (
        <>
          <ReactSelectWrapper id={id} ref={ref} {...props} />
        </>
      ) : (
        <NativeSelect id={id} ref={ref} {...props} />
      )}
      <div id={`${id}_description`}>
        {typeof invalid === "string" && (
          <ValidationMessage>{invalid}</ValidationMessage>
        )}
      </div>
    </div>
  );
});

Select.propTypes = {
  /** Label text */
  label: PropTypes.string,
  /**
   * Validation state.
   * `true` displays field invalid.
   * A `string` displays field invalid with a message.
   */
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Attributes applied to select container */
  containerAttrs: PropTypes.object,
  /** Attributes applied to select element on mobile */
  nativeAttrs: PropTypes.object,
  /** Selected value */
  value: PropTypes.any,
  /** Theme */
  theme: PropTypes.oneOf(["default", "inline"]),
  /** Make the box background transparent unless on hover */
  transparentBox: PropTypes.bool,
  /** Align menu to the right */
  menuAlignRight: PropTypes.bool,
  /** On change handler. Gets the value passed to it. */
  onChange: () => { },
  /** React Select placeholder prop */
  placeholder: PropTypes.string,
  /** Like placeholder but only shows when menu is open */
  openPlaceholder: PropTypes.string,
  /** React Select options prop */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string
    })
  ),
  /** React Select menuPlacement prop */
  menuPlacement: PropTypes.string,
  /** React Select isSearchable prop */
  isSearchable: PropTypes.bool,
  /** React Select isClearable prop */
  isClearable: PropTypes.bool
};

Select.defaultProps = {
  placeholder: "",
  theme: "default",
  transparentBox: false,
  menuAlignRight: false,
  nativeAttrs: {},
  menuPlacement: "auto",
  isSearchable: false
};

export default Select;
