import {
  getActionFactory,
  postActionFactory,
  updateActionFactory,
  getByIdActionFactory,
  postByIdActionFactory,
  updateByIdActionFactory,
  updateByParentIdActionFactory,
  deleteByParentIdActionFactory,
  deleteByIdActionFactory,
  deleteAllRulesActionFactory
} from "modules/request/factories";

import { userFavoritesIncrement, userFavoritesDecrement } from "modules/auth/actions";
// account status
export const fetchAccountVerificationStatus = getActionFactory(
  "v1/account-verification/status"
);
export const addNewPhoneNumber = postActionFactory("v1/account/phones");
export const verifyPhoneNumber = updateByIdActionFactory(
  "v1/account/phones/:id/verify"
);
export const resendVerificationCode = updateByIdActionFactory(
  "v1/account/phones/:id/resend"
);
export const makePhoneNumberAsPrimary = updateByIdActionFactory(
  "v1/account/phones/:id/make-primary"
);
export const deletePhoneNumber = deleteByIdActionFactory(
  "v1/account/phones/:id"
);

// trips
export const fetchTravellingStats = getActionFactory("v1/travelling/statistics/all");
export const fetchTrips = getActionFactory("v1/travelling/trips");
export const fetchPastTrips = getActionFactory("v1/travelling/past-trips");
export const fetchFavorites = getActionFactory("v1/travelling/favorites");

// inbox
export const sendMessage = updateByIdActionFactory("v1/inbox/:id");
export const readThread = getByIdActionFactory("v1/inbox/:id");
export const updateThreadStatus = updateByIdActionFactory(
  "v1/inbox/:id/status"
);

// bookings
export const createBooking = postActionFactory("v1/bookings");
export const resolveBooking = updateByIdActionFactory(
  "v1/bookings/:id/respond"
);
export const updateReservationParams = updateByIdActionFactory(
  "v1/bookings/:id/reservation-parameters"
);
export const acceptBooking = updateByIdActionFactory(
  "v1/bookings/:id/invite"
);
export const declineBooking = updateByIdActionFactory(
  "v1/bookings/:id/decline"
);

export const inviteAfterExpiredBooking = updateByIdActionFactory(
  "v1/bookings/:id/invite-expired"
);

// bedrooms
export const fetchBedrooms = getByIdActionFactory(
  "v1/hosting/listings/:id/bedrooms"
);

export const addBedroom = postByIdActionFactory(
  "v1/hosting/listings/:id/bedrooms"
);

export const updateBedroom = updateByParentIdActionFactory(
  "v1/hosting/listings/:parentId/bedrooms/:childId"
);

export const deleteBedroom = deleteByParentIdActionFactory(
  "v1/hosting/listings/:parentId/bedrooms/:childId"
);

// custom rules
export const fetchCustomRules = getByIdActionFactory(
  "v1/hosting/listings/:id/custom-rules"
);

export const addCustomRule = postByIdActionFactory(
  "v1/hosting/listings/:id/custom-rules"
);

export const deleteAllCustomRules = deleteAllRulesActionFactory(
  "v1/hosting/listings/:id/custom-rules"
);

export const updateCustomRule = updateByParentIdActionFactory(
  "v1/hosting/listings/:parentId/custom-rules/:childId"
);

export const deleteCustomRule = deleteByParentIdActionFactory(
  "v1/hosting/listings/:parentId/custom-rules/:childId"
);

// extra services
export const fetchExtraServices = getByIdActionFactory(
  "v1/hosting/listings/:id/extra-services"
);

export const addExtraService = postByIdActionFactory(
  "v1/hosting/listings/:id/extra-services"
);

export const updateExtraService = updateByParentIdActionFactory(
  "v1/hosting/listings/:parentId/extra-services/:childId"
);

export const deleteExtraService = deleteByParentIdActionFactory(
  "v1/hosting/listings/:parentId/extra-services/:childId"
);

// photos
export const fetchPhotos = getByIdActionFactory(
  "v1/hosting/listings/:id/photos"
);

export const updateVRTourUrl = updateByIdActionFactory(
  "v1/hosting/listings/:id/photos/vr-tour"
);

export const deletePhoto = deleteByParentIdActionFactory(
  "v1/hosting/listings/:parentId/photos/:childId"
);

export const updatePhotoPosition = updateByParentIdActionFactory(
  "v1/hosting/listings/:parentId/photos/:childId/position"
);

export const updatePhotoCaption = updateByParentIdActionFactory(
  "v1/hosting/listings/:parentId/photos/:childId/caption"
);

// profile
export const updateProfile = updateActionFactory("v1/profile");
export const updatePayoutEmail = updateActionFactory("v1/profile/payout-email");

// calendar
export const fetchPrimaryCalendar = getByIdActionFactory(
  "v1/hosting/listings/:id/primary-calendar"
);
export const updatePrimaryCalendar = updateByIdActionFactory(
  "v1/hosting/listings/:id/primary-calendar"
);
export const fetchCalendarPeriods = getByIdActionFactory(
  "v1/hosting/listings/:id/calendar-periods"
);
export const updateMultipleCalendars = updateActionFactory(
  "v1/hosting/listings/calendars"
);

export const fetchPrimaryCalendarsForAllListings = getActionFactory("v1/hosting/listings/calendars");
export const fetchPeriodsForAllListings = getActionFactory("v1/hosting/listings/periods");

// external calendars
export const fetchExternalCalendars = getByIdActionFactory(
  "v1/hosting/listings/:id/external-calendars"
);
export const importExternalCalendar = postByIdActionFactory(
  "v1/hosting/listings/:id/external-calendars"
);
export const syncExternalCalendar = updateByParentIdActionFactory(
  "v1/hosting/listings/:parentId/external-calendars/:childId"
);
export const deleteExternalCalendar = deleteByParentIdActionFactory(
  "v1/hosting/listings/:parentId/external-calendars/:childId"
);

// taxes
export const updateTaxes = updateByIdActionFactory(
  "v1/hosting/listings/:id/taxes"
);
export const deleteTaxes = deleteByIdActionFactory(
  "v1/hosting/listings/:id/taxes"
);
export const applyTaxesToAllInRegion = postByIdActionFactory(
  "v1/hosting/listings/:id/apply-taxes"
);

// listings
export const fetchMyListings = getActionFactory("v1/hosting/listings");
export const likeListing = postByIdActionFactory("v1/listings/:id/likes", (data, { dispatch }) => {
  if (!data.data.is_fav) {
    dispatch(userFavoritesIncrement());
  } else {
    dispatch(userFavoritesDecrement());
  }
});

export * from "./listing";
