import shared from "translations/shared";

export default {
  en: {
    ...shared.en,
    night: "night",
    nights: "nights",
    wechaletFee: "Service fee",
    hostWechaletFee: "Host service fee",
    accommodationTax: "Lodging Tax",
    cleaningFee: "Cleaning fee",
    rebateDiscount: "Rebate code",
    giftDiscount: "Gift card",
    refundableDeposit: "Refundable deposit",
    damageProtection: "Damage protection",
    total: "Total",
    totalWithDeposit: "Total + Deposit",
    hostPayout: "Host Payout",
    monthlyDiscount: "Monthly discount",
    weeklyDiscount: "Weekly discount",
    serviceFeeGuestLink:
      "http://help.wechalet.com/en/articles/4412084-what-are-the-wechalet-service-charges-for-travelers",
    serviceFeeHostLink:
      "http://help.wechalet.com/en/articles/4387810-what-are-the-wechalet-service-fees-for-hosts",
    cleaningFeeLink:
      "http://help.wechalet.com/en/articles/4413005-what-is-the-wechalet-cleaning-fee",
    accomodationTaxLink:
      "http://help.wechalet.com/en/articles/4413144-wechalet-collects-and-remits-the-lodging-tax-in-quebec",
    securityDepositLink:
      "http://help.wechalet.com/en/articles/2387632-how-do-refundable-security-deposits-work-on-wechalet",
    waiverDepositLink: "http://help.wechalet.com/en/articles/7004989-how-does-damage-protection-fees-work-for-wechalet-guests",
    per_guest: "per guest",
    per_night: "per night",
    single_fee: "single fee",
    per_guest_per_night: "per night per guest"
  },
  fr: {
    ...shared.fr,
    night: "nuit",
    nights: "nuits",
    wechaletFee: "Frais de service",
    hostWechaletFee: "Frais de service hôte ",
    accommodationTax: "Taxe d'Hébergement",
    cleaningFee: "Frais de nettoyage",
    refundableDeposit: "Dépôt remboursable",
    damageProtection: "Protection des dommages",
    rebateDiscount: "Coupon Rabais",
    giftDiscount: "Certificat cadeau",
    total: "Total",
    totalWithDeposit: "Total + Dépôt remboursable",
    hostPayout: "Paiement à l'hôte",
    monthlyDiscount: "Rabais mensuel",
    weeklyDiscount: "Rabais semaine",
    serviceFeeGuestLink:
      "http://help.wechalet.com/fr/articles/4412084-quels-sont-les-frais-de-service-wechalet-pour-les-voyageurs",
    serviceFeeHostLink:
      "http://help.wechalet.com/fr/articles/4387810-quels-sont-les-frais-de-service-wechalet-pour-les-hotes",
    cleaningFeeLink:
      "http://help.wechalet.com/fr/articles/4413005-a-quoi-correspondent-les-frais-de-nettoyage-wechalet",
    accomodationTaxLink:
      "http://help.wechalet.com/fr/articles/4413144-wechalet-collecte-et-verse-la-taxe-d-hebergement-a",
    securityDepositLink:
      "http://help.wechalet.com/fr/articles/2387632-comment-fonctionnent-les-depots-de-securite-remboursables-sur-wechalet",
    waiverDepositLink: "http://help.wechalet.com/fr/articles/7004989-comment-fonctionne-l-option-de-protection-des-dommages-pour-les-invites-wechalet",
    per_guest: "par personne",
    per_night: "par nuit",
    single_fee: "frais unique",
    per_guest_per_night: "par nuit par personne"
  }
};
