import PropTypes from "prop-types";
import clsx from "clsx";
import Loader from "../Loader";
import style from "./Button.module.scss";
import Link from "modules/router/Link";
/**
 * Button component
 */
const Button = ({
  className = "",
  theme = "primary",
  size = "large",
  loading = false,
  outlined = false,
  block = false,
  icon = undefined,
  shadow = false,
  disabled = false,
  rounded = false,
  route = undefined,
  flush = false,
  children,
  ...attrs
}) => {
  const classNames = clsx(className, style.button, style[theme], style[size], {
    [style.outlined]: outlined,
    [style.block]: block,
    [style.flush]: flush,
    [style.disabled]: disabled,
    [style.rounded]: rounded,
    [style.loading]: loading,
    [style.shadow]: shadow
  });

  const renderContent = () => (
    <>
      {loading && (
        <span className={style.loader}>
          <Loader />
        </span>
      )}
      <span className={style.content}>
        {icon && <span className={style.icon}>{icon()}</span>}
        {children}
      </span>
    </>
  );

  return route ? (
    <Link route={route} className={classNames} {...attrs}>
      {renderContent()}
    </Link>
  ) : (
    <button
      type="button"
      className={classNames}
      disabled={disabled || loading}
      aria-busy={loading}
      {...attrs}
    >
      {renderContent()}
    </button>
  );
};

Button.propTypes = {
  /** Button element type */
  type: PropTypes.string,
  /** Button theme */
  theme: PropTypes.oneOf([
    "primary",
    "primarySolid",
    "primaryFaded",
    "secondary",
    "link",
    "info",
    "infoSolid",
    "roundedInfo",
    "roundedInfoTransparent",
    "roundedInfoBlack",
    "circle-secondary",
    "google",
    "facebook",
    "toggle"
  ]),
  /** Button size */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  shadow: PropTypes.bool,
  /** Display button as loading */
  loading: PropTypes.bool,
  /** Use outlined style */
  outlined: PropTypes.bool,
  /** Display as block (take full width) */
  block: PropTypes.bool,
  icon: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  rounded: PropTypes.bool,
  to: PropTypes.object
};

export default Button;
