import style from "./ServiceIcon.module.scss";

const ServiceIcon = () => (
  <svg
    className={style.icon}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 17"
  >
    <path fillRule="evenodd" d="M9.75 5.5c-.4142 0-.75.33579-.75.75s.3358.75.75.75c1.322 0 2.7118.75866 3.4094 1.7557.2375.3394.7051.4221 1.0445.1846.3394-.2375.422-.7051.1846-1.04447C13.4134 6.5022 11.5672 5.5 9.75 5.5z"/>
    <path fillRule="evenodd" d="M7.5 2.5C7.5 1.11928988 8.61929989 0 10 0s2.5 1.11928988 2.5 2.5v.87957c3.33979988 1.05360986 5.75 4.18124963 5.75 7.87043 0 .41419983-.33580017.75-.75.75h-15c-.41420984 0-.75-.33580017-.75-.75 0-3.6786499 2.42218971-6.7930398 5.75-7.85793972V2.5zm3.5 0c0-.55227995-.4477005-1-1-1-.55230045 0-1 .44772005-1 1v.56264019c.33479977-.04134035.67570019-.06262016 1.02140045-.06262016.33129978 0 .65779972.0195198.97859955.05747986V2.5zm5.70910072 8H3.29153013c.37571001-3.37203026 3.25402975-5.99997997 6.72987032-5.99997997 3.47109986 0 6.31679917 2.6236105 6.68770027 5.99997997z"/>
    <path fillRule="evenodd" d="M0 15c0-1.10459995.89543009-2 2-2h16c1.1046009 0 2 .89540005 2 2 0 1.104599-.8953991 2-2 2H2c-1.10456991 0-2-.895401-2-2zm2-.5c-.27613997 0-.5.22389984-.5.5 0 .27610016.22386003.5.5.5h16c.27610016 0 .5-.22389984.5-.5 0-.27610016-.22389984-.5-.5-.5H2z"/>
  </svg>
);

export default ServiceIcon;
