import { ContainerState } from "../types";
import { PayloadAction } from "modules/shared/types/payload-action";
import { PeriodSet } from "modules/listing-calendar/types/period-set";
import { produce } from "immer";
import normalizeArray from "modules/shared/utils/normalize-array";

export default (
  state: ContainerState,
  action: PayloadAction<PeriodSet[]>
): ContainerState => {
  switch (action.type) {
    case PERIODS_UPDATED: {
      const periods = action.payload;
      return {
        ...state,
        periods: produce(state.periods, (draft) => {
          periods.forEach((p) => {
            const pNew = normalizeArray(p.periods, "start_date");
            draft[p.id] = pNew;
          });
        })
      };
    }
    default:
      return {
        ...state
      };
  }
};

export const PERIODS_UPDATED = "PERIODS_UPDATED";
