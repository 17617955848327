import { useEffect, createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AnalyticsBrowser } from "@segment/analytics-next";

import { getAuthenticatedUser } from "modules/auth/selector";
import selectAcceptedCookies from "modules/layout/store/selectors/select-accepted-cookies";

const segmentContext = createContext(null);
export const SegmentProvider = ({ children }) => {
  const location = useLocation();
  const user = useSelector(getAuthenticatedUser);
  const hasUser = !!user;
  const phone = user?.rawUser?.phones?.find((phone) => phone.number);
  const acceptedCookies = useSelector(selectAcceptedCookies);

  const [analytics, setAnalytics] = useState(undefined);

  useEffect(() => {
    if (!process.env.REACT_APP_SEGMENT_WRITE_KEY) return;
    if (!acceptedCookies.marketing) return;
    const loadAnalytics = async () => {
      const [response] = await AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY });
      setAnalytics(response);
    };
    if (!window.Cypress) {
      loadAnalytics();
    }
  }, [acceptedCookies]);// eslint-disable-line react-hooks/exhaustive-deps

  // Call page() whenever a new page is visited
  useEffect(() => {
    if (hasUser) analytics?.page();
  }, [analytics, location.pathname, hasUser]);

  useEffect(() => {
    if (!analytics) return;

    if (!hasUser) {
      analytics.reset();
      return;
    }

    analytics.identify(user.id, {
      firstname: user.profile?.firstname,
      lastname: user.profile?.lastname,
      email: user.email,
      phone: phone?.prefix + phone?.number,
      host: user.roles?.includes("host")
    });
  }, [
    hasUser,
    analytics,
    user?.id,
    user?.profile?.firstname,
    user?.profile?.lastname,
    user?.email,
    phone?.number,
    phone?.prefix,
    user?.roles
  ]);

  return (
    <segmentContext.Provider value={analytics}>
      {children}
    </segmentContext.Provider>
  );
};

export const useSegment = () => {
  return useContext(segmentContext);
};

export default useSegment;
