import { Fragment } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

import "./index.scss";

/**
 * CurrencySelect
 *
 * @deprecated Use new Select instead
 */
var CurrencySelect = ({ currency, changeCurrency }) => {
  const options = [
    { value: "CAD", label: "CAD" },
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "MXN", label: "MXN" }
  ];

  const onChange = (selected) => {
    changeCurrency(selected.value);
  };

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <span data-cy={`select.${props.data.label}`} key={props.innerProps.key}>
          {props.data.label}
        </span>
      </components.Option>
    );
  };
  return (
    <Fragment>
      <Select
        className="WechaletSelect"
        components={{
          Option: CustomOption
        }}
        classNamePrefix="WechaletSelect"
        onChange={onChange}
        options={options}
        defaultValue={options.find((e) => e.value === currency)}
        menuPlacement="auto"
        isSearchable={false}
      />
    </Fragment>
  );
};

CurrencySelect.propTypes = {
  /** String current currency */
  currency: PropTypes.string.isRequired,
  /** Function to be triggered when user clicks on the button */
  changeCurrency: PropTypes.func
};

const noop = () => { };

CurrencySelect.defaultProps = {
  currency: "CAD",
  changeCurrency: noop
};

export default CurrencySelect;
