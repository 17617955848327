import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";

export default (
  state: ContainerState,
  action: PayloadAction<void>
): ContainerState => {
  switch (action.type) {
    case MASS_EDIT_MODAL_DISMISSED:
      return {
        ...state,
        selectionDayOne: undefined,
        selectionDayTwo: undefined,
        yIndexTwo: undefined,
        yIndexOne: undefined
      };
    default:
      return state;
  }
};

export const MASS_EDIT_MODAL_DISMISSED = "MASS_EDIT_MODAL_DISMISSED";
