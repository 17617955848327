import { FC } from "react";
import { getLocale } from "modules/settings/selectors";
import { useDispatch, useSelector } from "react-redux";
import changeDisplayType from "modules/listing-calendar/store/actions/change-display-type";
import classes from "./day-value-toggle.module.scss";
import messages from "./messages";
import selectDisplayType from "modules/listing-calendar/store/selectors/select-display-type";
import ToggleSwitch from "@design-library/toggle-switch";
import useLang from "@design-library/utils/useLang";

interface Props {}

export const DayValueToggle: FC<Props> = () => {
  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  const value = useSelector(selectDisplayType);

  const dispatch = useDispatch();

  const onChange = () => {
    const type = value === "min-stay" ? "price" : "min-stay";
    dispatch(changeDisplayType(type));
  };

  return (
    <div className={classes.container}>
      <ToggleSwitch
        offText={lang.minimumStay}
        onChange={onChange}
        onText={lang.prices}
        theme="banner"
        value={value === "price"}
      />
    </div>
  );
};

export default DayValueToggle;
