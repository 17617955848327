import { FC, ReactNode, Fragment, useEffect } from "react";
import GoogleAnalytics from "react-ga4";

/**
 * Google Analytics measurement id
 *
 */
const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

interface Props {
  children: ReactNode;
}

export const AnalyticsProvider: FC<Props> = (props) => {
  const { children } = props;

  useEffect(() => {
    if (!measurementId) {
      console.error("Analytics Measurement ID was not set");
      return;
    }

    GoogleAnalytics.initialize(measurementId, {
      testMode: process.env.NODE_ENV !== "production"
    });
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default AnalyticsProvider;
