import selectAcceptedCookies from "modules/layout/store/selectors/select-accepted-cookies";
import { createContext, useRef, useContext, useEffect } from "react";
import useBreakpoints from "utils/useBreakpoints";
import { useSelector } from "react-redux";

const HubspotContext = createContext(null);

export const HubspotProvider = ({
  /** Hubspot should be enbabled on load */
  enableOnLoad = true,
  /** Only render the widget above this breakpoint */
  minBreakpoint = "xxs",
  children
}) => {
  const isReadyRef = useRef(false);
  const isEnabledRef = useRef(enableOnLoad);
  const isRenderedRef = useRef(false);

  const breakpoints = useBreakpoints();
  const minBreakpointIsActive = useRef(false);
  const acceptedCookies = useSelector(selectAcceptedCookies);

  const onUpdate = () => {
    if (!isReadyRef.current) return;

    const shouldRender = (
      isEnabledRef.current &&
      minBreakpointIsActive.current
    );

    if (shouldRender === isRenderedRef.current) return;
    isRenderedRef.current = shouldRender;

    if (shouldRender) {
      window.HubSpotConversations.widget.load();
    } else {
      window.HubSpotConversations.widget.remove();
    }
  };

  const onReady = () => {
    isReadyRef.current = true;
    onUpdate();
  };

  const { current: controller } = useRef({
    get enabled() {
      return isEnabledRef.current;
    },
    set enabled(value) {
      isEnabledRef.current = Boolean(value);
      onUpdate();
    }
  });

  // Load Hubspot script dynamically
  useEffect(() => {
    if (!process.env.REACT_APP_HUBSPOT_TRACKING_CODE) return;
    if (!acceptedCookies.marketing) return;

    const script = document.createElement("script");

    script.onload = () => {
      window.hsConversationsSettings = {
        loadImmediately: false
      };
      window.hsConversationsOnReady = [
        () => {
          onReady();
        }
      ];
    };
    script.src = `https://js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_TRACKING_CODE}.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      window.HubSpotConversations?.widget?.remove();
      document.body.removeChild(script);
    };
  }, [acceptedCookies]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    minBreakpointIsActive.current = Boolean(breakpoints[minBreakpoint]);
    onUpdate();
  }, [breakpoints, minBreakpoint]);

  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      @media (max-width: 767px) {
        #hubspot-messages-iframe-container.widget-align-right {
          bottom: 80px !important;
          z-index: 9999999 !important;
        }
        #hubspot-messages-iframe-container.widget-align-right:has(.hs-shadow-container.active) iframe {
          height: calc(100% - 80px) !important;
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <HubspotContext.Provider value={controller}>
      {children}
    </HubspotContext.Provider>
  );
};

/**
 * Hubspot hook. Set `enabled` on the returned object to toggle hubspot on and off.
 * @returns {object} Hubspot helper state and methods.
 **/
export const useHubspot = () => {
  const hubspot = useContext(HubspotContext);

  return hubspot;
};

export default useHubspot;
