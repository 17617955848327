import { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import LocalizedStrings from "react-localization";
import ClickAwayListener from "react-click-away-listener";

import "./index.scss";
import strings from "./strings";
import DatePicker from "./DatePicker";
import DropdownToggle from "./DropdownToggle";
import _ from "lodash";
import useBreakpoints from "utils/useBreakpoints";

const placements = {
  primary: "bottom-end",
  secondary: "bottom-start",
  "search-filter": "bottom"
};

const DropdownDatePicker = (props) => {
  const {
    displayFormat,
    locale,
    staticLabel = false,
    theme,
    isScrollScreen,
    isFullWidth,
    defaultVisibility,
    onVisibilityChange,
    onError,
    onCancel,
    disableFlip,
    data
  } = props;

  const passedProps = _.omit(props, ["isFullWidth"]);
  /**
   * @states
   */
  // popper specific
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const breakpoints = useBreakpoints();

  let modifiers = [
    {
      name: "preventOverflow",
      options: {
        rootBoundary: "document"
      }
    }
  ];
  if (disableFlip) {
    modifiers = [
      ...modifiers,
      {
        name: "flip",
        options: {
          enabled: false,
          fallbackPlacements: []
        }
      }
    ];
  }
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placements[theme] || "auto",
    modifiers
  });

  const [datePickerState, setDatePickerState] = useState({});
  const [isVisible, setIsVisible] = useState(defaultVisibility);

  /**
   * @effects
   */

  // a react effect to toggle popper attributes
  // this hook also trigger invisibility change
  useEffect(() => {
    if (popperElement) {
      !isVisible
        ? popperElement.removeAttribute("data-show")
        : popperElement.setAttribute("data-show", "true");
    }
    if (typeof onVisibilityChange === "function") {
      onVisibilityChange(isVisible);
    }
  }, [isVisible, popperElement]); // eslint-disable-line

  // a react hook to check fo changes in the state and determine button label

  // a react effect to toggle a class on document body
  // this hook has been added by imran's team probably for mobile
  useEffect(() => {
    if (isVisible && isScrollScreen) {
      document.body.classList.add("wc-opened-menu");
    } else {
      document.body.classList.remove("wc-opened-menu");
    }
  }, [isVisible, isScrollScreen]);

  /**
   * @memoization
   */

  // get current lang
  const lang = useMemo(() => {
    const localizedStrings = new LocalizedStrings(strings);
    localizedStrings.setLanguage(locale);
    return localizedStrings;
  }, [locale]);

  // get button name from calendar state.
  const buttonLabel = useMemo(() => {
    if (staticLabel) {
      return lang.when;
    }
    if (datePickerState.start && datePickerState.end) {
      return `${dayjs(datePickerState.start).format(displayFormat)} - ${dayjs(
        datePickerState.end
      ).format(displayFormat)}`;
    } else if (datePickerState.start && !datePickerState.end) {
      return `${dayjs(datePickerState.start).format(displayFormat)} - ${lang.checkout
        }`;
    } else {
      return `${isVisible ? lang.checkin + " - " + lang.checkout : lang.when}`;
    }
  }, [datePickerState, isVisible, displayFormat, lang, staticLabel]);

  const dataAttrs = useMemo(() => {
    const dataAttrs = {};
    Object.entries(data).forEach(([key, value]) => {
      dataAttrs[`data-${key}`] = value;
    });
    return dataAttrs;
  }, [data]);

  /**
   * @events
   */
  /**
   * set button label based on state and visibility.
   */
  const onStateChange = (state) => {
    setDatePickerState(state);
    // close date picker if dates are selected
    if (state.start && state.end) {
      setIsVisible(false);
    }
  };

  /**
   * close datepicker on cancel button click
   */
  const onClear = (state) => {
    // close date picker if visible
    if (isVisible) {
      setIsVisible(false);
      onCancel();
    }
  };

  /**
   * set the calender to invisible when the user click outside of the box
   * @param {Event} event dom object where the mouse is clicked
   */
  const handleClickAway = (event) => {
    if (isVisible && breakpoints.md) {
      onError(null);
      setIsVisible(false);
    }
  };

  /**
   * Event handler to open calendar
   * @param {Date} date
   */
  const toggleCalendar = (e) => {
    e.preventDefault();
    setIsVisible(!isVisible);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={clsx(
          "DropdownDatePicker",
          theme && `DropdownDatePicker--${theme}`,
          isFullWidth && "isFullWidth"
        )}
        {...dataAttrs}
      >
        <DropdownToggle
          popperRef={setReferenceElement}
          label={buttonLabel}
          onClick={toggleCalendar}
          isVisible={isVisible}
          classnames={clsx(
            "DropdownDatePicker__toggle",
            (datePickerState.start || datePickerState.end) && "isActive",
            isVisible && isScrollScreen && "isHover"
          )}
        />
        <div className={clsx(isVisible && !breakpoints.md && "fullscreen-modal")}>
          {isVisible && !breakpoints.md && (
            <div className="wc-media-header uk-flex uk-flex-between uk-flex-middle">
              <button className="uk-button-link wc-goback-link uk-flex wc-mobile" onClick={() => setIsVisible(false)}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox=" 0 0 492 492"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="#4f9589"
                      d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12 C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084 c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864 l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"
                    />
                  </svg>
                </span>
              </button>
              <h4 className="wc-title">
                {lang.dates}
              </h4>
            </div>
          )}
          <div
            className="DropdownDatePicker__content"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <DatePicker {...passedProps} onChange={onStateChange} onCancel={onClear} />
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

DropdownDatePicker.propTypes = {
  /** Date indicating the default start date the calendar should render */
  initialStartDate: PropTypes.object,
  /** Date indicating the default end date the calendar should render */
  initialEndDate: PropTypes.object,
  /** Object containing the rules of availability */
  availabilityCalendar: PropTypes.object,
  /** String indicating the default locale the calendar should render in */
  locale: PropTypes.oneOf(["en", "fr", "es", "it"]),
  /** String indicating the theme the calendar should render in */
  theme: PropTypes.oneOf(["primary", "secondary", "search-filter"]),
  /** String indicating the date format to be used by day-picker */
  displayFormat: PropTypes.string,
  /** Number indicating the number of months visible in date calendar */
  numberOfMonths: PropTypes.number.isRequired,
  /** Boolean indicating whether calendar is in loading state */
  isLoading: PropTypes.bool,
  /** Boolean indicating whether dropdown should be visible by default */
  defaultVisibility: PropTypes.bool,
  /** Boolean indicating whether it should disable auto flip */
  disableFlip: PropTypes.bool,
  /** Boolean indicating whether it's scroll screen */
  isScrollScreen: PropTypes.bool,
  /** Boolean indicating whether it's scroll screen */
  isFullWidth: PropTypes.bool,
  /** Function to be triggered when user clicks on apply button */
  onApply: PropTypes.func.isRequired,
  /** Function to be triggered when user clicks on cancel button */
  onCancel: PropTypes.func.isRequired,
  /** Function to be triggered when a validation error fires */
  onError: PropTypes.func,
  /** Function to be triggered when click on next month */
  onMonthChange: PropTypes.func,
  /** Object for live price */
  livePrice: PropTypes.object,
  /** Function to be triggered when user clicks on apply button */
  onVisibilityChange: PropTypes.func,
  /** Whether or not to show label */
  showLabel: PropTypes.bool
};

const noop = () => { };

DropdownDatePicker.defaultProps = {
  initialStartDate: null,
  initialEndDate: null,
  locale: "en",
  displayFormat: "ddd, D MMM",
  numberOfMonths: 2,
  isScrollScreen: false,
  isLoading: false,
  isFullWidth: false,
  defaultVisibility: false,
  onCancel: noop,
  onError: noop,
  onMonthChange: noop,
  livePrice: {},
  availabilityCalendar: {},
  data: {}
};

export default DropdownDatePicker;
