import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import lang from "./lang";
import Select from "react-select";
import COUNTRIES_CODES from "./codes";
import InputMask from "react-input-mask";

import "./index.scss";

const dialSelectOptions = COUNTRIES_CODES.map(item => {
  return {
    value: item.dial_code,
    label: (
      <span>
        <span
          className={"flag-icon flag-icon-" + item.code.toLowerCase()}
        ></span>{" "}
        ({item.dial_code})
      </span>
    )
  };
});

/**
 * A custom phone number input
 * @param {String} locale locale for this component.
 * @param {String} defaultDialCode default selected dial code.
 * @param {String} defaultPhoneNumber default entered user phone number.
 * @param {String} phoneNumberPlaceHolder a placeholder for the phone input.
 * @param {Function} onChange an on change function to be called when intern state changes.
 * @param {Boolean} readOnly make phoneInput readonly.
 */
var PhoneInput = ({
  locale,
  defaultDialCode,
  defaultPhoneNumber,
  phoneNumberPlaceHolder,
  onChange,
  onError,
  readOnly,
  ...attrs
}) => {
  /**
   * component state containers
   * @states
   */
  const [dialCode, setDialCode] = useState(defaultDialCode);
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);

  /**
   * lifecycle methods
   * @effects
   */
  useEffect(() => {
    onChange({
      dialCode,
      phoneNumber
    });
  }, [phoneNumber, dialCode]); // eslint-disable-line

  /**
   * Event handlers
   * @handlers
   */
  const handleDialSelectChange = useCallback(
    selected => {
      setDialCode(selected.value);
    },
    [setDialCode]
  );

  const handlePhoneInputChange = useCallback(e => {
    e.preventDefault();
    const value = e.target.value;
    setPhoneNumber(value);
  }, []);
  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    if (
      userInput &&
      newState.value === oldState.value &&
      oldState.value.length === 14
    ) {
      // eslint-disable-next-line no-unused-expressions
      onError?.();
    }

    return newState;
  };
  lang.setLanguage(locale);
  return (
    <div className="phone-input-container">
      <span className="input-right-section">
        <Select
          className="select-country"
          defaultValue={dialSelectOptions.find(e => e.value === dialCode)}
          onChange={handleDialSelectChange}
          placeholder=" Code"
          options={dialSelectOptions}
          isDisabled={readOnly}
        />
      </span>
      <InputMask
        maskChar={""}
        mask={phoneNumber ? "(999) 999-9999" : ""}
        className="phone-input hide-arrow"
        onChange={handlePhoneInputChange}
        type="tel"
        value={phoneNumber}
        placeholder={phoneNumberPlaceHolder || lang.phoneNumberPlaceHolder}
        readOnly={readOnly}
        beforeMaskedValueChange={beforeMaskedValueChange}
        {...attrs}
      />
    </div>
  );
};

PhoneInput.propTypes = {
  /** String indicating the default locale the phone input should render */
  locale: PropTypes.oneOf(["en", "fr", "es", "it"]),
  /** Number indicating default phone number */
  defaultPhoneNumber: PropTypes.string,
  /** Number indicating default dial code */
  defaultDialCode: PropTypes.string,
  /** String indicating placeholder for phone input */
  phoneNumberPlaceHolder: PropTypes.string,
  /** Function to be triggered when user clicks on apply button */
  onChange: PropTypes.func.isRequired,
  /** Function to be triggered when user attempt to enter more than 10 characters */
  onError: PropTypes.func.isRequired,
  /** make phoneInput readonly */
  readOnly: PropTypes.bool
};

const noop = () => {};

PhoneInput.defaultProps = {
  locale: "en",
  defaultPhoneNumber: "",
  defaultDialCode: "+1",
  phoneNumberPlaceHolder: "",
  onChange: noop,
  onError: noop,
  readOnly: false
};

export default PhoneInput;
