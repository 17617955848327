function getServiceFeePercentageValue(
// serviceFee: number,
// totalCost: number
): number {
  // const percentage: number = Number(
  //   ((serviceFee / totalCost) * 100).toFixed(2)
  // );
  return 3;
}

export default getServiceFeePercentageValue;
