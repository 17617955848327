import PropTypes from "prop-types";

export const CalendarIcon = ({ size = 20, color = "#4f5253" }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    width={size}
    height={size}
    fill={color}
  >
    <g>
      <g>
        <path
          d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
        c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
        h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"
        />
      </g>
    </g>
    <g>
      <g>
        <rect x="76" y="230" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="156" y="230" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="236" y="230" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="316" y="230" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="396" y="230" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="76" y="310" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="156" y="310" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="236" y="310" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="316" y="310" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="76" y="390" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="156" y="390" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="236" y="390" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="316" y="390" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="396" y="310" width="40" height="40" />
      </g>
    </g>
  </svg>
);

const ChevronIcon = ({ size = 20, color = "#4f5253" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="3"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 9l6 6 6-6" />
  </svg>
);

var Toggle = ({ label, classnames, onClick, isVisible }) => {
  return (
    <button className={classnames} type="button" onClick={onClick}>
      <span className="Icon CalendarIcon">
        <CalendarIcon color="#4f9589" />
      </span>
      <span className="Text">{label}</span>
      <span className={`ChevronIcon ${isVisible ? "rotate" : ""}`}>
        <ChevronIcon />
      </span>
    </button>
  );
};

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
  classnames: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired
};

export default Toggle;
