import { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import "./index.scss";

/**
 * LanguageSelect
 *
 * @deprecated Use new Select instead
 */
const LanguageSelect = ({ locale, changeLocale }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(options.find(e => e.value === locale));
  }, [locale]); // eslint-disable-line

  const options = [
    { value: "en", label: "English" },
    { value: "fr", label: "Français" }
  ];

  const onChange = selected => {
    setValue(selected);
    return changeLocale(selected.value);
  };

  return (
    <Fragment>
      <Select
        className="WechaletSelect"
        classNamePrefix="WechaletSelect"
        onChange={onChange}
        options={options}
        defaultValue={options.find(e => e.value === locale)}
        value={value}
        menuPlacement="auto"
        isSearchable={false}
      />
    </Fragment>
  );
};

LanguageSelect.propTypes = {
  /** String current locale */
  locale: PropTypes.string.isRequired,
  /** Function to be triggered when user clicks on the button */
  changeLocale: PropTypes.func
};

const noop = () => {};

LanguageSelect.defaultProps = {
  locale: "en",
  changeLocale: noop
};

export default LanguageSelect;
