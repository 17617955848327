import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";
import { produce } from "immer";

export default (
  state: ContainerState,
  action: PayloadAction<ContainerState["searchWithMap"]>
): ContainerState => {
  const mode = action.payload;
  switch (action.type) {
    case MAP_SEARCH_MODE_TOGGLED: {
      return produce(state, (draft) => {
        draft.searchWithMap = mode ?? !state.searchWithMap;
      });
    }
    default:
      return state;
  }
};

export const MAP_SEARCH_MODE_TOGGLED = "MAP_SEARCH_MODE_TOGGLED";
