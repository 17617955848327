import { createSelector } from "reselect";
import selectDomain from ".";

/**
 * Returns whether or not is the cookie banner visible
 *
 * @returns {boolean}
 */
export default createSelector([selectDomain], (layoutState) => {
  return !layoutState.cookiesAcceptedAt;
});
