import style from "./ChevronIcon.module.scss";

const ChevronIcon = ({ strokeWidth, ...attrs }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth={strokeWidth || 3}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={style.icon}
      {...attrs}
    >
      <path d="M6 9l6 6 6-6" />
    </svg>
  );
};

export default ChevronIcon;
