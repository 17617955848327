import { useMemo } from "react";
import LocalizedStrings from "react-localization";

const useLang = (strings, locale) => {
  return useMemo(() => {
    const lang = new LocalizedStrings(strings);
    lang.setLanguage(locale);
    return lang;
  }, [strings, locale]);
};

export default useLang;
