export default {
  en: {
    title: "We use cookies",
    description:
      "We use cookies and similar technologies to enhance your experience on our site. Some cookies are necessary for our site to function. We also use cookies to collect information about how you use our site to personalize content, tailor and measure the effectiveness of our advertising, and create a better experience.",
    accept: "Accept",
    acceptOnlyNecessary: "Accept only necessary"
  },
  fr: {
    title: "Nous utilisons des cookies",
    description:
      "Nous utilisons des cookies et des technologies similaires pour améliorer votre expérience sur notre site. Certains cookies sont nécessaires au bon fonctionnement de notre site. Nous utilisons également des cookies pour collecter des informations sur votre utilisation de notre site afin de personnaliser le contenu, d'adapter et de mesurer l'efficacité de nos publicités, et de créer une meilleure expérience.",
    accept: "Accepter",
    acceptOnlyNecessary: "Accepter uniquement ce qui est nécessaire"
  }
};
