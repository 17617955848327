import { FC, memo, useMemo } from "react";
import { FlagComponentProps } from "./types";
import classes from "./flag-components.module.scss";
import clsx from "clsx";
import useBreakpoints from "utils/useBreakpoints";

export const BlockedDay: FC<FlagComponentProps> = (props) => {
  const breakpoints = useBreakpoints();

  const length = breakpoints.md ? 5 : 2;

  const dashes = useMemo(() => {
    return Array.from({ length });
  }, [length]);

  return (
    <div className={classes.blocked}>
      {dashes.map((d, index) => (
        <div key={`dash--${index}`} className={clsx(classes.stripe)}></div>
      ))}
    </div>
  );
};

export default memo(BlockedDay);
