import Query from "utils/Query";
import {
  USER_LOGIN_REQUEST,
  USER_FETCH_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_FAVORITES_UPDATE,
  USER_FAVORITES_INCREMENT,
  USER_FAVORITES_DECREMENT,
  USER_TRIPS_UPDATE,
  AuthenticationAction
} from "./types";

import {
  requestStart,
  requestSuccess,
  requestFailure
} from "modules/request/actions";

import { hideModal, setModalData, showModal } from "modules/layout/actions";

import { fetchFavorites, fetchTravellingStats } from "modules/dashboard/actions";

const userLoginRequest = (): AuthenticationAction => {
  return {
    type: USER_LOGIN_REQUEST
  };
};

const userLoginFailure = (error: string): AuthenticationAction => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: error
  };
};

const userLogoutRequest = (): AuthenticationAction => {
  return {
    type: USER_LOGOUT_REQUEST
  };
};

export const userLogoutSuccess = (): AuthenticationAction => {
  localStorage.removeItem("user");
  return {
    type: USER_LOGOUT_SUCCESS
  };
};

export const userFetchSuccess = (user: object): AuthenticationAction => {
  localStorage.setItem("user", JSON.stringify(user));
  return {
    type: USER_FETCH_SUCCESS,
    payload: user
  };
};

export const userFavoritesUpdate = (count: number): AuthenticationAction => {
  return {
    type: USER_FAVORITES_UPDATE,
    payload: count
  };
};

export const userFavoritesIncrement = (): AuthenticationAction => {
  return {
    type: USER_FAVORITES_INCREMENT
  };
};

export const userFavoritesDecrement = (): AuthenticationAction => {
  return {
    type: USER_FAVORITES_DECREMENT
  };
};

export const userTripsUpdate = (count: number): AuthenticationAction => {
  return {
    type: USER_TRIPS_UPDATE,
    payload: count
  };
};

export const loginUser = (email: string, password: string) => async (
  dispatch: Function
) => {
  dispatch(userLoginRequest());
  try {
    await Query("auth/login").post({ email, password });
    dispatch(hideModal("LOGIN_MODAL"));
    return dispatch(fetchAuthenticatedUser(true));
  } catch (error: any) {
    if (error.name === "AbortError") return;

    // catches specific errors from backend.
    if (error.status === 400 && error.error_code) {
      return dispatch(userLoginFailure(error.error_code));
    }

    // catches form validation errors
    dispatch(userLoginFailure(error.validations?.email?.[0]));
  }
};

export const logoutUser = () => (dispatch: Function) => {
  dispatch(userLogoutRequest());
  return Query("auth/logout")
    .post()
    .then((json) => dispatch(userLogoutSuccess()))
    .catch((error) => dispatch(userLogoutSuccess())); // eslint-disable-line
};

interface FetchAuthenticatedUserRequestParams {
  r?:string
}

export const fetchAuthenticatedUser = (liveFetch: boolean = false, affiliateCode?:string) => async (
  dispatch: Function
) => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") as string)
    : undefined;

  const updateUserStats = () => {
    dispatch(fetchFavorites({}, (data: any) => {
      dispatch(userFavoritesUpdate(data.meta.total));
    }, () => {}));
    dispatch(fetchTravellingStats({}, (data: any) => {
      dispatch(userTripsUpdate(data.data.travelling.all));
    }, () => {}));
  };

  const isUserSuspended = user?.account_status?.has_account_suspended;

  // get user from local storage without fetch
  if (user && !liveFetch) {
    dispatch({
      type: USER_FETCH_SUCCESS,
      payload: user
    });
    if (!isUserSuspended) {
      updateUserStats();
    }
  } else {
    dispatch(userLoginRequest());
    const requestParams :FetchAuthenticatedUserRequestParams = {};
    if (affiliateCode?.length) {
      requestParams.r = affiliateCode;
    }
    try {
      const response = await Query("auth/user").get(requestParams);
      await dispatch(userFetchSuccess(response.data));
      if (!isUserSuspended) {
        updateUserStats();
      }
      return response;
    } catch (error: any) {
      if (error.status === 401) {
        dispatch(userLogoutSuccess());
        return;
      }
      if (error.status === 422) {
        const validation = await error.json();
        dispatch(userLoginFailure(validation.message));
      } else {
        dispatch(userLoginFailure(error.statusText));
      }
    }
  }
};

/**
 * Fetch resource from server
 * @param {Object} params request parameters
 * @param {Function} onSuccess on success callback after request success
 * @param {Function} onError on error callback after request error
 */
export const fetchUserProfile = () => (dispatch: Function) => {
  const endpoint = "auth/user";
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .get({})
    .then((json) => {
      dispatch(requestSuccess(endpoint, json.data));
      dispatch(userFetchSuccess(json.data));
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      if (error.status === 401) {
        dispatch(userLogoutSuccess());
      }
    });
};

export const getSocialProviderUrl = (
  provider: string,
  redirect_after_login: string,
  onSuccess: Function
) => async (dispatch: Function) => {
  dispatch(userLoginRequest());
  try {
    const response = await Query(`auth/${provider}/url`).get({
      redirect_after_login
    });
    return onSuccess(response);
  } catch (error: any) {
    dispatch(userLoginFailure(error.statusText));
  }
};

export const loginWithSocialProviderToken = (
  provider: string,
  params: object
) => async (dispatch: Function) => {
  dispatch(userLoginRequest());
  try {
    await Query(`v1/auth/${provider}`).post(params);
    dispatch(hideModal("LOGIN_MODAL"));
    return dispatch(fetchAuthenticatedUser());
  } catch (error: any) {
    if (error.name === "AbortError") return;
    if (error.status === 400) {
      dispatch(hideModal("LOGIN_MODAL"));
      dispatch(setModalData("REGISTER_MODAL", error.data));
      dispatch(showModal("REGISTER_MODAL"));
    }

    dispatch(userLoginFailure(error.statusText));
  }
};

export const loginWithAccessToken = (access_token: string) => async (
  dispatch: Function
) => {
  dispatch(userLoginRequest());
  try {
    await Query("auth/authorize").post({
      access_token
    });
    return dispatch(fetchAuthenticatedUser());
  } catch (error: any) {
    dispatch(userLoginFailure(error.statusText));
  }
};

export const registerUser = async (
  payload: object,
  onSuccess: Function,
  onError: Function
) => {
  try {
    const data = await Query("auth/register").post(payload);
    return onSuccess(data);
  } catch (error: any) {
    if (error.status) {
      return onError(error);
    }
    throw error;
  }
};

export const sendPasswordResetEmail = (
  payload: object,
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = "auth/password/email";
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .post(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const resetPassword = (
  payload: object,
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = "auth/password/reset";
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .post(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      dispatch(hideModal("LOGIN_MODAL"));
      dispatch(fetchAuthenticatedUser(true));
      return onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const activateEmail = (
  payload: object,
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = "auth/email/verify";
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .post(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json));
      dispatch(fetchAuthenticatedUser(true));
      onSuccess(json);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const resendEmail = (
  payload: object,
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = "auth/email/resend";
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .post(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json.data));
      onSuccess(json.data);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const updatePassword = (
  payload: object,
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = "auth/update-password";
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .update(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json.data));
      onSuccess(json.data);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};

export const addPassword = (
  payload: object,
  onSuccess: Function,
  onError: Function
) => (dispatch: Function) => {
  const endpoint = "auth/add-password";
  dispatch(requestStart(endpoint));
  return Query(endpoint)
    .update(payload)
    .then((json) => {
      dispatch(requestSuccess(endpoint, json.data));
      onSuccess(json.data);
    })
    .catch((error) => {
      if (error.name === "AbortError") return;
      dispatch(requestFailure(endpoint, error.message, error.status));
      onError(error);
    });
};
