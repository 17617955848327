export const CalendarIcon = ({ size = 20, color = "#4f5253" }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    width={size}
    height={size}
    fill={color}
  >
    <g>
      <g>
        <path
          d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
        c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
        h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"
        />
      </g>
    </g>
    <g>
      <g>
        <rect x="76" y="230" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="156" y="230" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="236" y="230" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="316" y="230" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="396" y="230" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="76" y="310" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="156" y="310" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="236" y="310" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="316" y="310" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="76" y="390" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="156" y="390" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="236" y="390" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="316" y="390" width="40" height="40" />
      </g>
    </g>
    <g>
      <g>
        <rect x="396" y="310" width="40" height="40" />
      </g>
    </g>
  </svg>
);

export const UserIcon = ({ size = 20, color = "#4f5253" }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    x="0px"
    y="0px"
    width={size}
    height={size}
    fill={color}
  >
    <g>
      <g>
        <path
          d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148 C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z"
        />
      </g>
    </g>
  </svg>
);

export const ArrowNextIcon = ({ size = 20, color = "#4f5253" }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    width={size}
    height={size}
    fill={color}
  >
    <g>
      <g>
        <path
          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
        />
      </g>
    </g>
  </svg>
);

export const CheckIcon = ({ size = 20, color = "#4f5253" }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 516 516"
    xmlSpace="preserve"
    width={size}
    height={size}
    fill={color}
  >
    <path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
  </svg>
);
