import { forwardRef, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import style from "./NumberStepper.module.scss";

const keyCodes = {
  35: "End",
  36: "Home",
  38: "ArrowUp",
  40: "ArrowDown"
};

const NumberStepper = forwardRef(({
  value,
  min,
  max,
  onChange,
  onBlur: onBlurProp,
  className,
  style: styleAttr,
  disabled,
  ...attrs
}, ref) => {
  const innerRef = useRef(null);

  const [hasFocus, setHasFocus] = useState(false);

  const setRef = (el) => {
    if (ref) ref.current = el;
    innerRef.current = el;
  };

  const decrement = () => {
    const newValue = value - 1;

    if (disabled || newValue < min) return;

    onChange(newValue);
  };

  const increment = () => {
    const newValue = value + 1;

    if (disabled || newValue > max) return;

    onChange(newValue);
  };

  const setToMin = () => {
    if (min !== value) {
      onChange(min);
    }
  };

  const setToMax = () => {
    if (max !== value) {
      onChange(max);
    }
  };

  const focus = () => {
    // Redirect focus to main element
    innerRef.current.focus();
  };

  const onArrowUp = (event) => {
    event.preventDefault();
    increment();
  };

  const onArrowDown = (event) => {
    event.preventDefault();
    decrement();
  };

  const onHome = (event) => {
    event.preventDefault();
    setToMin();
  };

  const onEnd = (event) => {
    event.preventDefault();
    setToMax();
  };

  const onKeyDown = (event) => {
    let code = event.code;

    if (!code && event.keyCode != null) {
      code = keyCodes[event.keyCode];
    }

    switch (code) {
      case "ArrowUp": return onArrowUp(event);
      case "ArrowDown": return onArrowDown(event);
      case "Home": return onHome(event);
      case "End": return onEnd(event);
      default: break;
    }
  };

  const onWrapperClick = () => {
    // Prevent from losing focus when clicking on disabled buttons
    if (document.activeElement === document.body || !document.activeElement) focus();
  };

  const onFocus = () => {
    setHasFocus(true);
  };

  const onBlur = (event) => {
    onBlurProp(event);

    // Keep track of focus state
    setTimeout(() => {
      if (document.activeElement !== innerRef.current) setHasFocus(false);
    });
  };

  return (
    <div
      role="group"
      className={clsx(className, style.default, {
        [style.focus]: hasFocus,
        [style.disabled]: disabled
      })}
      style={styleAttr}
      onFocusCapture={focus}
      onClick={onWrapperClick}
    >
      <button
        type="button"
        tabIndex="-1"
        className={style.button}
        aria-label="-"
        disabled={disabled || value <= min}
        onClick={decrement}
      >
        <i className="fa fa-minus"></i>
      </button>
      <output
        ref={setRef}
        role="spinbutton"
        tabIndex={disabled ? null : 0}
        className={style.value}
        aria-live="off"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        aria-disabled={disabled}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        {...attrs}
      >
        {value}
      </output>
      <button
        type="button"
        tabIndex="-1"
        className={style.button}
        aria-label="+"
        disabled={disabled || value >= max}
        onClick={increment}
      >
        <i className="fa fa-plus"></i>
      </button>
    </div>
  );
});

NumberStepper.propTypes = {
  /** The number value */
  value: PropTypes.number,
  /** The min value */
  min: PropTypes.number,
  /** The max value */
  max: PropTypes.number.isRequired,
  /** Disable the input */
  disabled: PropTypes.bool,
  /** The onChange listener */
  onChange: PropTypes.func
};

const noop = () => {};

NumberStepper.defaultProps = {
  value: 0,
  id: "",
  min: 0,
  max: 100,
  disabled: false,
  onChange: noop,
  onBlur: noop
};

export default NumberStepper;
