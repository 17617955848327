export const DAY_WIDTH = {
  md: 100,
  sm: 50
};

export const DAY_HEIGHT = {
  md: 60,
  sm: 60
};

export const LISTING_NAME_WIDTH = {
  md: 350,
  sm: 100
};
