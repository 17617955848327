import { colors } from "modules/listing-calendar/constants/day-color";
import { FC, Fragment, memo } from "react";
import { FlagComponentProps } from "./types";
import { PriceTag, TileCenter, TileEnd } from "modules/listing-calendar";
import { RANGE_FORMAT } from "modules/listing-calendar/constants/range-format";
import { useSelector } from "react-redux";
import classes from "./flag-components.module.scss";
import clsx from "clsx";
import dayjs from "dayjs";
import selectIsDayOverlapping from "modules/listing-calendar/store/selectors/select-is-day-overlapping";
import selectIsDayOverlappingBack from "modules/listing-calendar/store/selectors/select-is-day-overlapping-back";
import selectPeriodBetweenRange from "modules/listing-calendar/store/selectors/select-period-between-range";
import selectPeriodByEndDate from "modules/listing-calendar/store/selectors/select-period-by-end-date";

interface Props extends FlagComponentProps {}

export const ReservedEndDay: FC<Props> = (props) => {
  const { calendar, day, listingId } = props;

  const previousDay = dayjs(day).subtract(1, "day").format(RANGE_FORMAT);

  const prevPeriod = useSelector(selectPeriodByEndDate(listingId, previousDay));

  const isOverlapping = useSelector(
    selectIsDayOverlapping(listingId, previousDay)
  );

  const isBgOverlayed = useSelector(selectIsDayOverlappingBack(listingId, day));

  const bgPeriod = useSelector(selectPeriodBetweenRange(listingId, day));

  const type = prevPeriod?.type;

  const bgPeriodType = bgPeriod?.type;

  const color = type ? colors[type] : "primary";

  const bgColor = bgPeriodType ? colors[bgPeriodType] : "primary";

  const hasBgOverlay = isOverlapping && isBgOverlayed;

  return (
    <Fragment>
      <div
        className={clsx({
          [classes.reservedEnd]: true,
          [classes.elevated]: hasBgOverlay
        })}
      >
        <TileEnd color={isOverlapping ? "danger" : color} />
        {!hasBgOverlay && (
          <div className={classes.priceTagContainer}>
            <PriceTag calendar={calendar} />
          </div>
        )}
      </div>
      {hasBgOverlay && (
        <TileCenter className={classes.overlappedBack} color={bgColor} />
      )}
    </Fragment>
  );
};

export default memo(ReservedEndDay);
