import { useRef, useState } from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "react-click-away-listener";

import "./index.scss";
import lang from "./lang";
import Toggle from "./Toggle";

const SelectableList = ({
  name,
  items,
  buttonLabel,
  defaultSelected,
  locale,
  insideDropdown,
  onChange,
  isScrollScreen,
  applyOnClickAway
}) => {
  const dropdownRef = useRef();
  const [state, setState] = useState(defaultSelected);
  const [isVisible, setIsVisible] = useState(false);
  lang.setLanguage(locale);

  /**
   * an event handler for the cancel button click
   * @param {Event}  e
   */
  const onCancelButtonClick = (e) => {
    e.preventDefault();
    setIsVisible(false);
    setState([]);
    onChange({ [name]: [] });
  };

  /**
   * an event handler for the cancel button click
   * @param {Event}  e
   */
  const onApplyButtonClick = (e) => {
    e.preventDefault();
    setIsVisible(false);
    onChange({ [name]: state });
  };
  /**
   * On input change event handler
   * @param {Event} e event
   */

  const onCheckboxChange = (e) => {
    const { name, checked } = e.target;

    const newState = checked
      ? [...state, name]
      : state.filter((e) => e !== name);
    setState(newState);
  };

  const onToggleButtonClick = (e) => {
    e.preventDefault();
    setIsVisible(!isVisible);
  };

  /**
   * set the calender to invisible when the user click outside of the box
   * @param {Event} event dom object where the mouse is clicked
   */
  const handleClickAway = (event) => {
    if (isVisible && applyOnClickAway) {
      onChange({ [name]: state });
    }
    setIsVisible(false);
  };

  const getButtonClassNames = () => {
    const classnames = ["SelectableList_ToggleButton"];
    if (state.length !== 0) {
      classnames.push("isActive");
    }
    if (isVisible && isScrollScreen) {
      toggleBodyClass(true);
    } else {
      toggleBodyClass(false);
    }
    return classnames.reduce((prev, current) => prev + " " + current, "");
  };

  const toggleBodyClass = (bodyClass) => {
    bodyClass
      ? document.body.classList.add("wc-opened-menu")
      : document.body.classList.remove("wc-opened-menu");
  };

  const getSelectableListClassNames = () => {
    const classnames = ["SelectableList"];

    if (insideDropdown) {
      classnames.push("isInsideDropdown");
    }

    return classnames.reduce((prev, current) => prev + " " + current, "");
  };

  const getContainerClassNames = () => {
    const classnames = ["SelectableList_Container"];
    if (isVisible) {
      classnames.push("isHover");
    }
    return classnames.reduce((prev, current) => prev + " " + current, "");
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div ref={dropdownRef} className={getContainerClassNames()}>
        {insideDropdown && (
          <Toggle
            label={buttonLabel}
            classnames={getButtonClassNames()}
            onClick={onToggleButtonClick}
            insideDropdown={insideDropdown}
            isVisible={isVisible}
          />
        )}
        {(isVisible || !insideDropdown) && (
          <div className={getSelectableListClassNames()}>
            <div className="SelectableList_Row">
              <ul className="SelectableList_ItemsContainer">
                {items.map((item) => (
                  <li key={item.value} className="SelectableList_ListItem">
                    <label htmlFor={item.value}>
                      <input
                        id={item.value}
                        name={item.value}
                        onChange={onCheckboxChange}
                        checked={state.includes(item.value)}
                        type="checkbox"
                      />
                      <span
                        className={`checkmark checkmark-${item.value}`}
                      ></span>
                      {item.label}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="SelectableList_Row">
              <button
                onClick={onCancelButtonClick}
                className="SelectableList_Cancel"
              >
                {lang.clear}
              </button>
              <button
                onClick={onApplyButtonClick}
                className="SelectableList_Apply"
              >
                {lang.apply}
              </button>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

SelectableList.propTypes = {
  /** String with a unique name for this selectable list */
  name: PropTypes.string.isRequired,

  /** Array of items indicating the default selected items */
  defaultSelected: PropTypes.array,

  /** Array with a list items to render */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,

  /** Function to be triggered when component states changes */
  onChange: PropTypes.func.isRequired,

  /** Dropdown button label */
  buttonLabel: PropTypes.string,

  /** String indicating the default locale component should render with */
  locale: PropTypes.oneOf(["en", "fr", "es", "it"]),

  /** Boolean indicating whether it's scroll screen */
  isScrollScreen: PropTypes.bool,

  /** Boolean indicating whether the list should render inside dropdown */
  insideDropdown: PropTypes.bool
};

SelectableList.defaultProps = {
  locale: "en",
  buttonLabel: "List",
  defaultSelected: [],
  insideDropdown: false,
  isScrollScreen: false
};
export default SelectableList;
