import PropTypes from "prop-types";
import { PulseLoader } from "react-spinners";

const Loader = ({ size }) => {
  return (
    <PulseLoader
      css={"display: inline-flex; align-items: center;"} // Aligns loader vertically
      sizeUnit={"px"}
      size={size}
      color="currentColor" // Colors spinner based on context
      loading={true}
    />
  );
};

Loader.propTypes = {
  /** Size in px */
  size: PropTypes.string
};

Loader.defaultProps = {
  size: "10"
};

export default Loader;
