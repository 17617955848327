import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";
import { produce } from "immer";

export default (
  state: ContainerState,
  action: PayloadAction<ContainerState["mapMode"]>
): ContainerState => {
  const mode = action.payload;
  switch (action.type) {
    case MAP_DISPLAY_MODE_CHANGED: {
      return produce(state, (draft) => {
        draft.mapMode = mode;
      });
    }
    default:
      return state;
  }
};

export const MAP_DISPLAY_MODE_CHANGED = "MAP_DISPLAY_MODE_CHANGED";
