import { createSelectorCreator, defaultMemoize } from "reselect";
import selectDomain from ".";
import { ContainerState } from "../types";

const createSelector = createSelectorCreator(
  defaultMemoize,
  (previousState: ContainerState, state: ContainerState) => {
    return previousState.from === state.from && previousState.to === state.to;
  }
);

/**
 * Returns the date range selected
 *
 */
export default createSelector([selectDomain], (state) => {
  return [state.from, state.to];
});
