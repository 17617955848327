import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";

export default (
  state: ContainerState,
  action: PayloadAction<{
    date: string;
    yIndex: number;
  }>
): ContainerState => {
  const payload = action.payload;
  switch (action.type) {
    case DAY_CLICKED:
      if (!state.selectionDayOne && !state.selectionDayTwo) {
        return {
          ...state,
          selectionDayOne: payload.date,
          yIndexOne: payload.yIndex,
          previewDayTwo: payload.date,
          yIndexTwoPreview: payload.yIndex
        };
      } else if (state.selectionDayOne && !state.selectionDayTwo) {
        return {
          ...state,
          selectionDayTwo: payload.date,
          yIndexTwo: payload.yIndex
        };
      } else if (state.selectionDayOne && state.selectionDayTwo) {
        return {
          ...state,
          selectionDayOne: undefined,
          selectionDayTwo: undefined,
          yIndexTwo: undefined,
          yIndexOne: undefined
        };
      }
      return {
        ...state
      };
    default:
      return {
        ...state
      };
  }
};

export const DAY_CLICKED = "DAY_CLICKED";
