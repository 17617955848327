import { FC, Fragment } from "react";
import { getLocale } from "modules/settings/selectors";
import { useSelector } from "react-redux";
import classes from "./page-title.module.scss";
import messages from "./messages";
import useLang from "@design-library/utils/useLang";

interface Props {}

export const PageTitle: FC<Props> = () => {
  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  return (
    <Fragment>
      <div className={classes.pageTitle}>
        <h2>{lang.pageTitle}</h2>
      </div>
    </Fragment>
  );
};

export default PageTitle;
