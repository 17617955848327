export default {
  en: {
    guests: "Guests",
    guest: "Guest",
    extraService: "Extra service",
    extraServices: "Extra services",
    single: "Single",
    per_night: "Per Night",
    per_guest: "Per Guest",
    per_night_guest: "Per Night & Guest"
  },
  fr: {
    guests: "Invités",
    guest: "Invité",
    extraService: "Service en extra",
    extraServices: "Services en extra",
    single: "Unique",
    per_night: "Par Nuit",
    per_guest: "Par Invité",
    per_night_guest: "Par Nuit et Invité"
  }
};
