const Navbar = ({
  onPreviousClick,
  onNextClick,
  className,
  showNextButton,
  showPreviousButton
}) => {
  return (
    <div className={className}>
      {showPreviousButton && (
        <div
          className="DayPicker-NavBar--prev"
          onClick={() => onPreviousClick()}
        >
          <ChevronIconLeft />
        </div>
      )}
      {showNextButton && (
        <div className="DayPicker-NavBar--next" onClick={() => onNextClick()}>
          <ChevronIconRight />
        </div>
      )}
    </div>
  );
};

export default Navbar;

const ChevronIconRight = ({ size = 20, color = "#4f5253" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="3"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M9 18l6-6-6-6" />
  </svg>
);

const ChevronIconLeft = ({ size = 20, color = "#4f5253" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="3"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M15 18l-6-6 6-6" />
  </svg>
);
