export const DEFAULT_CURRENCY = "CAD";
export const SYMBOLS = {
  CAD: "$",
  USD: "$",
  EUR: "€",
  MXN: "$"
};

// actions
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_CURRENCY_RATES = "SET_CURRENCY_RATES";

// interfaces
export interface RatesInterface {
  [key: string]: number;
}

export interface CurrencyState {
  base: string;
  rates: RatesInterface;
}

export interface CurrencyAction {
  type: typeof SET_CURRENCY;
  payload: {
    currency: string;
  };
}

export interface CurrencyRatesAction {
  type: typeof SET_CURRENCY_RATES;
  payload: {
    rates: RatesInterface;
  };
}

export type CurrencyActionTypes = CurrencyRatesAction | CurrencyAction;
