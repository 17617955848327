import { colors } from "modules/listing-calendar/constants/day-color";
import { FC, memo } from "react";
import { FlagComponentProps } from "./types";
import { TileCenter } from "modules/listing-calendar";
import { useSelector } from "react-redux";
import classes from "./flag-components.module.scss";
import selectIsDayOverlapping from "modules/listing-calendar/store/selectors/select-is-day-overlapping";
import selectPeriodBetweenRange from "modules/listing-calendar/store/selectors/select-period-between-range";

interface Props extends FlagComponentProps {
  tile: string;
}

export const ReservedDay: FC<Props> = (props) => {
  const { day, listingId } = props;

  const period = useSelector(selectPeriodBetweenRange(listingId, day));

  const type = period?.type;

  const color = type ? colors[type] : "primary";

  const isOverlapping = useSelector(selectIsDayOverlapping(listingId, day));

  return (
    <div className={classes.reserved}>
      <TileCenter color={isOverlapping ? "danger" : color} />
    </div>
  );
};

export default memo(ReservedDay);
