import { ObjectId } from "modules/shared/types/object-id";

/**
 * Coverts an array into a object, with id as the key
 * and the item in array as the value
 *
 */
export default <T extends { id: ObjectId }>(
  arr: T[],
  id = "id"
): Record<ObjectId, T> => {
  const record: Record<ObjectId, T> = {};
  if (!arr?.length) {
    return record;
  }
  for (const item of arr) {
    record[item[id]] = item;
  }
  return record;
};
