import LocalizedStrings from "react-localization";
const lang = new LocalizedStrings({
  en: {
    guests: "Guests",
    guest: "Guest",
    adults: "Adults",
    pets: "Pets",
    children: "Children",
    children_age: "Age 2-12",
    infants: "Infants",
    infants_age: "Under 2",
    description: "The maximum amount of guests is {maxGuests}.",
    actions: {
      no: "No",
      yes: "Yes",
      cancel: "Clear",
      apply: "Apply"
    },
    errorMessages: {
      ERROR_PETS_NOT_ALLOWED: "This property doesn't accept animals"
    }
  },
  fr: {
    guests: "Invités",
    guest: "Invité",
    adults: "Adultes",
    pets: "Animaux",
    children: "Enfants",
    children_age: "Âge 2-12",
    infants: "Bébés",
    infants_age: "(-) de 2 ans",
    description: "Le nombre maximum d'invités est de {maxGuests}.",
    actions: {
      no: "Non",
      yes: "Oui",
      cancel: "Annuler",
      apply: "Appliquer"
    },
    errorMessages: {
      ERROR_PETS_NOT_ALLOWED: "Cette propriété n'accepte pas d'animaux"
    }
  },
  es: {
    guests: "Invitados",
    guest: "Invitado",
    adults: "Adultos",
    pets: "Mascotas",
    children: "Niños",
    children_age: "2-12 años",
    infants: "Infantes",
    infants_age: "Menores de 2",
    actions: {
      no: "No",
      yes: "Si",
      cancel: "Claro",
      apply: "Aplicar"
    },
    errorMessages: {
      ERROR_MAXIMUM_GUESTS: "Le nombre maximum d'invités est de {value}",
      ERROR_CHILDREN_NOT_ALLOWED: "L'endroit n'est pas adapté aux enfants",
      ERROR_INFANTS_NOT_ALLOWED: "L'endroit ne convient pas aux bébés",
      ERROR_PETS_NOT_ALLOWED: "Cette propriété n'accepte pas d'animaux"
    }
  },
  it: {
    guests: "Ospiti",
    guest: "Ospite",
    adults: "Adulti",
    pets: "Animali",
    children: "Bambini",
    children_age: "Età 2-12",
    infants: "I Neonati",
    infants_age: "Meno di 2 anni",
    actions: {
      no: "No",
      yes: "Sì",
      cancel: "Chiaro",
      apply: "Applicare"
    },
    errorMessages: {
      ERROR_MAXIMUM_GUESTS: "Il numero massimo di ospiti è {value}",
      ERROR_CHILDREN_NOT_ALLOWED: "Il posto non è adatto ai bambini",
      ERROR_INFANTS_NOT_ALLOWED: "Il posto non è adatto ai bambini",
      ERROR_PETS_NOT_ALLOWED: "Questa proprietà non accetta animali"
    }
  }
});
export default lang;
