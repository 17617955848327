import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";
import { produce } from "immer";

export default (
  state: ContainerState,
  action: PayloadAction<number>
): ContainerState => {
  switch (action.type) {
    case PAGE_EXPANDED:
      return produce(state, (draft) => {
        const payload = action.payload;
        draft.pages[payload] = true;
      });
    default:
      return state;
  }
};

export const PAGE_EXPANDED = "PAGE_EXPANDED";
