import { ContainerState } from "../types";
import { Listing } from "modules/shared/types/listing";
import { merge } from "lodash";
import { PaginatedEntity } from "modules/shared/types/paginated-entity";
import { PayloadAction } from "modules/shared/types/payload-action";
import { PeriodSet } from "modules/listing-calendar/types/period-set";
import { produce } from "immer";
import { ResponseData } from "modules/shared/types/response-data";
import getRangeId from "modules/listing-calendar/utils/get-range-id";
import normalizeArray from "modules/shared/utils/normalize-array";

export default (
  state: ContainerState,
  action: PayloadAction<ListingsPayload>
): ContainerState => {
  const payload = action.payload;
  switch (action.type) {
    case LISTINGS_LOADED: {
      const { periods, listings } = action.payload;
      const rangeId = getRangeId(
        listings.meta.current_page,
        payload.from,
        payload.to
      );

      return {
        ...state,
        periods: produce(state.periods, (draft) => {
          periods.data.forEach((p) => {
            const pOld = draft[p.id] ?? {};
            const pNew = normalizeArray(p.periods, "start_date");
            draft[p.id] = merge(pOld, pNew);
          });
        }),
        overlappedPeriods: produce(state.overlappedPeriods, (draft) => {
          periods.data.forEach((p) => {
            const pOld = draft[p.id] ?? {};
            const pNew = normalizeArray(p.overlapped, "start_date");
            draft[p.id] = merge(pOld, pNew);
          });
        }),

        calendars: produce(state.calendars, (draft) => {
          listings.data.forEach((l) => {
            const cOld = draft[l.id] ?? {};
            const cNew = normalizeArray(l.calendars, "date") ?? {};
            draft[l.id] = merge(cOld, cNew);
          });
        }),

        listingIds: produce(state.listingIds, (draft) => {
          draft[rangeId] = payload.listings.data.map((l) => l.id);
        }),

        rangeStatus: {
          ...state.rangeStatus,
          [rangeId]: "idle"
        },

        listings: {
          ...state.listings,
          [rangeId]: normalizeArray(action.payload.listings.data)
        },
        meta: {
          total: payload.listings.meta.total,
          last_page: payload.listings.meta.last_page
        },
        pages: produce(state.pages, (draft) => {
          draft[payload.listings.meta.current_page] = true;
        })
      };
    }
    default:
      return {
        ...state
      };
  }
};

export type ListingsPayload = {
  to: string;
  from: string;
  data: Listing[];
  listings: PaginatedEntity<Listing>;
  periods: ResponseData<PeriodSet[]>;
};

export const LISTINGS_LOADED = "LISTINGS_LOADED";
