import { ContainerState } from "../types";
import { DATE_RANGE_CHANGED } from "./date-range-changed";
import { SEARCH_KEYWORD_CHANGED } from "./search-keyword-changed";
import { STATUS_CLEARED } from "./status-cleared";
import { STATUS_TOGGLED } from "./status-toggled";
import { uniqueId } from "lodash";
import initialState from "../initial-state";

const reducers: ((
  state: typeof initialState,
  action: unknown
) => typeof initialState)[] = [
  /**
   * all listing calendar reducers should be put here
   *
   */
  require("./date-range-changed").default,
  require("./day-highlighted").default,
  require("./day-marked").default,
  require("./display-type-changed").default,
  require("./listings-loaded").default,
  require("./listings-updated").default,
  require("./periods-updated").default,
  require("./mass-edit-modal-dismisses").default,
  require("./page-expanded").default,
  require("./range-deflated").default,
  require("./range-inflated").default,
  require("./search-keyword-changed").default,
  require("./segment-changed").default,
  require("./status-cleared").default,
  require("./status-toggled").default
];

/**
 * Listing calendar root reducer
 *
 */
export default (state = initialState, action: any) => {
  let newState: ContainerState;
  switch (action.type) {
    // Global reducers go here
    case DATE_RANGE_CHANGED:
    case SEARCH_KEYWORD_CHANGED:
    case STATUS_TOGGLED:
    case STATUS_CLEARED:
      newState = {
        ...state,
        listingIds: initialState.listingIds,
        requestId: uniqueId("listings--"),
        from: initialState.from,
        to: initialState.to,
        rangeStatus: {}
      };
      break;
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
};
