import { userLogoutSuccess } from "./../auth/actions";
import {
  REQUEST_UNAUTHORIZED,
  REQUEST_FORBIDDEN,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_FAILURE,
  RESET_ERROR,
  RequestActionTypes,
  RequestPendingAction,
  RequestSuccessAction
} from "./types";

export const requestStart = (endpoint: string): RequestPendingAction => ({
  type: REQUEST_PENDING,
  payload: {
    endpoint
  }
});

export const requestSuccess = (
  endpoint: string,
  data: object
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  payload: {
    endpoint,
    data
  }
});

export const requestFailure = (
  endpoint: string,
  error: object,
  status: number
) => (dispatch: Function) => {
  switch (status) {
    case 401: // logout user
      dispatch(userLogoutSuccess());
      return dispatch({
        type: REQUEST_UNAUTHORIZED,
        payload: {
          endpoint,
          error,
          status
        }
      });
    case 403:
      dispatch({
        type: REQUEST_FORBIDDEN,
        payload: {
          endpoint,
          error,
          status
        }
      });
      window.location.href = "/";
      return;
    case 500:
      if (process.env.NODE_ENV !== "production") return;
      window.location.href = "/500";
      return;
    case 503:
      if (process.env.NODE_ENV !== "production") return;
      window.location.href = "/503";
      return;
    default:
      return dispatch({
        type: REQUEST_FAILURE,
        payload: {
          endpoint,
          error,
          status
        }
      });
  }
};

export const resetError = (endpoint: string): RequestActionTypes => {
  return {
    type: RESET_ERROR,
    payload: {
      endpoint
    }
  };
};
