import { Profile } from "../types/profile";

class User {
  rawUser: any;
  id: string;
  email: string;
  emailVerified: boolean;
  phoneNumber: string;
  phoneVerified: boolean;
  profile: Profile;
  roles: string[];
  socialAccounts: object[];

  constructor(authenticatedUser: any) {
    this.rawUser = authenticatedUser;
    this.id = authenticatedUser.id;
    this.email = authenticatedUser.email;
    this.emailVerified = authenticatedUser.email_verified_at !== null;
    this.phoneNumber = authenticatedUser.phone_number;
    this.phoneVerified = authenticatedUser.phones?.find(
      (phone: any) => phone.is_primary
    )?.is_verified;
    this.profile = authenticatedUser.profile;
    this.roles = authenticatedUser.roles;
    this.socialAccounts = authenticatedUser.social_accounts;
  }

  raw(): any {
    return this.rawUser;
  }

  avatar(): string {
    return this.profile.avatar || "/images/default_avatar.jpg";
  }

  publicAvatar(): string {
    return this.profile.avatar &&
      !this.profile.avatar.match(/default_avatar.jpg$/)
      ? this.profile.avatar
      : `https://ui-avatars.com/api/?name=${this.profile.firstname}&size=512&length=1&rounded=true&bold=true&color=FFFFFF&background=4f5253`;
  }

  hasRole(role: string): boolean {
    return this.roles.includes(role);
  }

  isHost(): boolean {
    return this.hasRole("host");
  }

  isAdmin(): boolean {
    return this.hasRole("administrator");
  }

  isGuest(): boolean {
    return this.hasRole("guest");
  }

  hasProfilePicture(): boolean {
    return !!this.rawUser.account_status.has_profile_picture;
  }

  hasPhoneNumber(): boolean {
    return this.phoneNumber !== null;
  }

  hasVerifiedEmail(): boolean {
    return this.emailVerified;
  }

  hasVerifiedPhone(): boolean {
    return this.phoneVerified;
  }

  getVerificationLevel(): number {
    let level = 0;
    if (this.hasVerifiedEmail()) {
      level += 1;
    }

    if (this.hasVerifiedPhone()) {
      level += 1;
    }

    if (this.hasProfilePicture()) {
      level += 1;
    }
    return level;
  }
}

export default User;
