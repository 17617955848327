import { createSelector } from "reselect";
import { ObjectId } from "modules/shared/types/object-id";
import dayjs from "dayjs";
import selectPeriodsByListingId from "./select-periods-by-listing-id";

/**
 * Returns the period that ends at the date given
 *
 */
export default (listingId: ObjectId, date?: string) =>
  createSelector([selectPeriodsByListingId(listingId)], (periods) => {
    if (!date || !listingId) {
      return;
    }

    const dateToCheck = dayjs(date);

    const record = periods.find((p) => {
      const offset = p.type === "external-booking" ? 0 : 1;

      return dateToCheck.subtract(offset, "day").isSame(p?.end_date, "date");
    });

    return record;
  });
