import { ContainerState } from "../types";
import { PayloadAction } from "modules/shared/types/payload-action";
import { produce } from "immer";
import dayjs from "dayjs";
import getRangeId from "modules/listing-calendar/utils/get-range-id";
import realDate from "modules/listing-calendar/utils/real-date";

export default (
  state: ContainerState,
  action: PayloadAction<number>
): ContainerState => {
  const payload = action.payload;
  switch (action.type) {
    case RANGE_DEFLATED: {
      const current = state.from;
      const newDate = dayjs(current).subtract(payload, "days").startOf("D");
      const previous = newDate.subtract(payload - 1, "days");

      const pages = Object.keys(state.pages);
      return {
        ...state,
        from: realDate(newDate.toDate()),
        rangeStatus: produce(state.rangeStatus, (draft) => {
          pages.forEach((p) => {
            const page = Number(p);
            const rangeId = getRangeId(
              page,
              realDate(previous.toDate()),
              realDate(newDate.toDate())
            );
            draft[rangeId] = "loading";
          });
        })
      };
    }
    default:
      return state;
  }
};

export const RANGE_DEFLATED = "RANGE_DEFLATED";
