import { STATUS_TOGGLED, StatusPayload } from "../reducers/status-toggled";

/**
 * Toggles many statuses at once on or off
 *
 */
export default (statuses: StatusPayload) => {
  return {
    type: STATUS_TOGGLED,
    payload: statuses
  };
};
