import {
  ModalAction,
  CREATE_MODAL,
  TOGGLE_MODAL_VISIBILITY,
  SET_MODAL_VISIBILITY,
  SET_MODAL_TITLE,
  SET_MODAL_DATA,
  UNSET_MODAL_DATA
} from "./types.dt";

/**
 * modal actions
 */
export function createModal(id: string): ModalAction {
  return {
    type: CREATE_MODAL,
    payload: {
      id,
      visible: false,
      title: ""
    }
  };
}

export function toggleModal(id: string): ModalAction {
  return {
    type: TOGGLE_MODAL_VISIBILITY,
    payload: { id }
  };
}

export function showModal(id: string): ModalAction {
  return {
    type: SET_MODAL_VISIBILITY,
    payload: {
      id,
      visible: true
    }
  };
}

export function hideModal(id: string): ModalAction {
  return {
    type: SET_MODAL_VISIBILITY,
    payload: {
      id,
      visible: false
    }
  };
}

export function setModalTitle(id: string, title: string): ModalAction {
  return {
    type: SET_MODAL_TITLE,
    payload: {
      id,
      title
    }
  };
}

export function setModalData(id: string, data: any): ModalAction {
  return {
    type: SET_MODAL_DATA,
    payload: {
      id,
      data
    }
  };
}
export function unsetModalData(id: string): ModalAction {
  return {
    type: UNSET_MODAL_DATA,
    payload: {
      id
    }
  };
}
export const switchModals = (modal1: string, modal2: string) => (
  dispatch: Function
) => {
  dispatch(hideModal(modal1));
  dispatch(showModal(modal2));
};
