import { useRef } from "react";
import isEqual from "lodash/isEqual";

const useDeepEqualMemo = (value: any) => {
  const ref = useRef(value);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export default useDeepEqualMemo;
