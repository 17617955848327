export default {
  en: {
    minimumStay: "Min Stay",
    prices: "Prices"
  },
  fr: {
    minimumStay: "Durée",
    prices: "Prix"
  }
};
