import Helmet from "react-helmet";
import useLang from "redux/hooks/useLang";
import { useSelector } from "react-redux";
import { getLocale } from "modules/settings/selectors";
import translations from "translations";

const Meta = () => {
  const locale = useSelector(getLocale);
  const lang = useLang(translations, locale);

  return (
    <Helmet
      defaultTitle={lang.siteTitle}
      titleTemplate={lang.titleTemplate}
    />
  );
};

export default Meta;
