import CurrencySelect from "./CurrencySelect";
import LanguageSelect from "./LanguageSelect";

/**
 * Select
 *
 * @deprecated Use new Select instead
 */
export default {
  CurrencySelect,
  LanguageSelect
};
