import strings from "./strings";
import { useSelector } from "react-redux";
import useLang from "redux/hooks/useLang";
import { getLocale } from "modules/settings/selectors";
import { Portal } from "react-portal";

const styles = {
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: "0px",
    left: "0px",
    backgroundColor: "#fff",
    zIndex: "999999999999",
    overflow: "hidden"
  },
  loading: {
    position: "relative",
    height: "91px",
    width: "91px"
  },
  logo: {
    position: "absolute",
    zIndex: 10,
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    marginLeft: "0px"
  },
  ring: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9,
    marginLeft: "0px"
  },
  timeoutMessage: {
    zIndex: 10,
    marginLeft: "0px"
  },
  timeoutReload: {
    cursor: "pointer",
    textDecoration: "underline",
    background: "none",
    fontFamily: "inherit",
    fontSize: "inherit",
    color: "inherit",
    border: "none"
  }
};

const reloadPage = () => {
  window.location.reload();
};
/**
 * react component
 * @param {Object} props props passed to this component
 */
const WechaletLoader = ({ timedOut }) => {
  const locale = useSelector(getLocale);
  const lang = useLang(strings, locale);
  return (
    <Portal>
      <div className="wechaletLoader" style={styles.container}>
        <div className="loaderInner" style={styles.loading}>
          <svg
            style={styles.logo}
            width="60"
            height="60"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 187 188"
            xmlSpace="preserve"
          >
            <circle fill="#FFFFFF" cx="93.5" cy="93.5" r="85.5" />
            <g>
              <path
                fill="#4F9589"
                d="M92.86,5.29c-49.15,0-89,39.85-89,89s39.85,89,89,89s89-39.85,89-89S142.01,5.29,92.86,5.29z M17.5,94.29
    c0-10.96,2.34-21.37,6.55-30.76l25.36,92.33C30.11,142.21,17.5,119.72,17.5,94.29z M92.86,169.64c-13.39,0-25.96-3.5-36.86-9.62
    l26.91-39.99H71.47l19.29-30.7h-7.92l9.93-25.62l9.74,25.62h-7.87l19.29,30.7H102.5l26.87,39.94l0.08,0.21
    C118.61,166.2,106.14,169.64,92.86,169.64z M70.94,120.02l0.21-0.53l0,0.53H70.94z M94.9,27.61h-4.27l-35.56,92.1L35.01,46
    c13.82-16.54,34.6-27.07,57.85-27.07c23.2,0,43.95,10.49,57.77,26.98l-20.53,73.98L94.9,27.61z M135.46,156.45l26.09-93.19
    c4.28,9.46,6.66,19.96,6.66,31.02C168.21,120.09,155.24,142.87,135.46,156.45z"
              />
            </g>
          </svg>
          <svg
            style={styles.ring}
            width="90"
            height="90"
            viewBox="0 0 44 44"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#4f9589"
          >
            <g fill="none" fillRule="evenodd" strokeWidth="2">
              <circle cx="22" cy="22" r="1">
                <animate
                  attributeName="r"
                  begin="0s"
                  dur="1.8s"
                  values="1; 20"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.165, 0.84, 0.44, 1"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="stroke-opacity"
                  begin="0s"
                  dur="1.8s"
                  values="1; 0"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.3, 0.61, 0.355, 1"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx="22" cy="22" r="1">
                <animate
                  attributeName="r"
                  begin="-0.9s"
                  dur="1.8s"
                  values="1; 20"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.165, 0.84, 0.44, 1"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="stroke-opacity"
                  begin="-0.9s"
                  dur="1.8s"
                  values="1; 0"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.3, 0.61, 0.355, 1"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
          </svg>
        </div>
        {timedOut && (
          <div style={styles.timeoutMessage}>
            <p>
              {lang.timeoutMessage}{" "}
              <button style={styles.timeoutReload} onClick={reloadPage}>
                {lang.reloadButton}.
              </button>
            </p>
          </div>
        )}
      </div>
    </Portal>
  );
};

export default WechaletLoader;
