import { createSelector } from "reselect";
import { ObjectId } from "modules/shared/types/object-id";
import dayjs from "dayjs";
import selectPeriodsByListingId from "./select-periods-by-listing-id";

/**
 * Returns the period between a range
 *
 */
export default (listingId: ObjectId, date?: string) =>
  createSelector([selectPeriodsByListingId(listingId)], (periods) => {
    if (!date || !listingId) {
      return;
    }

    const dateToCheck = dayjs(date);

    const record = periods.find((p) => {
      return dateToCheck.isBetween(p.start_date, p.end_date, "date", "[]");
    });

    return record;
  });
