import {
  ReservedDay,
  ReservedEndDay,
  ReservedStartDay,
  ReservedStartEndDay
} from "../containers/listing-column/flag-components";
import { DAY_TILE } from "../utils/get-day-tile-flags";

/**
 * Tile component dictionary
 *
 */
export const TILE_COMPONENT = {
  [DAY_TILE.START]: ReservedStartDay,
  [DAY_TILE.MIDDLE]: ReservedDay,
  [DAY_TILE.CROSS]: ReservedStartEndDay,
  [DAY_TILE.END]: ReservedEndDay,
  [DAY_TILE.NO_TILE]: null
};
