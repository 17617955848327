import { Calendar } from "modules/listing-calendar/types/calendar";
import { FC, Fragment, memo } from "react";
import { useSelector } from "react-redux";
import classes from "./price-tag.module.scss";
import clsx from "clsx";
import dayjs from "dayjs";
import selectDisplayType from "modules/listing-calendar/store/selectors/select-display-type";

interface Props {
  calendar?: Calendar;
}

export const PriceTag: FC<Props> = memo((props) => {
  const { calendar } = props;

  const dayInstance = dayjs(calendar?.date);

  // 5 is Friday, 6 is Saturday
  const isWeekend = [5, 6].includes(dayInstance.day());

  const display = useSelector(selectDisplayType);

  const price = (calendar?.price ?? 0) + "$";

  const minStay = calendar?.min_nights;

  return (
    <Fragment>
      <div
        className={clsx(classes.container, {
          [classes.weekend]: isWeekend
        })}
      >
        {display === "price" ? price : minStay}
      </div>
    </Fragment>
  );
});

export default memo(PriceTag);
