import { CONSENT_COOKIE_NAME } from "modules/layout/constants/consent-cookie";
import { CONSENT_COOKIES_INITIALIZED } from "../reducers/consent-cookies-initialized";

/**
 * Initializes the consent cookie state
 *
 */
export default () => {
  const regex = new RegExp(
    `(?:(?:^|.*;\\s*)${CONSENT_COOKIE_NAME}\\s*\\=\\s*([^;]*).*$)|^.*$`
  );
  const rawCookie = document.cookie.replace(regex, "$1");

  const cookieContent = rawCookie?.length ? JSON.parse(rawCookie) : undefined;

  const acceptedAt = cookieContent?.acceptedAt;

  const acceptedCookies = cookieContent?.cookies ?? {};

  return {
    type: CONSENT_COOKIES_INITIALIZED,
    payload: {
      acceptedCookies,
      acceptedAt
    }
  };
};
