import { createSelector } from "reselect";
import selectDomain from ".";

/**
 * Returns listings
 *
 */
export default createSelector([selectDomain], (state) => {
  const recordMap = Object.values(state.listings);

  const records = recordMap.reduce(
    (a, c) => ({
      ...c,
      ...a
    }),
    {}
  );
  return records;
});
