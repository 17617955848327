import { createSelector } from "reselect";
import { LanguagesEnum } from "./types";

interface SettingsState {
  locale: LanguagesEnum;
  countryCode:string,
}

interface RootState {
  settings: SettingsState;
}

export const getLocale = createSelector(
  (state : RootState) : SettingsState => state.settings,
  (settings: SettingsState) : LanguagesEnum => settings.locale
);

export const getCountryCode = createSelector(
  (state : RootState) : SettingsState => state.settings,
  (settings: SettingsState) : string => settings.countryCode
);
